import { PageLayout } from '@/components/page-layout';
import { useAuth } from '@/components/providers/auth.tsx';
import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  HStack,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Roles } from '@fieldbrick/core/constants.ts';
import { startCase } from 'lodash-es';
import { ArrowRightIcon, PlusIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { AuthorizeRole } from '../../../components/authorize-role.tsx';
import { NewProjectModal } from '../new/components/new-project-modal.tsx';
import { useProjects } from './hooks/use-projects.js';

export function ProjectsPage() {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const projects = useProjects();
  const { organizationId } = useAuth();

  return (
    <>
      <PageLayout>
        <PageLayout.Header>
          <PageLayout.Title>Projects</PageLayout.Title>
          <AuthorizeRole role={[Roles.OWNER, Roles.TECH]}>
            <Button onClick={onToggle} leftIcon={<PlusIcon />} as={Link} to={`/org/${organizationId}/projects/new`}>
              New Project
            </Button>
          </AuthorizeRole>
        </PageLayout.Header>

        <PageLayout.Content>
          <VStack mt="4" gap="4">
            {projects.map((project) => {
              const { customer, customerAddress, createdBy } = project;
              const { primaryStreet, secondaryStreet, zipCode, city, state } = customerAddress;
              const address = [primaryStreet, secondaryStreet].filter(Boolean).join(', ');
              const rest = [city, state, zipCode].filter(Boolean).join(', ');
              return (
                <Card key={project.id} className="w-full" variant="outline">
                  <CardBody>
                    <Stack direction="column" gap="4">
                      <Box className="grid grid-cols-12 gap-2">
                        <HStack gap="8" alignItems="start" className="col-span-9">
                          <Box className="flex flex-col flex-1">
                            <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs" color="gray.500">
                              Customer
                            </Text>

                            <Text textTransform="capitalize" fontSize="lg">
                              {customer.firstName} {customer.lastName}
                            </Text>
                          </Box>

                          <Box className="flex flex-col flex-1">
                            <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs" color="gray.500">
                              Created by
                            </Text>

                            <Text textTransform="capitalize" fontSize="lg">
                              {createdBy.firstName} {createdBy.lastName}
                            </Text>
                          </Box>

                          <Box className="flex flex-col flex-1">
                            <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs" color="gray.500">
                              Project Created
                            </Text>

                            <Text textTransform="capitalize" fontSize="lg">
                              {DateTime.fromSQL(project.createdAt, {
                                zone: 'utc',
                              }).toLocaleString(DateTime.DATE_FULL)}
                            </Text>
                          </Box>
                        </HStack>

                        <Box className="col-span-3 flex items-center gap-2 justify-end">
                          <Badge className="block" colorScheme={project.status === 'completed' ? 'green' : 'blue'}>
                            {startCase(project.status)}
                          </Badge>
                        </Box>
                      </Box>

                      <Divider />

                      <Stack gap="8" direction="row" alignItems="center">
                        <Box className="flex flex-col flex-1">
                          <Text fontWeight="semibold" textTransform="uppercase" fontSize="xs" color="gray.500">
                            Address
                          </Text>

                          <Text textTransform="capitalize" fontSize="lg">
                            <span className="block">{address}</span> {rest}
                          </Text>
                        </Box>

                        <Button
                          as={Link}
                          to={`/org/${organizationId}/projects/${project.id}`}
                          rightIcon={<ArrowRightIcon />}
                          colorScheme="blue"
                        >
                          View project
                        </Button>
                      </Stack>
                    </Stack>
                  </CardBody>
                </Card>
              );
            })}
          </VStack>
        </PageLayout.Content>
      </PageLayout>

      {isOpen ? <NewProjectModal {...{ isOpen, onOpen, onClose }} /> : null}
    </>
  );
}
