import { relations } from 'drizzle-orm';
import {
  foreignKey,
  index,
  integer,
  jsonb,
  pgEnum,
  pgTable,
  primaryKey,
  timestamp,
  varchar,
} from 'drizzle-orm/pg-core';
import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import type { Simplify } from 'type-fest';
import type { z } from 'zod';
import { timestamps } from '../../util/sql';
import { inspectionResponseJson } from '../schemas/responses';
import { formFields, forms } from './forms';
import { organizations } from './organizations';
import { projects } from './projects';

export const inspectionStatus = pgEnum('inspection_status', ['not_started', 'in_progress', 'completed']);

export const inspections = pgTable(
  'inspections',
  {
    id: varchar('id', { length: 256 }).notNull(),
    status: inspectionStatus('status').notNull().default('not_started'),
    version: integer('version').notNull().default(1),
    organizationId: varchar('organization_id', { length: 256 })
      .notNull()
      .references(() => organizations.id),
    projectId: varchar('project_id', { length: 256 }).notNull(),
    startedAt: timestamp('started_at', { precision: 0, withTimezone: true, mode: 'string' }),
    completedAt: timestamp('completed_at', { precision: 0, withTimezone: true, mode: 'string' }),
    syncedAt: timestamp('synced_at', { precision: 0, withTimezone: true, mode: 'string' }),
    ...timestamps(),
  },
  (t) => ({
    primary: primaryKey({ columns: [t.organizationId, t.id] }),
    projectFk: foreignKey({
      columns: [t.organizationId, t.projectId],
      foreignColumns: [projects.organizationId, projects.id],
    }).onDelete('cascade'),
    projectFkIndex: index().on(t.organizationId, t.projectId),
  }),
);

export const inspectionResponses = pgTable(
  'inspection_responses',
  {
    id: varchar('id', { length: 256 }).notNull(),
    type: varchar('type', { length: 256 }).notNull().default('text'),
    response: jsonb('response').notNull().default({}),
    inspectionId: varchar('inspection_id', { length: 256 }).notNull(),
    formId: varchar('form_id', { length: 256 }).notNull(),
    fieldId: varchar('field_id', { length: 256 }).notNull(),
    organizationId: varchar('organization_id', { length: 256 }).notNull(),
    ...timestamps(),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.organizationId, t.id] }),
    formIndex: index().on(t.organizationId, t.formId),
    formFk: foreignKey({
      columns: [t.organizationId, t.formId],
      foreignColumns: [forms.organizationId, forms.id],
    }),
    fieldFk: foreignKey({
      columns: [t.organizationId, t.fieldId],
      foreignColumns: [formFields.organizationId, formFields.id],
    }),
    // formFieldUnique: unique().on(t.organizationId, t.formId, t.fieldId),
    inspectionFk: foreignKey({
      columns: [t.organizationId, t.inspectionId],
      foreignColumns: [inspections.organizationId, inspections.id],
    }).onDelete('cascade'),
    inspectionFkIndex: index().on(t.organizationId, t.inspectionId),
  }),
);

export const inspectionResponseAttachments = pgTable(
  'inspection_response_attachments',
  {
    id: varchar('id', { length: 256 }).notNull(),
    url: varchar('url').notNull(),
    fileName: varchar('file_name').notNull(),
    size: integer('size').notNull(),
    contentType: varchar('content_type').notNull(),
    inspectionId: varchar('inspection_id', { length: 256 }).notNull(),
    fieldId: varchar('field_id', { length: 256 }).notNull(),
    responseId: varchar('response_id', { length: 256 }).notNull(),
    organizationId: varchar('organization_id', { length: 256 }).notNull(),
    ...timestamps(),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.organizationId, t.id] }),
    formIndex: index().on(t.organizationId, t.inspectionId),
    reponseIndex: index().on(t.organizationId, t.responseId),
    fieldIndex: index().on(t.organizationId, t.fieldId),
    inspectionFk: foreignKey({
      columns: [t.organizationId, t.inspectionId],
      foreignColumns: [inspections.organizationId, inspections.id],
    }),
    fieldFk: foreignKey({
      columns: [t.organizationId, t.fieldId],
      foreignColumns: [formFields.organizationId, formFields.id],
    }),
    responseFk: foreignKey({
      columns: [t.organizationId, t.responseId],
      foreignColumns: [inspectionResponses.organizationId, inspectionResponses.id],
    }),
  }),
);

export const inspectionRelations = relations(inspections, ({ one, many }) => ({
  forms: many(forms),
  project: one(projects, {
    fields: [inspections.projectId],
    references: [projects.id],
  }),
  responses: many(inspectionResponses),
  attachments: many(inspectionResponseAttachments),
}));

export const inspectionResponseRelations = relations(inspectionResponses, ({ one, many }) => ({
  inspection: one(inspections, {
    fields: [inspectionResponses.organizationId, inspectionResponses.inspectionId],
    references: [inspections.organizationId, inspections.id],
  }),
  field: one(formFields, {
    fields: [inspectionResponses.organizationId, inspectionResponses.fieldId],
    references: [formFields.organizationId, formFields.id],
  }),
  attachments: many(inspectionResponseAttachments),
}));

export const inspectionAttachmentRelations = relations(inspectionResponseAttachments, ({ one }) => ({
  inspection: one(inspections, {
    fields: [inspectionResponseAttachments.organizationId, inspectionResponseAttachments.inspectionId],
    references: [inspections.organizationId, inspections.id],
  }),
  field: one(formFields, {
    fields: [inspectionResponseAttachments.organizationId, inspectionResponseAttachments.fieldId],
    references: [formFields.organizationId, formFields.id],
  }),
  response: one(inspectionResponses, {
    fields: [inspectionResponseAttachments.organizationId, inspectionResponseAttachments.responseId],
    references: [inspectionResponses.organizationId, inspectionResponses.id],
  }),
}));

export const Inspection = createSelectSchema(inspections);
export const InspectionInsert = createInsertSchema(inspections);
export const InspectionResponse = createSelectSchema(inspectionResponses, {
  response: () => inspectionResponseJson,
});
export const InspectionAttachment = createSelectSchema(inspectionResponseAttachments);

export type Inspection = Simplify<z.infer<typeof Inspection>>;
export type InspectionResponse = Simplify<z.infer<typeof InspectionResponse>>;
export type InspectionResponseFileUpload = Simplify<
  InspectionResponse & {
    response: Extract<InspectionResponse['response'], { type: 'file_upload' }>;
  }
>;
export type InspectionAttachment = Simplify<z.infer<typeof InspectionAttachment>>;
export type InspectionInsert = Simplify<z.infer<typeof InspectionInsert>>;
