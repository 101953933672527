import { PageLayout } from '@/components/page-layout';
import { trpc } from '@/lib/trpc';
import { Box, Button, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from '@chakra-ui/react';
import { Roles } from '@fieldbrick/core/constants.ts';
import { PlusIcon } from 'lucide-react';
import { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { InviteList } from './components/show/invite-list.tsx';
import { InviteMember } from './components/show/invite-member.tsx';
import { MemberList } from './components/show/member-list.tsx';
import { TemplateList } from './components/show/template-list.tsx';

export function AdminOrganizationPage() {
  const { organizationId } = useParams();
  const [organization] = trpc.admin.organizations.byId.useSuspenseQuery({ id: organizationId! });
  const organizationHasOwnerRole = organization.memberships.some((m) => m.role === Roles.OWNER);

  const { isOpen, onToggle } = useDisclosure();

  return (
    <PageLayout>
      <PageLayout.Header>
        <Box className="flex flex-col gap-4 w-full">
          <Box className="flex items-center gap-4 justify-between flex-1 w-full">
            <PageLayout.Title>{organization.name}</PageLayout.Title>
            <Button colorScheme="blue" leftIcon={<PlusIcon />} onClick={onToggle}>
              Invite New Member
            </Button>
          </Box>
        </Box>
      </PageLayout.Header>

      <PageLayout.Content>
        <Tabs size="lg">
          <TabList>
            <Tab>Members</Tab>
            <Tab>Invitations</Tab>
            <Tab>Templates</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Box className="flex flex-col gap-4">
                <MemberList members={organization.memberships.flatMap((m) => m.member).filter(Boolean)} />
              </Box>
            </TabPanel>
            <TabPanel>
              <Box className="flex flex-col gap-4">
                <Suspense>
                  <InviteList organizationId={organization.id} />
                </Suspense>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box className="flex flex-col gap-4">
                <Suspense>
                  <TemplateList organizationId={organization.id} />
                </Suspense>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </PageLayout.Content>

      <InviteMember {...{ isOpen, onClose: onToggle, organizationHasOwnerRole, organizationId: organization.id }} />
    </PageLayout>
  );
}
