import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import { type DesignerFormFieldInput, designerFormFieldInput } from '@fieldbrick/core/domain/admin/templates/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { EyeOffIcon, TextIcon } from 'lucide-react';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import type { ElementsType, FormElement, FormElementInstance } from '.';
import { useDesigner } from '../context';

const type: ElementsType = 'ShortTextField';

const extraAttributes = {
  type: 'short_text' as const,
  title: 'Text field',
  description: '',
  properties: {
    type: 'short_text' as const,
    design: { layout: {} },
    meta: {},
  },
  validation: { type: 'short_text' as const },
  visibility: 'visible' as const,
  ownershipType: 'template',
  parentId: null,
  publicVisibility: 'visible',
} satisfies Omit<
  DesignerFormFieldInput,
  'id' | 'version' | 'order' | 'organizationId' | 'formId' | 'createdAt' | 'updatedAt'
>;

const propertiesSchema = designerFormFieldInput.omit({ order: true, organizationId: true, formId: true });

export const TextFieldFormElement: FormElement = {
  type,
  construct: (id: string) => ({
    id,
    type,
    extraAttributes,
    children: [],
  }),
  designerBtnElement: {
    icon: TextIcon,
    label: 'Text Field',
  },
  designerComponent: DesignerComponent,
  propertiesComponent: PropertiesComponent,
};

type CustomInstance = FormElementInstance & {
  extraAttributes: DesignerFormFieldInput;
};

function DesignerComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const element = elementInstance as CustomInstance;
  const { title, description, visibility } = element.extraAttributes;

  return (
    <div className="flex flex-col gap-1 w-full">
      <Box className="flex items-center gap-2">
        {visibility === 'hidden' && (
          <Box className="ml-auto">
            <EyeOffIcon className="text-gray-400" />
          </Box>
        )}
      </Box>
      <FormLabel className="font-semibold text-gray-600 !tracking-tight" my={0}>
        {title}
      </FormLabel>
      <Input readOnly={true} disabled={true} className="!border !shadow-lg !border-black !rounded-none" />
      {description && <p className="text-muted-foreground text-[0.8rem]">{description}</p>}
    </div>
  );
}

function PropertiesComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const element = elementInstance as CustomInstance;
  const { updateElement, elements } = useDesigner();

  const form = useForm<DesignerFormFieldInput>({
    resolver: zodResolver(propertiesSchema),
    mode: 'onBlur',
    defaultValues: {
      ...element.extraAttributes,
      id: element.id,
    },
  });

  useEffect(() => {
    form.reset({ ...element.extraAttributes, id: element.id });
  }, [element, form]);

  function applyChanges(values: DesignerFormFieldInput) {
    updateElement(element.id, {
      ...element,
      extraAttributes: values,
    });
  }

  return (
    <FormProvider {...form}>
      <form onBlur={form.handleSubmit(applyChanges)} className="flex flex-col gap-4">
        <Flex direction="column" gap="4">
          <Controller
            control={form.control}
            name="parentId"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Parent</FormLabel>
                  <Select {...field} value={field.value ?? ''}>
                    <option value="">Select an option</option>
                    {elements
                      .filter((el) => el.id !== element.id)
                      .filter((el) => el.extraAttributes.type === 'group')
                      .map((element) => (
                        <option key={element.id} value={element.id}>
                          {element.extraAttributes.title}
                        </option>
                      ))}
                  </Select>
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="title"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Title</FormLabel>
                  <Textarea {...field} value={field.value ?? ''} />
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />
          <Controller
            control={form.control}
            name="description"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Description</FormLabel>
                  <Textarea {...field} value={field.value ?? ''} />
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />
          <Controller
            control={form.control}
            name="visibility"
            render={({ field }) => {
              return (
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="visibility" mb="0">
                    Is visible?
                  </FormLabel>
                  <Switch
                    id="visibility"
                    isChecked={field.value === 'visible'}
                    onChange={(event) =>
                      form.setValue(field.name, event.target.checked ? 'visible' : 'hidden', {
                        shouldValidate: true,
                      })
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Flex>

        <Divider />
        <h3 className="!font-semibold tracking-tight !text-lg">Validation</h3>

        <Flex gap="4">
          <Controller
            control={form.control}
            name="validation.minLength"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Min length</FormLabel>
                  <Input {...field} type="number" />
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />
          <Controller
            control={form.control}
            name="validation.maxLength"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Max length</FormLabel>
                  <Input {...field} type="number" />
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />
        </Flex>
      </form>
    </FormProvider>
  );
}
