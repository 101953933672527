import { relations } from 'drizzle-orm';
import { pgTable, primaryKey, text, timestamp, varchar } from 'drizzle-orm/pg-core';
import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import type { Simplify } from 'type-fest';
import type { z } from 'zod';
import { id } from '../../util/id';
import { timestamps } from '../../util/sql';
import { users } from './users';

export const organizations = pgTable('organizations', {
  id: varchar('id', { length: 256 })
    .primaryKey()
    .$defaultFn(() => id('org')),
  name: varchar('name').notNull(),
  primaryStreet: varchar('primary_street').notNull(),
  secondaryStreet: varchar('secondary_street'),
  city: varchar('city').notNull(),
  state: varchar('state').notNull(),
  zipCode: varchar('zip').notNull(),
  officePhoneNumber: varchar('office_phone_number').notNull(),
  officeEmailAddress: varchar('office_email_address').notNull(),
  activatedAt: timestamp('activated_at', { precision: 0, withTimezone: true, mode: 'string' }),
  deactivatedAt: timestamp('deactivated_at', { precision: 0, withTimezone: true, mode: 'string' }),
  ...timestamps(),
});

export const orgMemberships = pgTable(
  'organization_memberships',
  {
    id: varchar('id')
      .notNull()
      .$defaultFn(() => id('membership')),
    role: varchar('role').notNull(),
    userId: varchar('user_id')
      .references(() => users.id)
      .notNull(),
    organizationId: varchar('organization_id')
      .notNull()
      .references(() => organizations.id),
    ...timestamps(),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.organizationId, t.userId] }),
  }),
);

export const orgInvitations = pgTable(
  'organization_invitations',
  {
    id: varchar('id', { length: 256 }).notNull(),
    organizationId: varchar('organization_id', { length: 256 })
      .notNull()
      .references(() => organizations.id),
    role: varchar('role').notNull(),
    invitationEmail: varchar('invitation_email').notNull(),
    invitedById: varchar('invited_by_id').references(() => users.id),
    expiresAt: timestamp('expires_at', {
      precision: 0,
      withTimezone: true,
      mode: 'string',
    }).notNull(),
    ...timestamps(),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.organizationId, t.id] }),
  }),
);

export const organizationDisclaimers = pgTable(
  'organization_disclaimers',
  {
    id: varchar('id', { length: 256 })
      .notNull()
      .$defaultFn(() => id('disclaimer')),
    organizationId: varchar('organization_id', { length: 256 })
      .notNull()
      .references(() => organizations.id),
    text: text('disclaimer').notNull(),
    ...timestamps(),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.organizationId, t.id] }),
  }),
);

export const organizationBranding = pgTable(
  'organization_branding',
  {
    id: varchar('id', { length: 256 })
      .notNull()
      .$defaultFn(() => id('branding')),
    organizationId: varchar('organization_id', { length: 256 })
      .notNull()
      .references(() => organizations.id),
    logoUrl: varchar('logo_url'),
    ...timestamps(),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.organizationId, t.id] }),
  }),
);

export const Organization = createSelectSchema(organizations, {
  name: (schema) => schema.name.trim().toLowerCase().max(100),
  primaryStreet: (schema) => schema.primaryStreet.trim().toLowerCase(),
  city: (schema) => schema.city.trim().toLowerCase(),
  state: (schema) => schema.state.trim().toLowerCase(),
  zipCode: (schema) => schema.zipCode.trim().toLowerCase(),
});

export const OrganizationInsert = createInsertSchema(organizations);
export const OrganizationMembership = createSelectSchema(orgMemberships);
export const OrganizationInvitation = createSelectSchema(orgInvitations);

export type Organization = Simplify<z.infer<typeof Organization>>;
export type OrganizationInsert = Simplify<z.infer<typeof OrganizationInsert>>;
export type OrganizationMembership = Simplify<z.infer<typeof OrganizationMembership>>;
export type OrganizationInvitation = Simplify<z.infer<typeof OrganizationInvitation>>;

export const organizationRelations = relations(organizations, ({ many, one }) => ({
  memberships: many(orgMemberships),
  invitations: many(orgInvitations),
  branding: one(organizationBranding),
  disclaimers: many(organizationDisclaimers),
}));

export const organizationDisclaimerRelations = relations(organizationDisclaimers, ({ one }) => ({
  organization: one(organizations, {
    fields: [organizationDisclaimers.organizationId],
    references: [organizations.id],
  }),
}));

export const organizationMembershipRelations = relations(orgMemberships, ({ one }) => ({
  organization: one(organizations, {
    fields: [orgMemberships.organizationId],
    references: [organizations.id],
  }),
  member: one(users, {
    fields: [orgMemberships.userId],
    references: [users.id],
  }),
}));

export const organizationInvitationRelations = relations(orgInvitations, ({ one }) => ({
  organization: one(organizations, {
    fields: [orgInvitations.organizationId],
    references: [organizations.id],
  }),
  invitedBy: one(users, {
    fields: [orgInvitations.invitedById],
    references: [users.id],
  }),
}));

export const organizationBrandingRelations = relations(organizationBranding, ({ one }) => ({
  organization: one(organizations, {
    fields: [organizationBranding.organizationId],
    references: [organizations.id],
  }),
}));
