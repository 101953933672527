import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { Units } from '@fieldbrick/core/constants';
import type { MeasurementFieldProperties } from '@fieldbrick/core/db/schemas/fields';
import { type DesignerFormFieldInput, designerFormFieldInput } from '@fieldbrick/core/domain/admin/templates/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { EyeOffIcon, RulerIcon } from 'lucide-react';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import type { z } from 'zod';
import type { ElementsType, FormElement, FormElementInstance } from '.';
import { useDesigner } from '../context';

const type: ElementsType = 'MeasurementField';

const extraAttributes = {
  type: 'measurement' as const,
  title: 'Measurement',
  description: '',
  properties: {
    unit: 'in',
    type: 'measurement' as const,
    design: {
      layout: {
        columns: 12,
      },
    },
    meta: {},
  },
  validation: { type: 'measurement' as const, min: 0.1 },
  visibility: 'visible' as const,
  ownershipType: 'template',
  parentId: '',
  publicVisibility: 'visible',
} satisfies Omit<
  DesignerFormFieldInput,
  'id' | 'version' | 'order' | 'organizationId' | 'formId' | 'createdAt' | 'updatedAt'
>;

const propertiesSchema = designerFormFieldInput.omit({ order: true, organizationId: true, formId: true });
type Properties = z.infer<typeof propertiesSchema>;

export const MeasurementFieldFormElement: FormElement = {
  type,
  construct: (id: string) => ({
    id,
    type,
    extraAttributes,
    children: [],
  }),
  designerBtnElement: {
    icon: RulerIcon,
    label: 'Measurement',
  },
  designerComponent: DesignerComponent,
  propertiesComponent: PropertiesComponent,
};

type CustomInstance = FormElementInstance & {
  extraAttributes: Properties;
};

function DesignerComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const element = elementInstance as CustomInstance;
  const { title, description, visibility, properties } = element.extraAttributes;

  return (
    <div className="flex flex-col gap-1 w-full">
      <Box className="flex items-center gap-2">
        {visibility === 'hidden' && (
          <Box className="ml-auto">
            <EyeOffIcon className="text-gray-400" />
          </Box>
        )}
      </Box>
      <FormLabel className="font-semibold text-gray-600 !tracking-tight" my={0}>
        {title}
      </FormLabel>
      <InputGroup size="lg" className="!border !shadow-lg !border-black !rounded-none">
        <Input readOnly={true} disabled={true} className="!border-none" />
        <InputRightAddon className="!bg-white !rounded-none !border-none">
          <Text className="text-md font-semibold">{(properties as MeasurementFieldProperties).unit}</Text>
        </InputRightAddon>
      </InputGroup>

      {description && <p className="text-muted-foreground text-[0.8rem]">{description}</p>}
    </div>
  );
}

function PropertiesComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const element = elementInstance as CustomInstance;
  const { updateElement, elements } = useDesigner();
  const form = useForm<Properties>({
    resolver: zodResolver(propertiesSchema),
    mode: 'onBlur',
    defaultValues: {
      ...element.extraAttributes,
      id: element.id,
    },
  });

  useEffect(() => {
    form.reset({ ...element.extraAttributes, id: element.id });
  }, [element, form]);

  function applyChanges(values: Properties) {
    updateElement(element.id, {
      ...element,
      extraAttributes: values,
    });
  }

  return (
    <FormProvider {...form}>
      <form onBlur={form.handleSubmit(applyChanges)} className="flex flex-col gap-4">
        <Flex direction="column" gap="4">
          <Controller
            control={form.control}
            name="parentId"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Parent</FormLabel>
                  <Select {...field} value={field.value ?? ''}>
                    <option value="">Select an option</option>
                    {elements
                      .filter((el) => el.id !== element.id)
                      .filter((el) => el.extraAttributes.type === 'group' || el.extraAttributes.type === 'row')
                      .map((element) => (
                        <option key={element.id} value={element.id}>
                          {element.extraAttributes.title}
                        </option>
                      ))}
                  </Select>
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="title"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Title</FormLabel>
                  <Textarea {...field} value={field.value ?? ''} />
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="description"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Description</FormLabel>
                  <Textarea {...field} value={field.value ?? ''} />
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="visibility"
            render={({ field }) => {
              return (
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="visibility" mb="0">
                    Is visible?
                  </FormLabel>
                  <Switch
                    id="visibility"
                    isChecked={field.value === 'visible'}
                    onChange={(event) =>
                      form.setValue(field.name, event.target.checked ? 'visible' : 'hidden', {
                        shouldValidate: true,
                      })
                    }
                  />
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="publicVisibility"
            render={({ field }) => {
              return (
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor={field.name} mb="0">
                    Show in customer report?
                  </FormLabel>
                  <Switch
                    id={field.name}
                    isChecked={field.value === 'visible'}
                    onChange={(event) =>
                      form.setValue(field.name, event.target.checked ? 'visible' : 'hidden', {
                        shouldValidate: true,
                      })
                    }
                  />
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="properties.unit"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Unit</FormLabel>
                  <Select {...field} value={field.value ?? 'in'}>
                    {Units.map((unit) => (
                      <option key={unit} value={unit}>
                        {unit}
                      </option>
                    ))}
                  </Select>
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Divider />
          <h3 className="!font-semibold tracking-tight !text-lg">Layout</h3>
          <Controller
            control={form.control}
            name="properties.design.layout.columns"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Columns</FormLabel>
                  <Input {...field} type="number" min={1} max={12} />

                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />
        </Flex>
      </form>
    </FormProvider>
  );
}
