import { Box, Button, Flex, FormControl, FormErrorMessage, IconButton, Select } from '@chakra-ui/react';
import { PlusIcon, TrashIcon } from 'lucide-react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useDesigner } from '../designer/context';
import type { RuleBuilderSchema } from './schemas';

export function Actions({ index: nestedIndex }: { index: number }) {
  const { control } = useFormContext<RuleBuilderSchema>();
  const { fields: actions, append, remove } = useFieldArray({ control, name: `rules.${nestedIndex}.actions` as const });
  const { elements } = useDesigner();

  return (
    <Flex className="flex-col gap-4 w-full">
      <h3 className="text-xl font-semibold tracking-tighter">Then</h3>
      {actions.map((action, index) => {
        return (
          <Box className="rounded-md p-4 border w-full gap-4 grid grid-cols-4 items-start" key={action.id}>
            <Controller
              control={control}
              name={`rules.${nestedIndex}.actions.${index}.type` as const}
              render={({ field, fieldState: { error } }) => (
                <FormControl isInvalid={!!error} className="col-span-1">
                  <Select w="full" {...field}>
                    <option value="show">Show</option>
                  </Select>
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              )}
            />

            <Box className="w-full col-span-3 flex items-center gap-2">
              <Controller
                control={control}
                name={`rules.${nestedIndex}.actions.${index}.reference.id` as const}
                render={({ field, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <Select w="full" {...field}>
                      <option value="" />
                      {elements
                        .filter((el) => el.extraAttributes.type !== 'choice')
                        .map((el) => (
                          <option key={el.id} value={el.id}>
                            {el.extraAttributes.title}
                          </option>
                        ))}
                    </Select>
                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />
              {actions.length > 1 && index > 0 ? (
                <Box>
                  <IconButton icon={<TrashIcon />} aria-label="Remove" onClick={() => remove(index)} />
                </Box>
              ) : null}
            </Box>
          </Box>
        );
      })}

      <Box>
        <Button
          leftIcon={<PlusIcon />}
          onClick={() => {
            append({ type: 'show', reference: { id: '' } });
          }}
        >
          Add action
        </Button>
      </Box>
    </Flex>
  );
}
