import { states } from '@/pages/projects/new/lib/states.ts';
import { Box, FormControl, FormErrorMessage, FormLabel, Input, Select, Stack } from '@chakra-ui/react';
import { type UpsertCustomerAddressInput, upsertCustomerAddressInput } from '@fieldbrick/core/domain/customers/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';

type Props = {
  address?: Partial<UpsertCustomerAddressInput>;
  onSubmit: (values: UpsertCustomerAddressInput) => void;
};

export function AddressForm({ address = {}, onSubmit }: Props) {
  const form = useForm<UpsertCustomerAddressInput>({
    resolver: zodResolver(upsertCustomerAddressInput),
    defaultValues: { ...address, state: address.state?.toLowerCase() ?? '' },
  });

  return (
    <FormProvider {...form}>
      <form id="customer-address-form" onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={4} direction="column">
          <Controller
            control={form.control}
            name="primaryStreet"
            render={({ field, fieldState: { error } }) => {
              const hasError = !!error?.message;
              return (
                <FormControl isRequired={true} isInvalid={hasError}>
                  <FormLabel htmlFor={field.name} className="!font-semibold tracking-tight !text-lg">
                    Street address
                  </FormLabel>
                  <Input {...field} id={field.name} size="lg" className="capitalize" />
                  <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                </FormControl>
              );
            }}
          />
          <Controller
            control={form.control}
            name="secondaryStreet"
            render={({ field, fieldState: { error } }) => {
              const hasError = !!error?.message;

              return (
                <FormControl isInvalid={hasError}>
                  <FormLabel htmlFor={field.name} className="!font-semibold tracking-tight !text-lg">
                    Apt, suite, or unit
                  </FormLabel>
                  <Input {...field} id={field.name} size="lg" value={field.value ?? ''} className="capitalize" />
                  <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="city"
            render={({ field, fieldState: { error } }) => {
              const hasError = !!error?.message;
              return (
                <FormControl isInvalid={hasError} isRequired={true}>
                  <FormLabel htmlFor={field.name} className="!font-semibold tracking-tight !text-lg">
                    City
                  </FormLabel>
                  <Input {...field} id={field.name} size="lg" className="capitalize" />
                  <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                </FormControl>
              );
            }}
          />

          <Stack direction="row" spacing={4}>
            <Controller
              control={form.control}
              name="state"
              render={({ field, fieldState: { error } }) => {
                const hasError = !!error?.message;
                return (
                  <FormControl isRequired={true} isInvalid={hasError}>
                    <FormLabel htmlFor={field.name} className="!font-semibold tracking-tight !text-lg">
                      State
                    </FormLabel>
                    <Select {...field} size="lg" className="capitalize">
                      <option value="">Select a state</option>
                      {states.map((state) => (
                        <option key={state.value} value={state.value.toLowerCase()}>
                          {state.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
            />

            <Controller
              control={form.control}
              name="zipCode"
              render={({ field, fieldState: { error } }) => {
                const hasError = !!error?.message;
                return (
                  <FormControl isRequired={true} isInvalid={hasError}>
                    <FormLabel htmlFor={field.name} className="!font-semibold tracking-tight !text-lg">
                      Zip code
                    </FormLabel>
                    <Input id={field.name} {...field} size="lg" maxLength={5} type="tel" className="capitalize" />
                  </FormControl>
                );
              }}
            />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}
