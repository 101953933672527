import { UserStore } from '@/data/users';
import { trpc } from '@/lib/trpc.js';
import { SESSION_COOKIE_NAME } from '@fieldbrick/core/constants';
import type { Admin } from '@fieldbrick/core/db/schema/users.js';
import { type AuthSession, authSessionSchema } from '@fieldbrick/core/domain/auth/schema';
import cookies from 'js-cookie';
import ms from 'ms';
import { createContext, use } from 'react';
import { Navigate } from 'react-router-dom';
import { useSubscribe } from 'replicache-react';
import { useReplicache } from './replicache.tsx';

export type Auth = AuthSession | null;

const AuthContext = createContext<Auth>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const cookie = cookies.get(SESSION_COOKIE_NAME);
  const session = cookie ? authSessionSchema.parse(JSON.parse(atob(cookie))) : null;
  if (!session) {
    return <Navigate to="/login" />;
  }
  return <AuthContext value={session}>{children}</AuthContext>;
}

export function useAuth() {
  const context = use(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an <AuthProvider>');
  }
  return context;
}

export function useCurrentUser() {
  const session = useAuth();
  const replicache = useReplicache();
  const user = useSubscribe(
    replicache,
    async (tx) => {
      const user = await UserStore.get(tx, { id: session.userId });
      return user;
    },
    {
      default: { id: '', email: '', firstName: '', lastName: '' },
    },
  );

  return user;
}

const AdminContext = createContext<Admin | null>(null);

export function AdminContextProvider({ children }: { children: React.ReactNode }) {
  const { data: admin, isLoading } = trpc.admin.auth.me.useQuery(undefined, {
    retry: 0,
    staleTime: ms('1hr'),
  });
  if (isLoading) {
    return null;
  }
  if (!admin) {
    return <Navigate to="/admin/login" replace={true} />;
  }
  return <AdminContext value={admin}>{children}</AdminContext>;
}

export function useAdmin() {
  const context = use(AdminContext);
  if (!context) {
    throw new Error('useAdmin must be used within an <AdminContextProvider>');
  }
  return context;
}
