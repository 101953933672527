import { FormElement } from '@/pages/projects/inspection/components/form-element';
import { useInspection } from '@/pages/projects/inspection/hooks/use-inspection';
import { Button } from '@chakra-ui/react';
import type { FormField } from '@fieldbrick/core/db/schema/forms';
import { FormProvider } from 'react-hook-form';
import { useDesigner } from '../components/designer/context';

export function TemplatePreview() {
  const designer = useDesigner();
  console.log({ designer });
  const formFields = designer.elements.map((el) => el.extraAttributes) as FormField[];

  const { form, fieldTree } = useInspection({ formFields, formRules: designer.rules });

  return (
    <FormProvider {...form}>
      <form className="flex flex-col gap-4" onSubmit={form.handleSubmit((values) => console.log(values))}>
        <div className="grid w-full grid-cols-12 gap-4">
          {fieldTree.map((field) => (
            <div key={field.id} className="col-span-12 gap-4 grid grid-cols-12">
              <FormElement key={field.id} block={field} />
            </div>
          ))}
        </div>
        <Button type="submit">Submit</Button>
      </form>
    </FormProvider>
  );
}
