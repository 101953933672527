import { trpc } from '@/lib/trpc';
import type { FormRule } from '@fieldbrick/core/db/schema/rules';
import { designerFormFieldInput } from '@fieldbrick/core/domain/admin/templates/schema';
import { useDebounce, useIsFirstRender } from '@uidotdev/usehooks';
import { type Dispatch, type ReactNode, type SetStateAction, createContext, use, useEffect, useState } from 'react';
import type { FormElementInstance } from './elements';

type DesignerContextType = {
  elements: FormElementInstance[];
  setElements: Dispatch<SetStateAction<FormElementInstance[]>>;
  addElement: (index: number, element: FormElementInstance) => void;
  removeElement: (id: string) => void;
  selectedElement: FormElementInstance | null;
  setSelectedElement: Dispatch<SetStateAction<FormElementInstance | null>>;
  updateElement: (id: string, element: FormElementInstance) => void;

  rules: FormRule[];
  setRules: Dispatch<SetStateAction<FormRule[]>>;
  updateRules: (rules: Omit<FormRule, 'organizationId' | 'formId' | 'createdAt' | 'updatedAt'>[]) => void;
  showSavedIndicator: boolean;
};

export const DesignerContext = createContext<DesignerContextType | null>(null);

type ContextProviderProps = {
  children: ReactNode;
  defaultElements?: FormElementInstance[];
  defaultRules?: FormRule[];
  organizationId: string;
  templateId: string;
};

export function DesignerContextProvider({
  children,
  defaultElements,
  defaultRules,
  organizationId,
  templateId,
}: ContextProviderProps) {
  const [elements, setElements] = useState<FormElementInstance[]>(defaultElements ?? []);
  const [rules, setRules] = useState<FormRule[]>(defaultRules ?? []);
  const debouncedElements = useDebounce(elements, 250);
  const [selectedElement, setSelectedElement] = useState<FormElementInstance | null>(null);

  const [showSavedIndicator, setShowSavedIndicator] = useState(false);
  const { mutate: updateFields } = trpc.admin.organizations.templates.fields.upsert.useMutation({
    onSuccess() {
      setShowSavedIndicator(true);
    },
  });

  const { mutate: updateRules } = trpc.admin.organizations.templates.rules.upsert.useMutation({
    onSuccess(rules) {
      setRules(rules);
      setShowSavedIndicator(true);
    },
  });

  useEffect(() => {
    if (!showSavedIndicator) {
      return;
    }

    const handle = setTimeout(() => {
      setShowSavedIndicator(false);
    }, 1000);
    return () => clearTimeout(handle);
  }, [showSavedIndicator]);

  const isFirstRender = useIsFirstRender();

  // Whenever the elements change, we want to update the fields in the backend
  // but we want to devounce the update so that we don't make too many requests
  useEffect(() => {
    if (isFirstRender) {
      return;
    }

    const rawElements = debouncedElements.map((element, index) => ({
      ...element.extraAttributes,
      id: element.id,
      order: index,
      formId: templateId!,
      organizationId: organizationId!,
      ownershipType: 'template' as const,
    }));

    const fields = designerFormFieldInput.array().parse(rawElements);
    updateFields({ fields, organizationId: organizationId!, formId: templateId! });
  }, [debouncedElements, templateId, organizationId, updateFields, isFirstRender]);

  const addElement = (index: number, element: FormElementInstance) => {
    setElements((prev) => {
      const newElements = [...prev];
      newElements.splice(index, 0, element);
      return newElements;
    });
  };

  const removeElement = (id: string) => {
    setSelectedElement(null);
    setElements((prev) => prev.filter((element) => element.id !== id));
  };

  const updateElement = (id: string, element: FormElementInstance) =>
    setElements((prev) => {
      const newElements = [...prev];
      const index = newElements.findIndex((el) => el.id === id);
      newElements[index] = element;
      return newElements;
    });

  return (
    <DesignerContext.Provider
      value={{
        elements,
        setElements,
        addElement,
        removeElement,
        selectedElement,
        setSelectedElement,
        updateElement,
        updateRules: (rules) => {
          return updateRules({ rules, organizationId: organizationId!, formId: templateId! });
        },
        showSavedIndicator,
        rules,
        setRules,
      }}
    >
      {children}
    </DesignerContext.Provider>
  );
}

export function useDesigner() {
  const context = use(DesignerContext);

  if (!context) {
    throw new Error('useDesigner must be used within a DesignerContext');
  }

  return context;
}
