import { relations } from 'drizzle-orm';
import { foreignKey, index, jsonb, pgTable, primaryKey, varchar } from 'drizzle-orm/pg-core';
import { createSelectSchema } from 'drizzle-zod';
import type { Simplify } from 'type-fest';
import type { z } from 'zod';
import { timestamps } from '../../util/sql';
import { ruleAction, ruleDefinition } from '../schemas/rules';
import { ownershipType } from './enums';
import { forms } from './forms';
import { organizations } from './organizations';

export const formRules = pgTable(
  'form_rules',
  {
    id: varchar('id', { length: 256 }).notNull(),
    ownershipType: ownershipType('ownership_type').notNull(),
    actions: jsonb('actions').notNull().default([]),
    formId: varchar('form_id', { length: 255 }).notNull(),
    definition: jsonb('definition').notNull().default({}),
    organizationId: varchar('organization_id', { length: 256 }).notNull(),
    ...timestamps(),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.organizationId, t.id] }),
    ownershipTypeIndex: index().on(t.ownershipType),
    formFk: foreignKey({
      columns: [t.organizationId, t.formId],
      foreignColumns: [forms.organizationId, forms.id],
    }).onDelete('cascade'),
    formFkIndex: index().on(t.organizationId, t.formId),
  }),
);

export const ruleRelations = relations(formRules, ({ one }) => ({
  form: one(forms, {
    fields: [formRules.organizationId, formRules.formId],
    references: [forms.organizationId, forms.id],
  }),
  organization: one(organizations, {
    fields: [formRules.organizationId],
    references: [organizations.id],
  }),
}));

export const FormRule = createSelectSchema(formRules, {
  actions: () => ruleAction.array(),
  definition: () => ruleDefinition,
});
export type FormRule = Simplify<z.infer<typeof FormRule>>;
