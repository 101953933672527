import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import type { User } from '@fieldbrick/core/db/schema/users';

export function MemberList({ members }: { members: User[] }) {
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
          </Tr>
        </Thead>
        <Tbody>
          {members.map((member) => {
            return (
              <Tr key={member.id}>
                <Td>
                  {member.firstName} {member.lastName}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
