import { z } from 'zod';
import { Roles } from '../../constants';

export const inviteMemberInput = z.object({
  emailAddress: z.string().email().trim().toLowerCase(),
  role: z.enum([Roles.OWNER, Roles.TECH, Roles.OFFICE]),
  organizationId: z.string().min(1, 'Organization ID is required'),
});

export type InviteMemberInput = z.infer<typeof inviteMemberInput>;
