import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Select,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import type { MultipleChoiceFieldProperties } from '@fieldbrick/core/db/schemas/fields';
import { type DesignerFormFieldInput, designerFormFieldInput } from '@fieldbrick/core/domain/admin/templates/schema';
import { id } from '@fieldbrick/core/util/id';
import { zodResolver } from '@hookform/resolvers/zod';
import { EyeOffIcon, ListIcon, PlusIcon, SquircleIcon, TrashIcon } from 'lucide-react';
import { useEffect } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import type { z } from 'zod';
import type { ElementsType, FormElement, FormElementInstance } from '.';
import { useDesigner } from '../context';

const type: ElementsType = 'MultipleChoiceField';

const extraAttributes = {
  type: 'multiple_choice' as const,
  title: 'Multiple choice',
  description: '',
  properties: {
    type: 'multiple_choice' as const,
    design: { layout: {} },
    meta: {},
    options: [],
  },
  validation: { type: 'multiple_choice' as const },
  visibility: 'visible' as const,
  ownershipType: 'template',
  parentId: null,
  publicVisibility: 'visible',
} satisfies Omit<
  DesignerFormFieldInput,
  'id' | 'version' | 'order' | 'organizationId' | 'formId' | 'createdAt' | 'updatedAt'
>;

const base = designerFormFieldInput.omit({ order: true, organizationId: true, formId: true });
const propertiesSchema = base;

type DesignerFormFieldInputWithChoices = z.infer<typeof propertiesSchema>;

export const MultipleChoiceFieldFormElement: FormElement = {
  type,
  construct: (id: string) => ({
    id,
    type,
    extraAttributes,
    children: [],
  }),
  designerBtnElement: {
    icon: ListIcon,
    label: 'Multiple choice',
  },
  designerComponent: DesignerComponent,
  propertiesComponent: PropertiesComponent,
};

type CustomInstance = FormElementInstance & {
  extraAttributes: DesignerFormFieldInputWithChoices;
};

function DesignerComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const element = elementInstance as CustomInstance;
  const { title, visibility, description, properties } = element.extraAttributes;
  return (
    <div className="flex flex-col gap-1 w-full">
      {visibility === 'hidden' && (
        <Box className="ml-auto">
          <EyeOffIcon className="text-gray-400" />
        </Box>
      )}
      <FormLabel className="font-semibold text-gray-600 !tracking-tight" my={0}>
        {title}
      </FormLabel>

      <div className="flex gap-2 flex-wrap">
        {(properties as MultipleChoiceFieldProperties).options.map((choice) => (
          <Button key={choice.id} size="lg" type="button" isDisabled={true}>
            <Box className="flex gap-2 items-center">
              <SquircleIcon />
              <span>{choice.title}</span>
            </Box>
          </Button>
        ))}
      </div>

      {description && <p className="text-muted-foreground text-[0.8rem]">{description}</p>}
    </div>
  );
}

function PropertiesComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const element = elementInstance as CustomInstance;
  const { updateElement, elements } = useDesigner();
  const form = useForm<DesignerFormFieldInputWithChoices>({
    resolver: zodResolver(propertiesSchema),
    mode: 'onBlur',
    defaultValues: {
      ...element.extraAttributes,
      id: element.id,
    },
  });

  useEffect(() => {
    form.reset({ ...element.extraAttributes, id: element.id });
  }, [element, form]);

  function applyChanges(values: DesignerFormFieldInputWithChoices) {
    updateElement(element.id, {
      ...element,
      extraAttributes: values,
    });
  }

  const { append, fields: choices, remove } = useFieldArray({ control: form.control, name: 'properties.options' });

  return (
    <FormProvider {...form}>
      <form onBlur={form.handleSubmit(applyChanges)} className="flex flex-col gap-4">
        <Flex direction="column" gap="4">
          <Controller
            control={form.control}
            name="parentId"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Parent</FormLabel>
                  <Select {...field} value={field.value ?? ''}>
                    <option value="">Select an option</option>
                    {elements
                      .filter((el) => el.id !== element.id)
                      .filter((el) => el.extraAttributes.type === 'group')
                      .map((element) => (
                        <option key={element.id} value={element.id}>
                          {element.extraAttributes.title}
                        </option>
                      ))}
                  </Select>
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="title"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Title</FormLabel>
                  <Textarea {...field} />
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="description"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Description</FormLabel>
                  <Textarea {...field} value={field.value ?? ''} />
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="visibility"
            render={({ field }) => {
              return (
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="visibility" mb="0">
                    Is visible?
                  </FormLabel>
                  <Switch
                    id="visibility"
                    isChecked={field.value === 'visible'}
                    onChange={(event) =>
                      form.setValue(field.name, event.target.checked ? 'visible' : 'hidden', {
                        shouldValidate: true,
                      })
                    }
                  />
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="publicVisibility"
            render={({ field }) => {
              return (
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor={field.name} mb="0">
                    Show in customer report?
                  </FormLabel>
                  <Switch
                    id={field.name}
                    isChecked={field.value === 'visible'}
                    onChange={(event) =>
                      form.setValue(field.name, event.target.checked ? 'visible' : 'hidden', {
                        shouldValidate: true,
                      })
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Flex>

        <Divider />
        <Box className="flex flex-col gap-4">
          <h3 className="font-semibold tracking-tighter">Choices</h3>
          <Flex direction="column" gap="4">
            {choices.map((choice, index) => (
              <Controller
                key={choice.id}
                control={form.control}
                name={`properties.options.${index}.title` as const}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <FormControl isInvalid={!!error}>
                      <Flex gap="2">
                        <Textarea {...field} />
                        <IconButton icon={<TrashIcon />} aria-label="Remove" onClick={() => remove(index)} />
                      </Flex>
                      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                    </FormControl>
                  );
                }}
              />
            ))}

            <Button
              leftIcon={<PlusIcon />}
              onClick={() => {
                append({ id: id('field'), title: '' });
              }}
            >
              Add choice
            </Button>
          </Flex>
        </Box>
      </form>
    </FormProvider>
  );
}
