import { cn } from '@/lib/cn';

function Layout({
  children,
  className,
  ...props
}: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn('flex flex-col gap-4 w-full', className)} {...props}>
      {children}
    </div>
  );
}

function Header({
  children,
  className,
  ...props
}: { children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn('flex items-center justify-between', className)} {...props}>
      {children}
    </div>
  );
}

function Content({ children }: { children: React.ReactNode }) {
  return children;
}

function Title({ children }: { children: React.ReactNode }) {
  return <h1 className="text-3xl font-medium tracking-tighter capitalize">{children}</h1>;
}

export const PageLayout = Object.assign(Layout, { Header, Content, Title }) as typeof Layout & {
  Content: typeof Content;
  Header: typeof Header;
  Title: typeof Title;
};
