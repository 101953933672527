import { PageLayout } from '@/components/page-layout';
import { trpc } from '@/lib/trpc';
import { states } from '@/pages/projects/new/lib/states';
import {
  Box,
  Button,
  Card,
  CardBody,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';
import { OrganizationInsert } from '@fieldbrick/core/db/schema/organizations';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useIMask } from 'react-imask';
import { useNavigate } from 'react-router-dom';

export function AdminNewOrganizationPage() {
  const form = useForm<OrganizationInsert>({
    resolver: zodResolver(OrganizationInsert),
    defaultValues: {
      name: '',
      primaryStreet: '',
      secondaryStreet: '',
      city: '',
      state: '',
      zipCode: '',
      officeEmailAddress: '',
      officePhoneNumber: '',
    },
  });

  const toast = useToast();
  const navigate = useNavigate();
  const { mutate, isPending } = trpc.admin.organizations.create.useMutation({
    onSuccess() {
      toast({ title: 'Organization created', status: 'success' });
      navigate('/admin/organizations');
    },
  });

  const { ref } = useIMask(
    { mask: '000-000-0000', placeholder: '555-555-5555', showMaskOnHover: true },
    {
      onAccept(value) {
        form.setValue('officePhoneNumber', value, { shouldValidate: true });
      },
    },
  );

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Title>New Organization</PageLayout.Title>
      </PageLayout.Header>

      <PageLayout.Content>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit((values) => mutate(values))}>
            <Card variant="outline">
              <CardBody className="flex flex-col gap-4">
                <Controller
                  name="name"
                  control={form.control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl className="flex flex-col" isRequired={true}>
                        <FormLabel>Organization name</FormLabel>
                        <Input {...field} id={field.name} />
                        {error ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                      </FormControl>
                    );
                  }}
                />

                <Box className="grid grid-cols-2 gap-4">
                  <Controller
                    name="primaryStreet"
                    control={form.control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormControl className="flex flex-col" isRequired={true}>
                          <FormLabel>Primary street</FormLabel>
                          <Input {...field} id={field.name} />
                          {error ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                        </FormControl>
                      );
                    }}
                  />
                  <Controller
                    name="secondaryStreet"
                    control={form.control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormControl className="flex flex-col">
                          <FormLabel>Secondary street</FormLabel>
                          <Input {...field} id={field.name} value={field.value ?? ''} />
                          {error ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                        </FormControl>
                      );
                    }}
                  />
                  <Box className="col-span-2 flex items-center gap-2">
                    <Controller
                      name="city"
                      control={form.control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <FormControl className="flex flex-col" isRequired={true}>
                            <FormLabel>City</FormLabel>
                            <Input {...field} id={field.name} value={field.value ?? ''} />
                            {error ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                          </FormControl>
                        );
                      }}
                    />
                    <Controller
                      name="state"
                      control={form.control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <FormControl className="flex flex-col" isRequired={true}>
                            <FormLabel>State</FormLabel>
                            <Select {...field}>
                              {states.map((state) => (
                                <option key={state.value} value={state.value}>
                                  {state.label}
                                </option>
                              ))}
                            </Select>
                            {error ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                          </FormControl>
                        );
                      }}
                    />
                    <Controller
                      name="zipCode"
                      control={form.control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <FormControl className="flex flex-col" isRequired={true}>
                            <FormLabel>Zip code</FormLabel>
                            <Input {...field} id={field.name} value={field.value ?? ''} maxLength={5} />
                            {error ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                          </FormControl>
                        );
                      }}
                    />
                  </Box>

                  <Controller
                    name="officeEmailAddress"
                    control={form.control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormControl className="flex flex-col" isRequired={true}>
                          <FormLabel>Office email address</FormLabel>
                          <Input {...field} id={field.name} value={field.value ?? ''} type="email" />
                          {error ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                        </FormControl>
                      );
                    }}
                  />
                  <Controller
                    name="officePhoneNumber"
                    control={form.control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormControl className="flex flex-col" isRequired={true}>
                          <FormLabel>Office phone</FormLabel>
                          <Input
                            ref={ref as React.RefObject<HTMLInputElement>}
                            id={field.name}
                            type="tel"
                            defaultValue={field.value}
                          />
                          {error ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                        </FormControl>
                      );
                    }}
                  />
                </Box>

                <Button colorScheme="blue" type="submit" isLoading={isPending}>
                  Save
                </Button>
              </CardBody>
            </Card>
          </form>
        </FormProvider>
      </PageLayout.Content>
    </PageLayout>
  );
}
