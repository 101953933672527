import { trpc } from '@/lib/trpc';
import { SESSION_COOKIE_NAME } from '@fieldbrick/core/constants';
import { reactEnv } from '@fieldbrick/env/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { httpBatchLink } from '@trpc/client';

import { useState } from 'react';
import superjson from 'superjson';

export function TrpcProvider({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {},
        },
      }),
  );
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          transformer: superjson,
          url: `${reactEnv.VITE_PUBLIC_API_URL}/trpc`,
          fetch(url, options) {
            return fetch(url, { ...options, credentials: 'include' });
          },
          headers() {
            const token = localStorage.getItem(SESSION_COOKIE_NAME);
            if (!token) {
              return new Headers();
            }
            return new Headers({
              Authorization: `Bearer ${token}`,
            });
          },
        }),
      ],
    }),
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools />
      </QueryClientProvider>
    </trpc.Provider>
  );
}
