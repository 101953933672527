import { useAuth } from '@/components/providers/auth.tsx';
import { Box, Button, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowLeftIcon } from 'lucide-react';
import { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';
import { useFetchNewProjectData } from '../../index/hooks/data.js';
import { type NewProjectFormValues, newProjectSchema } from '../schema.js';
import { NewAddressForm } from './new-address-form.tsx';
import { NewCustomerForm } from './new-customer-form.tsx';
import { NewProjectContext, type NewProjectState } from './new-project-context.tsx';
import { NewProjectForm } from './new-project-form.tsx';
export function NewProject() {
  const [state, setState] = useState<NewProjectState>('startProject');

  const form = useForm<NewProjectFormValues>({
    resolver: zodResolver(newProjectSchema),
    defaultValues: { templateIds: [] },
  });

  const customerId = useWatch({ name: 'customer.id', control: form.control });
  const { templates, customers, addressesByCustomer } = useFetchNewProjectData();
  const addresses = addressesByCustomer[customerId] ?? [];
  const { organizationId } = useAuth();

  const view = match(state)
    .with('startProject', () => <NewProjectForm {...{ addresses, templates, customerId, customers }} />)
    .with('addCustomerAddress', () => <NewAddressForm customerId={customerId} />)
    .with('addCustomer', () => <NewCustomerForm />)
    .exhaustive();

  const title = match(state)
    .with('startProject', () => 'Start new project')
    .with('addCustomerAddress', () => 'Add customer address')
    .with('addCustomer', () => 'Add customer')
    .exhaustive();

  const buttons = match(state)
    .with('startProject', () => (
      <>
        <Button variant="ghost" as={Link} to={`/org/${organizationId}/projects`}>
          Cancel
        </Button>
        <Button colorScheme="blue" type="submit" form="new-project-form">
          Start project
        </Button>
      </>
    ))
    .with('addCustomer', () => (
      <>
        <Button variant="ghost" onClick={() => setState('startProject')} leftIcon={<ArrowLeftIcon />}>
          Go back
        </Button>
        <Button colorScheme="blue" type="submit" form="new-customer-form">
          Save customer
        </Button>
      </>
    ))
    .with('addCustomerAddress', () => (
      <>
        <Button variant="ghost" onClick={() => setState('startProject')} leftIcon={<ArrowLeftIcon />}>
          Go back
        </Button>
        <Button colorScheme="blue" type="submit" form="new-customer-address-form">
          Save address
        </Button>
      </>
    ))
    .exhaustive();

  return (
    <Box>
      <Box className="mb-8">
        <Text fontSize="2xl" as="b">
          {title}
        </Text>
      </Box>
      <FormProvider {...form}>
        <NewProjectContext value={{ state, setState }}>{view}</NewProjectContext>
      </FormProvider>

      <Box className="flex gap-4 mt-8 justify-end">{buttons}</Box>
    </Box>
  );
}
