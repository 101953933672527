import { PageLayout } from '@/components/page-layout';
import { trpc } from '@/lib/trpc';
import {
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  PinInput,
  PinInputField,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function AdminVerifyPage() {
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { mutate: authenticatedWithCode, isPending } = trpc.admin.auth.magicLink.authenticateWithCode.useMutation({
    onSuccess() {
      toast({ title: 'Code verified', status: 'success', duration: 5000, isClosable: true, position: 'top' });
      navigate('/admin/organizations', { replace: true });
    },
    onError(error) {
      toast({ title: 'Could not verify code.', status: 'error', description: error.message });
    },
  });

  const [code, setCode] = useState('');

  return (
    <PageLayout className="h-screen flex flex-col items-center justify-center">
      <PageLayout.Content>
        <Container maxW="md" py="6">
          <Card className="w-full shadow-md" variant="outline">
            <CardBody>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  const authRequestId = searchParams.get('authRequestId')!;
                  if (!(code && authRequestId)) {
                    toast({
                      title: 'Missing code or authRequestId',
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                      position: 'top',
                    });
                    return;
                  }

                  authenticatedWithCode({ code, authRequestId });
                }}
              >
                <FormControl className="flex flex-col gap-4">
                  <Flex direction="column">
                    <FormLabel>Code</FormLabel>
                    <HStack className="w-full" justifyContent="space-between">
                      <PinInput placeholder="" onComplete={(value) => setCode(value)}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    </HStack>
                  </Flex>
                  <Button colorScheme="blue" type="submit" isLoading={isPending} disabled={!code.trim()}>
                    Login
                  </Button>
                </FormControl>
              </form>
            </CardBody>
          </Card>
        </Container>
      </PageLayout.Content>
    </PageLayout>
  );
}
