import { Box, Flex, FormControl, FormErrorMessage, Select } from '@chakra-ui/react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { P, match } from 'ts-pattern';
import { useDesigner } from '../designer/context';
import type { RuleBuilderSchema } from './schemas';

const operators = {
  multiple_choice: [{ label: 'is', operator: 'is' }],
  radio: [{ label: 'is', operator: 'is' }],
} as const;

export function Rules({ index: nestedIndex }: { index: number }) {
  const { elements } = useDesigner();
  const topLevelElements = elements;

  const { control } = useFormContext<RuleBuilderSchema>();
  const { fields: rules } = useFieldArray({ control, name: `rules.${nestedIndex}.definition.rules` as const });
  const values = useWatch({ control, name: `rules.${nestedIndex}.definition.rules` });

  return (
    <Flex className="flex-col gap-4">
      <h3 className="text-xl font-semibold tracking-tighter">When</h3>

      {rules.map((rule, index) =>
        match(rule)
          .with({ type: P.union('multiple_choice', 'radio') }, () => {
            const currentId = values[index]?.reference.id;
            const choiceChildren = elements.find((el) => el.id === currentId)?.extraAttributes.properties.options ?? [];

            return (
              <Box className="rounded-md p-4 border gap-4 grid grid-cols-12 items-start" key={rule.id}>
                <Controller
                  control={control}
                  name={`rules.${nestedIndex}.definition.rules.${index}.reference.id` as const}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl isInvalid={!!error} className="col-span-6">
                      <Select w="full" {...field}>
                        <option value="" />
                        {topLevelElements.map((el) => (
                          <option key={el.id} value={el.id}>
                            {el.extraAttributes.title}
                          </option>
                        ))}
                      </Select>
                      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name={`rules.${nestedIndex}.definition.rules.${index}.operator` as const}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl isInvalid={!!error}>
                      <Select w="full" {...field}>
                        <option value="" />
                        {operators[rule.type].map((v) => (
                          <option key={v.label} value={v.operator}>
                            {v.label}
                          </option>
                        ))}
                      </Select>
                      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name={`rules.${nestedIndex}.definition.rules.${index}.value` as const}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl isInvalid={!!error} className="col-span-5">
                      <Select w="full" {...field}>
                        <option value="" />
                        {choiceChildren.map((el) => (
                          <option key={el.id} value={el.title}>
                            {el.title}
                          </option>
                        ))}
                      </Select>
                      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
              </Box>
            );
          })
          .otherwise(() => null),
      )}
    </Flex>
  );
}
