import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';

// eslint-disable-next-line unicorn/prevent-abbreviations
export const reactEnv = createEnv({
  clientPrefix: 'VITE_',
  client: {
    VITE_PUBLIC_API_URL: z.string().url(),
    VITE_PUBLIC_WEB_URL: z.string().url(),
    VITE_PUBLIC_APP_ENV: z.union([z.literal('development'), z.literal('production')]),
    VITE_PUBLIC_REPLICACHE_KEY: z.string(),
  },
  server: {
    SENTRY_ORG: z.string().default('fieldbrick'),
    SENTRY_PROJECT: z.string().default('backend'),
    SENTRY_AUTH_TOKEN: z.string().default(''),
    NODE_ENV: z.union([z.literal('development'), z.literal('production')]),
  },
  runtimeEnv: import.meta.env,
});
