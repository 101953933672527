import { trpc } from '@/lib/trpc';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import type { UpsertCustomerAddressInput } from '@fieldbrick/core/domain/customers/schemas';
import { id } from '@fieldbrick/core/util/id';
import { Plus } from 'lucide-react';
import { useRef } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { AddressForm } from './components/address-form';

export function AddNewAddress() {
  const { customerId } = useParams();
  const [_, { refetch }] = trpc.customers.byId.useSuspenseQuery({ id: customerId! });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const cancelRef = useRef<any>(null);

  const handleSubmit: SubmitHandler<UpsertCustomerAddressInput> = async (values: UpsertCustomerAddressInput) => {
    mutate(values);
  };

  const { mutate, isPending } = trpc.customers.address.upsert.useMutation({
    onSuccess() {
      refetch();
      onClose();
      toast({ title: 'Added new customer address.', status: 'success', duration: 3000 });
    },
  });

  return (
    <div>
      <Button onClick={onOpen} variant="link" size="lg" rightIcon={<Plus />} className="!no-underline">
        Add address
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Add New Address
            </AlertDialogHeader>

            <AlertDialogBody>
              <AddressForm address={{ id: id('addr'), customerId: customerId! }} onSubmit={handleSubmit} />
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup size="lg">
                <Button ref={cancelRef} onClick={onClose} variant="ghost">
                  Cancel
                </Button>
                <Button colorScheme="blue" type="submit" isLoading={isPending} form="customer-address-form">
                  Save
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}
