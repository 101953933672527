import { relations } from 'drizzle-orm';
import { foreignKey, index, integer, pgTable, varchar } from 'drizzle-orm/pg-core';
import { timestamps } from '../../util/sql';
import { organizations } from './organizations';
import { projects } from './projects';

export const proposals = pgTable(
  'proposals',
  {
    id: varchar('id', { length: 256 }).primaryKey(),
    projectId: varchar('project_id', { length: 256 }).notNull(),
    organizationId: varchar('organization_id', { length: 256 })
      .notNull()
      .references(() => organizations.id),
    ...timestamps(),
  },
  (t) => ({
    projectFk: foreignKey({
      columns: [t.organizationId, t.projectId],
      foreignColumns: [projects.organizationId, projects.id],
    }),
    projectFkIndex: index().on(t.organizationId, t.projectId),
  }),
);

export const proposalLineItems = pgTable('proposal_line_items', {
  id: varchar('id', { length: 256 }).primaryKey(),
  proposalId: varchar('proposal_id', { length: 256 })
    .notNull()
    .references(() => proposals.id),
  name: varchar('name').notNull(),
  description: varchar('description').notNull(),
  quantity: integer('quantity').notNull().default(1),
  basePriceInCents: integer('base_price_in_cents').notNull().default(0),
  ...timestamps(),
});

export const proposalRelations = relations(proposals, ({ one, many }) => ({
  project: one(projects, {
    fields: [proposals.projectId],
    references: [projects.id],
  }),
  organization: one(organizations, {
    fields: [proposals.organizationId],
    references: [organizations.id],
  }),
  lineItems: many(proposalLineItems),
}));

export const proposalLineItemRelations = relations(proposalLineItems, ({ one }) => ({
  proposal: one(proposals, {
    fields: [proposalLineItems.proposalId],
    references: [proposals.id],
  }),
}));
