import type { FormField } from '@fieldbrick/core/db/schema/forms.js';
// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
import { Array, MutableHashMap, MutableList, Option } from 'effect';
import type { FieldBlock } from './schema.js';

export function traverse<T extends FormField[]>(fields: T) {
  const map = MutableHashMap.fromIterable<string, FieldBlock>(
    fields.map((field) => [field.id, { ...field, children: [] }]),
  );

  return Array.fromIterable(
    Array.reduce(fields, MutableList.empty<FieldBlock>(), (tree, element) => {
      const parent = MutableHashMap.get(map, element.parentId);
      return Option.match(parent, {
        onSome: (parent) => {
          const element_ = MutableHashMap.get(map, element.id);
          if (Option.isSome(element_)) {
            parent.children.push(element_.value);
          }

          return tree;
        },
        onNone: () => {
          const element_ = MutableHashMap.get(map, element.id);
          if (Option.isSome(element_)) {
            return MutableList.append(tree, element_.value);
          }

          return tree;
        },
      });
    }),
  );
}
