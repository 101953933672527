import { z } from 'zod';

export const ruleAction = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('show'),
    reference: z.object({ id: z.string().min(1, 'Reference field ID is required') }),
  }),
]);

const rule = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('multiple_choice'),
    operator: z.literal('is', { message: 'Operator is required' }),
    value: z.string().min(1, 'Value is required'),
    reference: z.object({ id: z.string().min(1, 'Reference field ID is required') }),
  }),
  z.object({
    type: z.literal('radio'),
    operator: z.literal('is', { message: 'Operator is required' }),
    value: z.string().min(1, 'Value is required'),
    reference: z.object({ id: z.string().min(1, 'Reference field ID is required') }),
  }),
]);

type Rule = z.infer<typeof rule>;
export type RuleDefinition = {
  operator: 'and' | 'or';
  rules: Rule[];
};

export const ruleDefinition: z.ZodType<RuleDefinition> = z.object({
  operator: z.union([z.literal('and'), z.literal('or')]),
  // Getting rid of nested ruleDefinitions for now.
  // rules: z.union([rule, z.lazy(() => ruleDefinition)]).array(),
  rules: z.array(rule),
});

// const a = [
//   {
//     uuid: 'd2c94bef-bd38-4e8d-a34e-21e69c324c70',
//     type: 'FORM_TITLE',
//     groupUuid: '799a557e-7c8c-4308-ae78-a16674d44367',
//     groupType: 'TEXT',
//     payload: {
//       safeHTMLSchema: [['Mango Farm']],
//       title: 'Mango Farm',
//     },
//   },
//   {
//     uuid: '94c673bd-dc2f-40a0-95b8-2cd86635a23f',
//     type: 'MULTIPLE_CHOICE_OPTION',
//     groupUuid: '49d51a81-04d5-44ce-b0ec-bf56f599a25d',
//     groupType: 'MULTIPLE_CHOICE',
//     payload: {
//       index: 0,
//       isRequired: true,
//       isFirst: true,
//       isLast: false,
//       text: '1',
//     },
//   },
//   {
//     uuid: 'be1d677e-77c7-42af-bce5-d96911a47241',
//     type: 'MULTIPLE_CHOICE_OPTION',
//     groupUuid: '49d51a81-04d5-44ce-b0ec-bf56f599a25d',
//     groupType: 'MULTIPLE_CHOICE',
//     payload: {
//       isRequired: true,
//       index: 1,
//       isFirst: false,
//       isLast: false,
//       text: '2',
//     },
//   },
//   {
//     uuid: 'fb31f6e8-2a86-4fff-8288-de7d25d8d040',
//     type: 'MULTIPLE_CHOICE_OPTION',
//     groupUuid: '49d51a81-04d5-44ce-b0ec-bf56f599a25d',
//     groupType: 'MULTIPLE_CHOICE',
//     payload: {
//       isRequired: true,
//       index: 2,
//       isFirst: false,
//       isLast: true,
//       text: '3',
//     },
//   },
//   {
//     uuid: '8508d291-a5b2-4560-a717-89a91c51e6d4',
//     type: 'INPUT_TEXT',
//     groupUuid: 'ae3e9a2b-6d3e-4462-9f15-a2128bea8b55',
//     groupType: 'INPUT_TEXT',
//     payload: {
//       isRequired: true,
//       placeholder: '',
//     },
//   },
//   {
//     uuid: 'ec23d490-efd8-4878-a62b-cd3545f5c321',
//     type: 'CONDITIONAL_LOGIC',
//     groupUuid: 'd3a77d9f-3c91-4aab-bdf3-348033692f45',
//     groupType: 'CONDITIONAL_LOGIC',
//     payload: {
//       updateUuid: null,
//       logicalOperator: 'AND',
//       conditionals: [
//         {
//           uuid: 'cd212684-e4e1-4ab2-8546-04ba780c4733',
//           type: 'SINGLE',
//           payload: {
//             field: {
//               uuid: '49d51a81-04d5-44ce-b0ec-bf56f599a25d',
//               type: 'INPUT_FIELD',
//               questionType: 'MULTIPLE_CHOICE',
//               blockGroupUuid: '49d51a81-04d5-44ce-b0ec-bf56f599a25d',
//               title: 'Untitled multiple choice field',
//             },
//             comparison: 'IS',
//             value: 'be1d677e-77c7-42af-bce5-d96911a47241',
//           },
//         },
//         {
//           uuid: '19e88e38-296c-4acd-8632-9c69b04bfc85',
//           type: 'SINGLE',
//           payload: {
//             field: {
//               uuid: 'ae3e9a2b-6d3e-4462-9f15-a2128bea8b55',
//               type: 'INPUT_FIELD',
//               questionType: 'INPUT_TEXT',
//               blockGroupUuid: 'ae3e9a2b-6d3e-4462-9f15-a2128bea8b55',
//               title: 'Untitled short answer field',
//             },
//             comparison: 'IS',
//             value: '123',
//           },
//         },
//       ],
//       actions: [
//         {
//           uuid: 'e46a37a7-47fe-41d9-82e1-f1e32a2f5d4f',
//           type: 'JUMP_TO_PAGE',
//           payload: {
//             jumpToPage: 'd2c94bef-bd38-4e8d-a34e-21e69c324c70',
//           },
//         },
//       ],
//     },
//   },
//   {
//     uuid: 'f33607dd-ef58-4b36-8659-48e872c50ddf',
//     type: 'TEXT',
//     groupUuid: 'ec914b47-cc6c-4cd7-b205-46d291c976c1',
//     groupType: 'TEXT',
//     payload: {
//       safeHTMLSchema: [],
//     },
//   },
// ];
