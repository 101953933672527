import inter from '@fontsource-variable/inter/files/inter-latin-wght-normal.woff2?url';

import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import { ThemeProvider } from 'next-themes';
import { Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import PWABadge from './PWABadge.tsx';
import { TrpcProvider } from './components/providers/trpc.tsx';
import { ApplicationRoutes } from './components/routes.tsx';

const theme = extendTheme({
  fonts: {
    heading: `'Inter Variable', sans-serif`,
    body: `'Inter Variable', sans-serif`,
  },
});

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Fieldbrick</title>
        <link rel="preload" as="font" type="woff2" crossOrigin="" src={inter} />
        <meta name="robots" content="noindex" />
      </Helmet>

      <ChakraProvider theme={theme}>
        <ThemeProvider attribute="class" disableTransitionOnChange={true}>
          <BrowserRouter>
            <TrpcProvider>
              <Suspense>
                <ApplicationRoutes />
              </Suspense>
            </TrpcProvider>
          </BrowserRouter>
          <PWABadge />
        </ThemeProvider>
      </ChakraProvider>
    </HelmetProvider>
  );
}

export default App;
