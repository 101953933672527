import { useRegisterSW } from 'virtual:pwa-register/react';
import { Box, Button, ButtonGroup, Card, CardBody } from '@chakra-ui/react';
import ms from 'ms';

function PWABadge() {
  const period = ms('1m');

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      if (period <= 0) {
        return;
      }
      if (r?.active?.state === 'activated') {
        registerPeriodicSync(period, swUrl, r);
      } else if (r?.installing) {
        r.installing.addEventListener('statechange', (e) => {
          const sw = e.target as ServiceWorker;
          if (sw.state === 'activated') {
            registerPeriodicSync(period, swUrl, r);
          }
        });
      }
    },
  });

  function close() {
    setOfflineReady(false);
    setNeedRefresh(false);
  }

  return (
    (offlineReady || needRefresh) && (
      <Box className="bottom-0 right-0 fixed z-10 m-4">
        <Card variant="outline" className="shadow-lg">
          <CardBody className="flex flex-col gap-4">
            <Box>
              {offlineReady ? (
                <span id="toast-message">App ready to work offline</span>
              ) : (
                <span id="toast-message">New version available! Click reload to update.</span>
              )}
            </Box>
            <Box>
              <ButtonGroup>
                {needRefresh && (
                  <Button colorScheme="blue" onClick={() => updateServiceWorker(true)}>
                    Reload
                  </Button>
                )}
                <Button variant="outline" onClick={() => close()}>
                  Close
                </Button>
              </ButtonGroup>
            </Box>
          </CardBody>
        </Card>
      </Box>
    )
  );
}

export default PWABadge;

function registerPeriodicSync(period: number, swUrl: string, r: ServiceWorkerRegistration) {
  if (period <= 0) {
    return;
  }

  setInterval(async () => {
    if ('onLine' in navigator && !navigator.onLine) {
      return;
    }

    const resp = await fetch(swUrl, {
      cache: 'no-store',
      headers: {
        cache: 'no-store',
        'cache-control': 'no-cache',
      },
    });

    if (resp?.status === 200) {
      await r.update();
    }
  }, period);
}
