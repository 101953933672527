import { type Mutators, mutators } from '@/data/mutators';
import { reactEnv } from '@fieldbrick/env/react';
import ms from 'ms';
import { createContext } from 'react';
import { useEffect } from 'react';
import React from 'react';
import { type PullerResult, type PusherResult, Replicache } from 'replicache';
import { useAuth } from './auth.tsx';

type ReplicacheContext = Replicache<Mutators> | null;
const ReplicacheContext = createContext<ReplicacheContext>(null);

export function ReplicacheProvider({ children }: { children: React.ReactNode }) {
  const [replicache, setReplicache] = React.useState<ReplicacheContext>(null);
  const session = useAuth();

  useEffect(() => {
    const repl = new Replicache({
      name: session.userId,
      licenseKey: reactEnv.VITE_PUBLIC_REPLICACHE_KEY,
      pushURL: new URL('/sync/push', reactEnv.VITE_PUBLIC_API_URL).toString(),
      pullURL: new URL('/sync/pull', reactEnv.VITE_PUBLIC_API_URL).toString(),
      mutators,
      pullInterval: ms('15s'),
    });

    repl.puller = async (req) => {
      const result = await fetch(repl.pullURL, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(req),
        method: 'POST',
        credentials: 'include',
      });

      const response = result.ok ? ((await result.json()) as any) : undefined;
      return {
        response,
        httpRequestInfo: {
          httpStatusCode: result.status,
          errorMessage: result.ok ? '' : result.statusText,
        },
      } satisfies PullerResult;
    };
    repl.pusher = async (req) => {
      const result = await fetch(repl.pushURL, {
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(req),
        method: 'POST',
        credentials: 'include',
      });
      return {
        httpRequestInfo: {
          httpStatusCode: result.status,
          errorMessage: result.ok ? '' : result.statusText,
        },
      } satisfies PusherResult;
    };

    setReplicache(repl);
    return () => {
      repl.close();
    };
  }, [session.userId]);

  if (!replicache) {
    return null;
  }
  return <ReplicacheContext value={replicache}>{children}</ReplicacheContext>;
}

export function useReplicache() {
  const replicache = React.use(ReplicacheContext);
  if (!replicache) {
    throw new Error('useReplicache must be used within a <ReplicacheProvider>');
  }
  return replicache;
}
