import type { Form, FormField } from '@fieldbrick/core/db/schema/forms.js';
import type { Inspection } from '@fieldbrick/core/db/schema/inspections.js';
import type { FormRule } from '@fieldbrick/core/db/schema/rules.js';
import { id } from '@fieldbrick/core/util/id.js';
import { omit } from 'lodash-es';
import { DateTime } from 'luxon';
import { resolveTemplateFields, resolveTemplateRules } from './template-resolver.js';

export type Template = Form & {
  fields: FormField[];
  rules: FormRule[];
};

export function buildInspectionsFromTemplates(projectId: string, templates: Template[], organizationId: string) {
  return templates.map((template) => {
    const inspectionId = id('inspect');
    const formId = id('form');

    const { resolvedFields, fieldResolutionMap } = resolveTemplateFields(
      template.fields.map((field) => ({
        ...field,
        formId,
        ownershipType: 'inspection' as const,
      })),
    );

    const resolvedRules = resolveTemplateRules(
      template.rules.map((rule) => ({
        ...rule,
        id: id('rule'),
        formId,
        ownershipType: 'inspection' as const,
      })),
      fieldResolutionMap,
    );
    const now = DateTime.utc().toSQL();

    const form = {
      ...omit(template, ['fields', 'rules']),
      id: formId,
      ownershipType: 'inspection' as const,
      inspectionId,
    };
    const inspection = {
      id: inspectionId,
      projectId,
      organizationId,
      version: form.version,
      status: 'not_started' as const,
      createdAt: now,
      updatedAt: now,
      syncedAt: null,
      startedAt: null,
      completedAt: null,
    } satisfies Inspection;
    const fields = resolvedFields;
    const rules = resolvedRules;

    return { inspection, form: { ...form, fields, rules } };
  });
}
