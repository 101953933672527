import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  GridItem,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
} from '@chakra-ui/react';
import { capitalize, compact } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import type { InspectionField } from '../../lib/schema.js';

export const MeasurementField = ({ block }: InspectionField) => {
  const { control } = useFormContext<Record<string, { type: 'text'; value?: string }>>();
  const { placeholder, design } = block.properties;
  const layout = design?.layout;
  const { description } = block;
  const fieldName = `${block.id}.value` as const;

  return (
    <GridItem colSpan={compact([12, layout?.columns])} key={block.id}>
      <Controller
        name={`${block.id}.type` as const}
        control={control}
        render={({ field: { value: _, ...field } }) => <input type="hidden" {...field} value="measurement" />}
      />

      <Controller
        name={fieldName}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const hasError = !!error?.message;
          return (
            <FormControl isInvalid={hasError} isRequired={true}>
              <FormLabel htmlFor={fieldName} className="!font-semibold tracking-tight !text-lg">
                {capitalize(block.title)}
              </FormLabel>
              <InputGroup size="lg">
                <Input {...field} id={fieldName} placeholder={placeholder} />
                <InputRightAddon className="!bg-white">
                  <Text className="text-md font-semibold">{block.properties.unit}</Text>
                </InputRightAddon>
              </InputGroup>
              {description ? (
                <FormHelperText className="text-sm font-semibold text-gray-600">
                  {capitalize(description)}
                </FormHelperText>
              ) : undefined}
              {hasError ? <FormErrorMessage>{error.message}</FormErrorMessage> : undefined}
            </FormControl>
          );
        }}
      />
    </GridItem>
  );
};
