import { Form, FormField } from '@fieldbrick/core/db/schema/forms.js';
import { FormRule } from '@fieldbrick/core/db/schema/rules.js';
import { Store } from './store.js';

export const TemplateStore = new Store()
  .setScanner('all', () => ['templates'])
  .setSchema(Form)
  .setPathResolver((args: { id: string }) => ['templates', args.id])
  .build();

export const FormStore = new Store()
  .setSchema(Form)
  .setScanner('all', (args: { inspectionId: string }) => ['forms', args.inspectionId])
  .setPathResolver((args: { inspectionId: string; id: string }) => ['forms', args.inspectionId, args.id])
  .build();

export const FormFieldStore = new Store()
  .setSchema(FormField)
  .setScanner('all', (args: { formId: string }) => ['formFields', args.formId])
  .setPathResolver((args: { formId: string; id: string }) => ['formFields', args.formId, args.id])
  .build();

export const FormRuleStore = new Store()
  .setSchema(FormRule)
  .setScanner('all', (args: { formId: string }) => ['formRules', args.formId])
  .setPathResolver((args: { formId: string; id: string }) => ['formRules', args.formId, args.id])
  .build();
