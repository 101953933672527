import { type Draft, create } from 'mutative';
import type { ReadTransaction, WriteTransaction } from 'replicache';
import type { z } from 'zod';

type PathResolver = (...args: any) => string[];

type BuildableStore<
  KeyPathResolver extends PathResolver,
  Scanners extends Record<string, PathResolver>,
  Schema extends z.ZodTypeAny,
  Item extends z.infer<Schema>,
> = {
  [Name in keyof Scanners]: (tx: ReadTransaction, ...args: Parameters<Scanners[Name]>) => Promise<Item[]>;
} & {
  get: <I extends Item>(tx: ReadTransaction, ...args: Parameters<KeyPathResolver>) => Promise<I>;
  put: (tx: WriteTransaction, id: Parameters<KeyPathResolver>, item: Partial<Item> | Partial<Item>[]) => Promise<void>;
  update: (
    tx: WriteTransaction,
    id: Parameters<KeyPathResolver>,
    updator: (item: Draft<Item>) => void,
  ) => Promise<void>;
  delete: (tx: WriteTransaction, id: Parameters<KeyPathResolver>) => Promise<void>;
};

export class Store<
  KeyPathResolver extends PathResolver,
  Scanners extends Record<string, PathResolver>,
  Schema extends z.ZodTypeAny,
  Item extends z.infer<Schema>,
> {
  #schema?: Schema = undefined;
  #pathResolver?: PathResolver = undefined;
  #scanners: Record<string, PathResolver> = {};

  setScanner<Name extends string, Resolver extends PathResolver>(name: Name, resolver: Resolver) {
    this.#scanners[name] = resolver;
    return this as unknown as Store<KeyPathResolver, Scanners & { [name in Name]: Resolver }, Schema, Item>;
  }

  setSchema<SchemaType extends z.ZodTypeAny>(schema: SchemaType) {
    this.#schema = schema as unknown as Schema;
    return this as unknown as Store<KeyPathResolver, Scanners, SchemaType, z.infer<SchemaType>>;
  }

  setPathResolver<Resolver extends PathResolver>(resolver: Resolver) {
    this.#pathResolver = resolver;
    return this as unknown as Store<Resolver, Scanners, Schema, Item>;
  }

  build() {
    const result = {} as any;

    for (const [name, resolver] of Object.entries(this.#scanners)) {
      result[name] = async (tx: ReadTransaction, ...args: any[]) => {
        const path = `/${resolver(...args).join('/')}`;
        const items = await tx.scan({ prefix: path }).values().toArray();

        return this.#schema!.array().parse(items);
      };
    }

    result.get = async (tx: ReadTransaction, ...args: any[]) => {
      const path = `/${this.#pathResolver!(...args).join('/')}`;
      const item = await tx.get(path);
      const schema = Array.isArray(item) ? this.#schema!.array() : this.#schema!;

      return schema.parse(item);
    };

    result.put = async (tx: WriteTransaction, args: any[], item: Partial<Item> | Partial<Item>[]) => {
      const path = `/${this.#pathResolver!(...args).join('/')}`;
      try {
        const parsed = Array.isArray(item) ? this.#schema!.array().parse(item) : this.#schema!.parse(item);

        await tx.set(path, parsed);
      } catch (ex) {
        console.error({ ex, path, item });
        throw ex;
      }
    };

    result.update = async (tx: WriteTransaction, id: string[], updator: (item: Draft<Item>) => void) => {
      const path = `/${this.#pathResolver!(...id).join('/')}`;
      const item = await tx.get(path);
      const parsed = this.#schema!.parse(item);
      const final = create(parsed, updator);
      await tx.set(path, final);
    };

    result.delete = async (tx: WriteTransaction, id: string[]) => {
      const path = `/${this.#pathResolver!(...id).join('/')}`;
      await tx.del(path);
    };

    return result as BuildableStore<KeyPathResolver, Scanners, Schema, Item>;
  }
}
