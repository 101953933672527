import { AuthorizeRole } from '@/components/authorize-role';
import { useAuth } from '@/components/providers/auth';
import { useReplicache } from '@/components/providers/replicache';
import { Box, Button } from '@chakra-ui/react';
import { Roles } from '@fieldbrick/core/constants';
import type { Inspection } from '@fieldbrick/core/db/schema/inspections';
import { merge } from 'lodash-es';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';

type StatusButtonProps<I extends Inspection> = {
  inspection: I;
};

export function InspectionStatusButton<I extends Inspection>({ inspection }: StatusButtonProps<I>) {
  const replicache = useReplicache();
  const navigate = useNavigate();
  const { organizationId } = useAuth();

  const handleStartInspection = async () => {
    await replicache.mutate.updateInspection(
      merge(inspection, { status: 'in_progress', startedAt: DateTime.utc().toSQL() }),
    );
    navigate(`/org/${organizationId}/projects/${inspection.projectId}/inspections/${inspection.id}`);
  };

  const handleInProgress = () => {
    navigate(`/org/${organizationId}/projects/${inspection.projectId}/inspections/${inspection.id}`);
  };

  return (
    <>
      {match(inspection.status)
        .with('not_started', () => (
          <Button colorScheme="blue" onClick={handleStartInspection}>
            Start inspection
          </Button>
        ))
        .with('in_progress', () => (
          <Button colorScheme="blue" onClick={handleInProgress}>
            Continue inspection
          </Button>
        ))
        .with('completed', () => (
          <Box className="flex gap-2 items-center">
            <AuthorizeRole role={[Roles.OWNER, Roles.TECH]}>
              <Button
                onClick={async () => {
                  await replicache.mutate.updateInspection(merge(inspection, { status: 'in_progress' }));
                  navigate(`/org/${organizationId}/projects/${inspection.projectId}/inspections/${inspection.id}`);
                }}
              >
                Edit
              </Button>
            </AuthorizeRole>
            {/* <Button
              leftIcon={<CheckIcon />}
              colorScheme="green"
              onClick={() => {
                navigate(
                  `/org/${organizationId}/projects/${inspection.projectId}/inspections/${inspection.id}/summary`,
                );
              }}
            >
              View completed inspection
            </Button> */}
          </Box>
        ))
        .exhaustive()}
    </>
  );
}
