import { z } from 'zod';

export const inspectionResponseJson = z.discriminatedUnion('type', [
  z.object({ type: z.literal('text'), value: z.string() }),
  z.object({ type: z.literal('multiple_choice'), value: z.string().array() }),
  z.object({ type: z.literal('select'), value: z.string() }),
  z.object({ type: z.literal('radio'), value: z.string() }),
  z.object({
    type: z.literal('file_upload'),
    value: z.array(
      z.union([
        z.object({
          id: z.string(),
          encoded: z.string(),
          contentType: z.string(),
          fileName: z.string(),
          size: z.number(),
        }),
        z.object({
          id: z.string(),
          url: z.string().url(),
          contentType: z.string(),
          fileName: z.string(),
          size: z.number(),
        }),
      ]),
    ),
  }),
]);
