import { trpc } from '@/lib/trpc';
import {
  Badge,
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react';
import { startCase } from 'lodash-es';

import type { FormRule } from '@fieldbrick/core/db/schema/rules';
import { AnimatePresence, motion } from 'framer-motion';
import { BadgeCheckIcon } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { DesignerContextProvider, useDesigner } from '../components/designer/context';
import { Designer } from '../components/designer/designer';
import type { ElementsType } from '../components/designer/elements';
import { RuleBuilder } from '../components/rule-builder/rule-builder';
import { TemplatePreview } from './preview';

function Header({ title, updateTemplate }: { title: string; updateTemplate: (title: string) => void }) {
  const { showSavedIndicator } = useDesigner();

  return (
    <Flex alignItems="center" gap="4">
      <Editable defaultValue={title} className="text-3xl font-semibold tracking-tighter" onSubmit={updateTemplate}>
        <EditablePreview />
        <EditableInput />
      </Editable>

      <AnimatePresence initial={false}>
        {showSavedIndicator && (
          <Box
            className="flex gap-2 items-center"
            as={motion.div}
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              scale: 1,
              opacity: 1,
              transition: {
                type: 'spring',
                stiffness: 260,
                damping: 20,
              },
            }}
          >
            <BadgeCheckIcon className="text-green-500" />
            <Badge colorScheme="green">Saved</Badge>
          </Box>
        )}
      </AnimatePresence>
    </Flex>
  );
}

export function AdminTemplateDesignerPage() {
  const { templateId, organizationId } = useParams();
  const [template] = trpc.admin.organizations.templates.byId.useSuspenseQuery({ id: templateId! });
  const { mutate } = trpc.admin.organizations.templates.update.useMutation();
  const updateTemplate = (title: string) => {
    mutate({ id: templateId!, organizationId: organizationId!, title });
  };

  const defaultElements = template.fields.map((field) => ({
    id: field.id,
    type: field.type.split('_').concat('field').map(startCase).join('') as ElementsType,
    extraAttributes: field,
    children: [],
  }));

  const { isOpen, onToggle } = useDisclosure();

  return (
    <DesignerContextProvider
      defaultElements={defaultElements}
      defaultRules={template.rules as FormRule[]}
      organizationId={organizationId!}
      templateId={templateId!}
    >
      <Flex className="flex-col gap-4">
        <Box className="flex justify-between items-center">
          <Header title={template.name} updateTemplate={updateTemplate} />
          <Button onClick={onToggle}>Preview</Button>
        </Box>
        <Tabs size="lg">
          <TabList>
            <Tab>Form Designer</Tab>
            <Tab>Rule Builder</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Designer />
            </TabPanel>
            <TabPanel>
              <RuleBuilder />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      <Modal isOpen={isOpen} onClose={onToggle} size="full" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box className="p-8">
              <TemplatePreview />
            </Box>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </DesignerContextProvider>
  );
}
