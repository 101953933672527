import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/carousel.jsx';
import { PageLayout } from '@/components/page-layout.tsx';
import { useAuth } from '@/components/providers/auth.tsx';
import { AspectRatio, Badge, Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import type { FormField } from '@fieldbrick/core/db/schema/forms.js';
import type { InspectionResponse } from '@fieldbrick/core/db/schema/inspections.js';
import { Option } from 'effect';
import { compact, keyBy } from 'lodash-es';
import { ArrowLeftIcon } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import { P, match } from 'ts-pattern';
import { traverse } from '../lib/traversal.js';
import { useSummary } from './hooks/use-summary.js';
type FormFieldWithChildren = FormField & {
  children: FormFieldWithChildren[];
};

export function renderResponseForField(
  field: FormFieldWithChildren,
  responsesByFieldId: Record<string, InspectionResponse>,
  fieldsById: Record<string, FormField>,
) {
  return Option.match(Option.fromNullable(responsesByFieldId[field.id]), {
    onNone: () => null,
    onSome: (response) => {
      return match(response)
        .with({ response: { type: P.union('text') } }, ({ response }) => {
          return (
            <div className="flex flex-col gap-2 col-span-6" key={field.id}>
              <Text className="!font-semibold !text-xl !tracking-tighter">{field.title}</Text>
              <p className="tracking-tight">{response.value}</p>
            </div>
          );
        })
        .with({ response: { type: P.union('select', 'radio') } }, ({ response }) => {
          const responseValue = response.value;
          if (!responseValue) {
            return null;
          }

          const childrenResponses =
            field.children.length > 0
              ? compact(field.children.map((child) => renderResponseForField(child, responsesByFieldId, fieldsById)))
              : [];

          const childResponses =
            childrenResponses.length > 0 ? (
              <Box ml="2" gap="4" className="col-span-12 grid grid-cols-12">
                {childrenResponses}
              </Box>
            ) : (
              []
            );

          return (
            <div className="flex flex-col my-1.5	col-span-12" key={field.id}>
              <Box className="flex justify-between items-center bg-black text-white pt-4 pb-4 pl-6 pr-6 rounded-md">
                <Text className="font-semibold text-xl"> {field.title} </Text>
                <Box className="rounded-md pt-2 pb-2 pl-4 pr-4 font-semibold text-white" bg="blue.500">
                  <Text className="text-lg">{responseValue}</Text>
                </Box>
              </Box>
              <Text className="font-semibold ">
                {field.description ? (
                  <span className="text-muted-foreground text-sm lowercase">({field.description})</span>
                ) : null}
              </Text>

              {childResponses}
            </div>
          );
        })
        .with({ response: { type: 'radio' } }, ({ response }) => {
          const responseValue = response.value;

          return (
            <div className="flex flex-col gap-2 col-span-12" key={field.id}>
              <Text className="tracking-tighter font-normal">
                {field.title}{' '}
                {field.description ? (
                  <span className="text-muted-foreground text-sm lowercase">({field.description})</span>
                ) : null}
              </Text>
              <Box>
                <Badge color="blue" size="3">
                  {responseValue}
                </Badge>
              </Box>
            </div>
          );
        })
        .with({ response: { type: 'multiple_choice' } }, ({ response }) => {
          const responseValue = response.value;
          return (
            <div className="flex flex-col gap-4 col-span-12" key={field.id}>
              <Box className="flex justify-between items-center bg-black text-white pt-4 pb-4 pl-6 pr-6 rounded-md">
                <Text className="font-semibold text-xl"> {field.title} </Text>
              </Box>
              <Box className="rounded-md pt-2 pb-2 pl-4 pr-4 font-semibold text-white" bg="blue.500">
                <Text className="text-lg">{responseValue.join(', ')}</Text>
              </Box>
            </div>
          );
        })
        .with({ response: { type: 'file_upload' } }, ({ response }) => {
          return (
            <Flex direction="column" gap="4" key={field.id} className="col-span-12">
              <Text className="font-semibold">
                {field.title}{' '}
                {field.description ? (
                  <span className="text-muted-foreground text-sm lowercase">({field.description})</span>
                ) : null}
              </Text>
              <Box className="grid grid-cols-12 gap-4 relative">
                <Carousel className="w-full col-span-12">
                  <CarouselContent>
                    {response.value.map((attachment) => {
                      const src = 'encoded' in attachment ? attachment.encoded : attachment.url;
                      return (
                        <CarouselItem key={attachment.id}>
                          <AspectRatio ratio={1} className="w-full col-span-12 md:col-span-6">
                            <Image className="object-scale-down rounded-md" src={src} />
                          </AspectRatio>
                        </CarouselItem>
                      );
                    })}
                  </CarouselContent>
                  <CarouselPrevious />
                  <CarouselNext />
                </Carousel>
              </Box>
            </Flex>
          );
        })
        .exhaustive();
    },
  });
}

export function renderResponse(
  field: FormFieldWithChildren,
  depth: number,
  responsesByFieldId: Record<string, InspectionResponse>,
  fieldsById: Record<string, FormField>,
) {
  return match(field)
    .with({ type: 'row' }, (f) => {
      return (
        <Box className="grid grid-cols-12 gap-8 col-span-12" key={f.id}>
          {f.children.map((child) => renderResponse(child, depth + 2, responsesByFieldId, fieldsById))}
        </Box>
      );
    })
    .with({ type: 'group' }, (f) => {
      return (
        <Box key={field.id} className="col-span-12 gap-4 flex flex-col">
          <Box className="flex flex-col gap-2">
            {f.title ? (
              <Heading style={{ fontSize: 28 - depth * 2 }} className="w-full">
                {f.title}
              </Heading>
            ) : null}
            <Box className="col-span-12 grid grid-cols-12 gap-4 border rounded-md p-4">
              {f.children.map((child) => renderResponse(child, depth + 2, responsesByFieldId, fieldsById))}
            </Box>
          </Box>
        </Box>
      );
    })
    .with(
      {
        type: P.union('short_text', 'long_text', 'measurement', 'select', 'multiple_choice', 'radio', 'file_upload'),
      },
      (f) => renderResponseForField(f, responsesByFieldId, fieldsById),
    )
    .otherwise(() => null);
}

export function InspectionSummaryPage() {
  const { projectId, inspectionId } = useParams();
  const record = useSummary({ projectId, inspectionId });
  const { organizationId } = useAuth();
  if (!record) {
    return null;
  }

  const { form, responses } = record;

  const fieldsById = keyBy(form.fields, 'id');
  const responsesByFieldId = keyBy(responses, 'fieldId');
  const fieldTree = traverse(form.fields);
  const coverPhoto = record.project.photos.at(0);

  return (
    <PageLayout>
      <PageLayout.Header className="flex-col justify-start items-start gap-3">
        <Button leftIcon={<ArrowLeftIcon />} as={Link} to={`/org/${organizationId}/projects/${projectId}`}>
          Back to project
        </Button>
        <Box className="flex flex-col gap-4 w-full">
          <PageLayout.Title>Inspection Summary</PageLayout.Title>
          {coverPhoto ? (
            <AspectRatio ratio={16 / 9} className="w-full">
              <Image className="cursor-pointer object-cover rounded-md" src={coverPhoto.url} />
            </AspectRatio>
          ) : null}
        </Box>
      </PageLayout.Header>

      <PageLayout.Content>
        <section className="flex flex-col gap-4" id="field-tree">
          {fieldTree.map((field) => (
            <Box className="grid grid-cols-12 gap-4" key={field.id}>
              {renderResponse(field, 0, responsesByFieldId, fieldsById)}
            </Box>
          ))}
        </section>
      </PageLayout.Content>
    </PageLayout>
  );
}
