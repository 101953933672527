import { InspectionResponseStore } from '@/data/inspection-response';
import type { ExtractMutationFunctions } from '@fieldbrick/core/sync/framework';
import type { ReplicacheServerType } from '@fieldbrick/core/sync/replicache';
import { map } from 'lodash-es';

type Update = ExtractMutationFunctions<ReplicacheServerType>['updateInspectionResponses'];

export const updateInspectionResponses: Update = async (tx, input) => {
  const currentResponses = await InspectionResponseStore.all(tx, { inspectionId: input.inspectionId });
  const currentResponseIds = new Set(map(currentResponses, 'id'));
  const incomingResponseIds = new Set(map(input.responses, 'id'));
  const responseIdsToDelete = currentResponseIds.difference(incomingResponseIds);
  const responsesToDelete = currentResponses.filter((response) => responseIdsToDelete.has(response.id));

  await Promise.all(responsesToDelete.map((response) => InspectionResponseStore.delete(tx, [response])));
  await Promise.all(
    input.responses.map((response) =>
      InspectionResponseStore.put(tx, [{ inspectionId: input.inspectionId, id: response.id }], response),
    ),
  );
};
