import { useAuth } from '@/components/providers/auth.tsx';
import { useReplicache } from '@/components/providers/replicache';
import { Box, FormControl, FormErrorMessage, FormLabel, Input, Select, Stack } from '@chakra-ui/react';
import { id } from '@fieldbrick/core/util/id.js';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useIMask } from 'react-imask';
import { z } from 'zod';
import { states } from '../lib/states.js';
import { useNewProjectContext } from './new-project-context.tsx';

const FormValues = z.object({
  primaryStreet: z.string(),
  secondaryStreet: z.string(),
  city: z.string(),
  state: z.string().length(2),
  zipCode: z.string().length(5),
});
type FormValues = z.infer<typeof FormValues>;
type Props = {
  customerId: string;
};

export function NewAddressForm({ customerId }: Props) {
  const replicache = useReplicache();
  const { setState } = useNewProjectContext();

  const form = useForm({
    resolver: zodResolver(FormValues),
    defaultValues: {
      primaryStreet: '',
      secondaryStreet: '',
      city: '',
      state: 'AL',
      zipCode: '',
    },
  });

  const { ref } = useIMask(
    { mask: '00000', showMaskOnHover: true },
    {
      onAccept(value) {
        form.setValue('zipCode', value, { shouldValidate: true });
      },
    },
  );

  const { organizationId } = useAuth();

  const handleSubmit: SubmitHandler<FormValues> = async (values) => {
    await replicache.mutate.upsertCustomerAddress({
      ...values,
      id: id('addr'),
      organizationId,
      customerId,
    });
    setState('startProject');
  };

  return (
    <FormProvider {...form}>
      <form id="new-customer-address-form" onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spacing={4} direction="column">
          <Controller
            control={form.control}
            name="primaryStreet"
            render={({ field, fieldState: { error } }) => {
              const hasError = !!error?.message;
              return (
                <FormControl isRequired={true} isInvalid={hasError}>
                  <FormLabel htmlFor={field.name}>Street address</FormLabel>
                  <Input {...field} id={field.name} />
                  <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                </FormControl>
              );
            }}
          />
          <Controller
            control={form.control}
            name="secondaryStreet"
            render={({ field, fieldState: { error } }) => {
              const hasError = !!error?.message;

              return (
                <FormControl isInvalid={hasError}>
                  <FormLabel htmlFor={field.name}>Apt, suite, or unit</FormLabel>
                  <Input {...field} id={field.name} />
                  <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="city"
            render={({ field, fieldState: { error } }) => {
              const hasError = !!error?.message;
              return (
                <FormControl isInvalid={hasError} isRequired={true}>
                  <FormLabel htmlFor={field.name}>City</FormLabel>
                  <Input {...field} id={field.name} />
                  <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                </FormControl>
              );
            }}
          />

          <Stack direction="row" spacing={4}>
            <Controller
              control={form.control}
              name="state"
              render={({ field, fieldState: { error } }) => {
                const hasError = !!error?.message;
                return (
                  <FormControl isRequired={true} isInvalid={hasError}>
                    <FormLabel htmlFor={field.name}>State</FormLabel>
                    <Select {...field}>
                      {states.map((state) => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
            />

            <Controller
              control={form.control}
              name="zipCode"
              render={({ field, fieldState: { error } }) => {
                const hasError = !!error?.message;
                return (
                  <FormControl isRequired={true} isInvalid={hasError}>
                    <FormLabel htmlFor={field.name}>Zip code</FormLabel>
                    <Input ref={ref as React.RefObject<HTMLInputElement>} id={field.name} type="tel" />
                  </FormControl>
                );
              }}
            />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}
