import { useReplicache } from '@/components/providers/replicache';
import { CustomerAddressStore } from '@/data/customer-addresses';
import { CustomerStore } from '@/data/customers';
import { FormFieldStore, FormRuleStore, TemplateStore } from '@/data/forms';
import type { Customer, CustomerAddress } from '@fieldbrick/core/db/schema/customers';
import { groupBy } from 'lodash-es';
import { useSubscribe } from 'replicache-react';
import type { Template } from '../../new/domain/builder.js';

export function useFetchNewProjectData(): {
  templates: Template[];
  customers: Customer[];
  addressesByCustomer: Record<string, CustomerAddress[]>;
} {
  const replicache = useReplicache();

  const templates = useSubscribe(
    replicache,
    async (tx) => {
      const templates = await TemplateStore.all(tx);
      const formFields = (
        await Promise.all(templates.map(async (template) => FormFieldStore.all(tx, { formId: template.id })))
      ).flat();
      const formRules = (
        await Promise.all(templates.map(async (template) => FormRuleStore.all(tx, { formId: template.id })))
      ).flat();
      const groupedFields = groupBy(formFields, 'formId');
      const groupedRules = groupBy(formRules, 'formId');
      return templates.map((template) => {
        const fields = groupedFields[template.id] ?? [];
        const rules = groupedRules[template.id] ?? [];
        return { ...template, fields, rules };
      });
    },
    {
      default: [],
    },
  );

  const { customers, addressesByCustomer } = useSubscribe(
    replicache,
    async (tx) => {
      const customers = await CustomerStore.all(tx);
      const customerAddresses = await Promise.all(
        customers.map((customer) => CustomerAddressStore.all(tx, { customerId: customer.id })),
      );

      return {
        customers,
        addressesByCustomer: groupBy(customerAddresses.flat(), 'customerId'),
      };
    },
    {
      default: { customers: [], addressesByCustomer: {} },
    },
  );

  return { customers, addressesByCustomer, templates };
}
