import { trpc } from '@/lib/trpc';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from '@chakra-ui/react';
import { Roles } from '@fieldbrick/core/constants';
import { type InviteMemberInput, inviteMemberInput } from '@fieldbrick/core/domain/admin/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  organizationId: string;
  organizationHasOwnerRole: boolean;
};

export function InviteMember({ isOpen, onClose, organizationHasOwnerRole, organizationId }: Props) {
  const form = useForm<InviteMemberInput>({
    resolver: zodResolver(inviteMemberInput),
    defaultValues: { emailAddress: '', role: Roles.OWNER, organizationId },
  });

  const toast = useToast();
  const utils = trpc.useUtils();

  const { mutate, isPending } = trpc.admin.organizations.inviteMember.useMutation({
    async onSuccess() {
      await utils.admin.organizations.invitations.all.refetch();
      toast({ title: 'Invitation sent', status: 'success' });
      onClose();
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite New Member</ModalHeader>
        <ModalBody>
          <FormProvider {...form}>
            <form
              onSubmit={form.handleSubmit((values) => mutate(values))}
              className="flex flex-col gap-4"
              id="invite-member-form"
            >
              <FormControl>
                <FormLabel>Email address</FormLabel>
                <Controller name="emailAddress" control={form.control} render={({ field }) => <Input {...field} />} />
              </FormControl>
              <FormControl>
                <FormLabel>Role</FormLabel>
                <Controller
                  name="role"
                  control={form.control}
                  render={({ field }) => (
                    <Select {...field}>
                      <option value={Roles.OWNER} disabled={organizationHasOwnerRole}>
                        Owner
                      </option>
                      <option value={Roles.TECH}>Tech</option>
                      <option value={Roles.OFFICE}>Office</option>
                    </Select>
                  )}
                />
              </FormControl>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="lg">
            <Button onClick={onClose} variant="ghost">
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit" form="invite-member-form" isLoading={isPending}>
              Invite
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
