import { FormControl, FormErrorMessage, FormHelperText, FormLabel, GridItem, Textarea } from '@chakra-ui/react';
import { capitalize, compact } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import type { InspectionField } from '../../lib/schema.js';

export const TextAreaField = ({ block }: InspectionField) => {
  const { control } = useFormContext<Record<string, { type: 'text'; value?: string }>>();
  const { placeholder, design } = block.properties;
  const { description } = block;
  const layout = design?.layout;
  const fieldName = `${block.id}.value` as const;

  return (
    <GridItem colSpan={compact([12, layout?.columns])} key={block.id}>
      <Controller
        name={`${block.id}.type` as const}
        control={control}
        render={({ field: { value: _, ...field } }) => <input type="hidden" {...field} value="text" />}
      />

      <Controller
        name={fieldName}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const hasError = !!error?.message;
          return (
            <FormControl isInvalid={hasError} isRequired={true}>
              <FormLabel htmlFor={fieldName} className="!font-semibold tracking-tight !text-lg">
                {capitalize(block.title)}
              </FormLabel>
              <Textarea {...field} value={field.value ?? ''} id={fieldName} placeholder={placeholder} />
              {description ? <FormHelperText>{capitalize(description)}</FormHelperText> : null}
              {hasError ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
            </FormControl>
          );
        }}
      />
    </GridItem>
  );
};
