import type { FormField } from '@fieldbrick/core/db/schema/forms';
import { Array as RA, pipe } from 'effect';
import { noop } from 'lodash-es';
import { P, match } from 'ts-pattern';
import { z } from 'zod';

type FormFieldValidation = FormField['validation'];

function buildStringValidator<T extends Extract<FormFieldValidation, { type: 'long_text' | 'short_text' }>>(
  elementValidation: T,
) {
  return pipe(
    [
      { condition: elementValidation.minLength, method: 'min' },
      { condition: elementValidation.maxLength, method: 'max' },
    ] as const,
    RA.reduce(z.string(), (validator, { condition, method }) => (condition ? validator[method](condition) : validator)),
  );
}

function buildNumberValidator<T extends Extract<FormFieldValidation, { type: 'number' }>>(elementValidation: T) {
  const validator = pipe(
    [
      { condition: elementValidation.min, method: 'min' },
      { condition: elementValidation.max, method: 'max' },
    ] as const,
    RA.reduce(z.number({ coerce: true }).positive().safe().int(), (validator, { condition, method }) =>
      condition ? validator[method](condition) : validator,
    ),
  );

  return validator;
}

const messages = {
  required: 'This field is required.',
};

export function buildValidationSchema<T extends FormField[]>(fields: T) {
  const schema = z.object(
    fields
      // .filter((f) => f.type !== 'group' && f.type !== 'choice' && f.visibility !== 'hidden')
      .reduce(
        (acc, field) => {
          match(field)
            .with({ validation: { type: 'file_upload' } }, (f) => {
              acc[f.id] = z
                .object({
                  type: z.literal('file_upload'),
                  value: z
                    .union([
                      z.object({
                        id: z.string(),
                        url: z.string().url(),
                        contentType: z.string(),
                        fileName: z.string(),
                        size: z.number(),
                      }),
                      z.object({
                        id: z.string(),
                        encoded: z.string().url(),
                        fileName: z.string(),
                        size: z.number(),
                        contentType: z.string(),
                      }),
                    ])
                    .array()
                    .nonempty('Please select at least one file'),
                })
                .optional();
            })
            .with({ validation: { type: P.union('short_text', 'long_text') } }, (f) => {
              acc[f.id] = z
                .object({
                  type: z.string(),
                  value: buildStringValidator(f.validation),
                })
                .optional();
            })
            .with({ validation: { type: 'multiple_choice' } }, (f) => {
              acc[f.id] = z
                .object({
                  type: z.string(),
                  value: z.string().array().min(1, messages.required),
                })
                .optional();
            })
            .with({ validation: { type: 'select' } }, (f) => {
              acc[f.id] = z
                .object({
                  type: z.string(),
                  value: z.string().min(1, messages.required),
                })
                .optional();
            })
            .with({ validation: { type: 'number' } }, (f) => {
              if (f.validation.type !== 'number') {
                acc[f.id] = z
                  .object({
                    type: z.string(),
                    value: z.union([z.string(), z.number()]),
                  })
                  .optional();
              } else {
                acc[f.id] = z
                  .object({
                    type: z.string(),
                    value: buildNumberValidator(f.validation),
                  })
                  .optional();
              }
            })
            .with({ validation: { type: 'radio' } }, (f) => {
              acc[f.id] = z
                .object({
                  type: z.string(),
                  value: z.string(),
                })
                .optional();
            })
            .with({ validation: { type: P.union('group', 'choice', 'file_upload') } }, noop)
            .otherwise((f) => {
              acc[f.id] = z.object({ type: z.string(), value: z.string().min(1, messages.required) }).optional();
            });

          return acc;
        },
        {} as Record<string, z.ZodType<unknown>>,
      ),
  );

  return schema;
}
