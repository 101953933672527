import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowLeftIcon } from 'lucide-react';
import { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { match } from 'ts-pattern';
import { useFetchNewProjectData } from '../../index/hooks/data.js';
import { type NewProjectFormValues, newProjectSchema } from '../schema.js';
import { NewAddressForm } from './new-address-form.tsx';
import { NewCustomerForm } from './new-customer-form.tsx';
import { NewProjectContext, type NewProjectState } from './new-project-context.tsx';
import { NewProjectForm } from './new-project-form.tsx';

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export function NewProjectModal({ isOpen, onClose }: Props) {
  const [state, setState] = useState<NewProjectState>('startProject');

  const form = useForm<NewProjectFormValues>({
    resolver: zodResolver(newProjectSchema),
    defaultValues: { templateIds: [] },
  });

  const customerId = useWatch({ name: 'customer.id', control: form.control });
  const { templates, customers, addressesByCustomer } = useFetchNewProjectData();

  const addresses = addressesByCustomer[customerId] ?? [];

  const view = match(state)
    .with('startProject', () => <NewProjectForm {...{ addresses, templates, customerId, customers }} />)
    .with('addCustomerAddress', () => <NewAddressForm customerId={customerId} />)
    .with('addCustomer', () => <NewCustomerForm />)
    .exhaustive();

  const title = match(state)
    .with('startProject', () => 'Start new project')
    .with('addCustomerAddress', () => 'Add customer address')
    .with('addCustomer', () => 'Add customer')
    .exhaustive();

  const buttons = match(state)
    .with('startProject', () => (
      <>
        <Button variant="ghost" onClick={onClose}>
          Cancel
        </Button>
        <Button colorScheme="blue" type="submit" form="new-project-form">
          Start project
        </Button>
      </>
    ))
    .with('addCustomer', () => (
      <>
        <Button variant="ghost" onClick={() => setState('startProject')} leftIcon={<ArrowLeftIcon />}>
          Go back
        </Button>
        <Button colorScheme="blue" type="submit" form="new-customer-form">
          Save customer
        </Button>
      </>
    ))
    .with('addCustomerAddress', () => (
      <>
        <Button variant="ghost" onClick={() => setState('startProject')} leftIcon={<ArrowLeftIcon />}>
          Go back
        </Button>
        <Button colorScheme="blue" type="submit" form="new-customer-address-form">
          Save address
        </Button>
      </>
    ))
    .exhaustive();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setState('startProject');
      }}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...form}>
            <NewProjectContext value={{ state, setState }}>{view}</NewProjectContext>
          </FormProvider>
        </ModalBody>

        <ModalFooter gap="2">{buttons}</ModalFooter>
      </ModalContent>
    </Modal>
  );
}
