export const SESSION_COOKIE_NAME = 'fieldbrick.session';
export const ADMIN_SESSION_COOKIE_NAME = 'fieldbrick.admin_session';

export const Roles = {
  OWNER: 'owner',
  TECH: 'tech',
  OFFICE: 'office',
} as const;

export type Role = (typeof Roles)[keyof typeof Roles];

export const Units = ['ft', 'in', 'cm', 'btu'] as const;
