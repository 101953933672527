import { PageLayout } from '@/components/page-layout';
import { trpc } from '@/lib/trpc.ts';
import {
  Box,
  Button,
  Card,
  CardBody,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { startCase } from 'lodash-es';
import { Share } from 'lucide-react';
import { createContext, use } from 'react';
import { useParams } from 'react-router-dom';
import { InspectionsList } from './components/inspections-list.tsx';
import { useProject } from './hooks/use-project.js';

type ProjectPageContext = {
  project: NonNullable<ReturnType<typeof useProject>>;
};

const ProjectPageContext = createContext<ProjectPageContext>({} as ProjectPageContext);

function ProjectPageImpl() {
  const { project } = use(ProjectPageContext);
  const { customer, customerAddress } = project;
  const { isOpen, onToggle } = useDisclosure();
  const allInspectionsCompleted = project.inspections.every((inspection) => inspection.status === 'completed');
  const toast = useToast();
  const { mutate: sendInspectionEmail, isPending } = trpc.projects.report.share.useMutation({
    onSuccess() {
      toast({ title: 'Inspection report sent', status: 'success', duration: 3000 });
      onToggle();
    },
    onError(error) {
      toast({ title: 'Could not send inspection report.', status: 'error', description: error.message });
    },
  });

  return (
    <PageLayout>
      <PageLayout.Header>
        <Box className="flex items-center justify-between w-full">
          <PageLayout.Title>Project</PageLayout.Title>
          {allInspectionsCompleted ? (
            <IconButton icon={<Share className="size-4" />} aria-label="Share inspection" onClick={onToggle} />
          ) : null}
        </Box>
      </PageLayout.Header>

      <PageLayout.Content>
        <Box className="flex flex-col gap-4">
          <Box>
            <Text fontWeight="semibold">Details</Text>
          </Box>
          <Box className="flex flex-col sm:flex-row gap-4">
            <Card className="flex-1" variant="outline">
              <CardBody>
                <Text>Customer</Text>
                <Text fontWeight="bold" fontSize="x-large">
                  {startCase([customer.firstName, customer.lastName].join(' '))}
                </Text>
              </CardBody>
            </Card>
            <Card className="flex-1" variant="outline">
              <CardBody>
                <Text>Address</Text>
                <Text fontWeight="bold" fontSize="x-large" className="capitalize">
                  {customerAddress.primaryStreet} {customerAddress.secondaryStreet}
                </Text>
                <Text color="grayText" fontSize="large" className="capitalize">
                  {customerAddress.city}, <span className="uppercase">{customerAddress.state}</span>{' '}
                  {customerAddress.zipCode}
                </Text>
              </CardBody>
            </Card>
          </Box>

          <Box className="flex gap-4 flex-col">
            <Text fontWeight="semibold">Inspections</Text>
            <Box className="grid grid-cols-12 gap-4">
              <InspectionsList projectId={project.id} />
            </Box>
          </Box>
        </Box>
      </PageLayout.Content>

      <Modal isOpen={isOpen} onClose={onToggle} isCentered={true}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Share Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack direction="column" gap="4">
              <Text>Share the report with your customer via email.</Text>
              <Stack>
                {customer.emailAddress ? (
                  <Stack direction="column" gap="4">
                    <Input value={customer.emailAddress} readOnly={true} />
                    <Button
                      colorScheme="blue"
                      isLoading={isPending}
                      type="submit"
                      onClick={() => sendInspectionEmail({ projectId: project.id })}
                    >
                      Send report
                    </Button>
                  </Stack>
                ) : (
                  <Stack direction="column" gap="4">
                    <Text>Please add an email address to the customer profile.</Text>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </PageLayout>
  );
}

export function ProjectPage() {
  const { projectId } = useParams();
  const project = useProject({ projectId: projectId! });

  if (!project) {
    return null;
  }

  return (
    <ProjectPageContext value={{ project }}>
      <ProjectPageImpl />
    </ProjectPageContext>
  );
}
