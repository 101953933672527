import { PageLayout } from '@/components/page-layout';
import { trpc } from '@/lib/trpc';
import { Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { PlusIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

export function AdminOrganizationsPage() {
  const [organizations] = trpc.admin.organizations.all.useSuspenseQuery();

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Title>Organizations</PageLayout.Title>
        <Button leftIcon={<PlusIcon />} as={Link} to="/admin/organizations/new">
          New Organization
        </Button>
      </PageLayout.Header>

      <PageLayout.Content>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Organization Name</Th>
                <Th>Created At</Th>
              </Tr>
            </Thead>
            <Tbody>
              {organizations.map((organization) => {
                return (
                  <Tr key={organization.id}>
                    <Td>
                      <Link to={`/admin/organizations/${organization.id}`}>{organization.name}</Link>
                    </Td>
                    <Td>{DateTime.fromSQL(organization.createdAt).toLocaleString(DateTime.DATE_FULL)}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </PageLayout.Content>
    </PageLayout>
  );
}
