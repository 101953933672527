import { cn } from '@/lib/cn';
import { Box, Button, Card, CardBody, Divider, Flex, GridItem, IconButton, SimpleGrid } from '@chakra-ui/react';
import { DndContext, type DragEndEvent, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { id } from '@fieldbrick/core/util/id';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { CopyIcon, TrashIcon, XIcon } from 'lucide-react';
import { useDesigner } from './context';
import { type FormElement, type FormElementInstance, FormElements } from './elements';

function SortableItem({ id, children }: { id: string; children: React.ReactNode }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
}

function SidebarBtnElement({ formElement }: { formElement: FormElement }) {
  const { addElement, elements } = useDesigner();
  const { label, icon: Icon } = formElement.designerBtnElement;

  return (
    <Button
      variant="outline"
      className="flex flex-col gap-2 !h-[120px] !w-full"
      onClick={() => {
        const newElement = formElement.construct(id('field'));

        addElement(elements.length, newElement);
      }}
    >
      <Icon className="h-8 w-8 text-primary" />
      <p className="text-xs">{label}</p>
    </Button>
  );
}

function PropertiesFormSidebar() {
  const { selectedElement, setSelectedElement } = useDesigner();
  if (!selectedElement) {
    return null;
  }

  const PropertiesForm = FormElements[selectedElement.type].propertiesComponent;

  return (
    <div className="flex flex-col gap-2 p-2 sticky top-0">
      <div className="flex justify-between items-center">
        <h3 className="!font-semibold tracking-tight !text-lg">{selectedElement.type} properties</h3>
        <Button
          variant="ghost"
          onClick={() => {
            setSelectedElement(null);
          }}
        >
          <XIcon className="h-4 w-4" />
        </Button>
      </div>
      <Divider className="mb-4" />
      <PropertiesForm elementInstance={selectedElement} />
    </div>
  );
}

function buildTreeFromElements(elements: FormElementInstance[]) {
  const elementsMap = new Map<string, FormElementInstance>(elements.map((el) => [el.id, { ...el, children: [] }]));

  return elements.reduce((tree, element) => {
    const parent = elementsMap.get(element.extraAttributes.parentId);
    const element_ = elementsMap.get(element.id);

    if (parent && element_) {
      parent.children.push(element_);
      return tree;
    }
    if (element_) {
      return tree.concat(element_);
    }

    return tree;
  }, [] as FormElementInstance[]);
}

export function Designer() {
  const { elements, setSelectedElement, selectedElement, setElements, removeElement } = useDesigner();

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setElements((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const movedItems = arrayMove(items, oldIndex, newIndex);

        return movedItems.map((item, index) => ({ ...item, order: index }));
      });
    }
  }

  const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 10 } });
  const sensors = useSensors(mouseSensor);

  const elementTree = buildTreeFromElements(elements);

  function TreeItem({ element }: { element: FormElementInstance }) {
    const { selectedElement, setElements } = useDesigner();

    const DesignerElement = FormElements[element.type].designerComponent;
    return (
      <Box key={element.id} className="flex flex-col gap-4">
        <SortableItem id={element.id}>
          <Card
            className={cn('!border !border-slate-600 cursor-pointer', {
              '!border-2 !border-blue-500': selectedElement?.id === element.id,
            })}
            onClick={() => setSelectedElement(element)}
          >
            <CardBody className="flex flex-col gap-4">
              <DesignerElement elementInstance={element} />
              <Box className="flex gap-2 items-center justify-between">
                <Box>
                  <IconButton
                    variant="ghost"
                    icon={<CopyIcon />}
                    aria-label="duplicate"
                    onClick={(event) => {
                      event.stopPropagation();
                      const newElement = structuredClone(element);
                      newElement.id = id('field');
                      newElement.extraAttributes.id = newElement.id;
                      newElement.extraAttributes.title = `Copy of ${element.extraAttributes.title}`;
                      setElements((prev) => [...prev, newElement]);
                    }}
                  />
                </Box>

                <IconButton
                  size="sm"
                  variant="ghost"
                  colorScheme="red"
                  icon={<TrashIcon />}
                  aria-label="Remove"
                  onClick={(event) => {
                    event.stopPropagation();
                    return removeElement(element.id);
                  }}
                />
              </Box>
            </CardBody>
          </Card>
        </SortableItem>

        {element.children.length > 0 && (
          <Box className="flex flex-col gap-4 ml-4">
            {element.children.map((child) => (
              <TreeItem key={child.id} element={child} />
            ))}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Flex direction="column" gap="4">
      <SimpleGrid columns={12} spacing={4} className="h-full flex-1 flex flex-col">
        <GridItem colSpan={8} className="flex flex-col gap-4">
          <ScrollArea.Root className="overflow-hidden h-full" type="auto">
            <ScrollArea.Viewport className="h-full w-full pr-4">
              <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
                <Box className="flex flex-col gap-4">
                  <SortableContext items={elements} strategy={verticalListSortingStrategy}>
                    {elementTree.map((element) => {
                      return <TreeItem key={element.id} element={element} />;
                    })}
                  </SortableContext>
                </Box>
              </DndContext>
            </ScrollArea.Viewport>

            <ScrollArea.Scrollbar
              orientation="vertical"
              className="flex w-2 touch-none select-none rounded-md bg-zinc-200"
            >
              <ScrollArea.Thumb className="relative flex-1 rounded-md bg-zinc-400" />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
        </GridItem>

        <GridItem colSpan={4} className="rounded-md border p-4 h-full">
          {selectedElement ? (
            <PropertiesFormSidebar />
          ) : (
            <div className="w-full sticky top-0">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <SidebarBtnElement formElement={FormElements.ShortTextField} />
                <SidebarBtnElement formElement={FormElements.GroupField} />
                <SidebarBtnElement formElement={FormElements.MultipleChoiceField} />
                <SidebarBtnElement formElement={FormElements.RadioField} />
                <SidebarBtnElement formElement={FormElements.FileUploadField} />
                <SidebarBtnElement formElement={FormElements.MeasurementField} />
                <SidebarBtnElement formElement={FormElements.RowField} />
              </div>
            </div>
          )}
        </GridItem>
      </SimpleGrid>
    </Flex>
  );
}
