import { useAuth } from '@/components/providers/auth.tsx';
import { useReplicache } from '@/components/providers/replicache';
import { Box, FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react';
import { id } from '@fieldbrick/core/util/id.js';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, type SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { useIMask } from 'react-imask';
import { z } from 'zod';
import { useNewProjectContext } from './new-project-context.tsx';

const FormValues = z.object({
  firstName: z.string().trim().min(1, 'First name is required'),
  lastName: z.string().trim().min(1, 'Last name is required'),
  emailAddress: z.string().email().trim().max(100),
  phoneNumber: z
    .string()
    .trim()
    .regex(/^\d{3}-\d{3}-\d{4}$/, 'Please enter a valid phone number'),
});
type FormValues = z.infer<typeof FormValues>;

export function NewCustomerForm() {
  const replicache = useReplicache();
  const { setState } = useNewProjectContext();
  const { setValue } = useFormContext();

  const form = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      firstName: '',
      lastName: '',
      // TODO: this does not validate uniqueness of email address or at least phone number.
      emailAddress: '',
      phoneNumber: '',
    },
  });

  const { ref } = useIMask(
    { mask: '000-000-0000', placeholder: '555-555-5555', showMaskOnHover: true },
    {
      onAccept(value) {
        form.setValue('phoneNumber', value, { shouldValidate: true });
      },
    },
  );

  const { organizationId } = useAuth();

  const handleSubmit: SubmitHandler<FormValues> = async (values) => {
    const customer = { ...values, id: id('cust'), organizationId };
    await replicache.mutate.upsertCustomer(customer);
    setValue('customer', customer);
    setState('startProject');
  };

  return (
    <FormProvider {...form}>
      <form id="new-customer-form" onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack spacing={4} direction="column">
          <Controller
            control={form.control}
            name="firstName"
            render={({ field, fieldState: { error } }) => {
              const hasError = !!error?.message;
              return (
                <FormControl isRequired={true} isInvalid={hasError}>
                  <FormLabel htmlFor={field.name}>First name</FormLabel>
                  <Input {...field} id={field.name} />
                  <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                </FormControl>
              );
            }}
          />
          <Controller
            control={form.control}
            name="lastName"
            render={({ field, fieldState: { error } }) => {
              const hasError = !!error?.message;

              return (
                <FormControl isRequired={true} isInvalid={hasError}>
                  <FormLabel htmlFor={field.name}>Last name</FormLabel>
                  <Input {...field} id={field.name} />
                  <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="emailAddress"
            render={({ field, fieldState: { error } }) => {
              const hasError = !!error?.message;
              return (
                <FormControl isInvalid={hasError}>
                  <FormLabel htmlFor={field.name}>Email address</FormLabel>
                  <Input {...field} id={field.name} type="email" />
                  <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="phoneNumber"
            render={({ field, fieldState: { error } }) => {
              const hasError = !!error?.message;
              return (
                <FormControl isRequired={true} isInvalid={hasError}>
                  <FormLabel htmlFor={field.name}>Phone number</FormLabel>
                  <Input
                    ref={ref as React.RefObject<HTMLInputElement>}
                    id={field.name}
                    type="tel"
                    defaultValue={field.value}
                  />
                </FormControl>
              );
            }}
          />
        </Stack>
      </form>
    </FormProvider>
  );
}
