import { Box, Button, Card, CardBody, CardFooter, Flex } from '@chakra-ui/react';
import { id } from '@fieldbrick/core/util/id';
import { zodResolver } from '@hookform/resolvers/zod';
import { PlusIcon } from 'lucide-react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDesigner } from '../designer/context';
import { Actions } from './actions';
import { Rules } from './rules';
import { type RuleBuilderSchema, ruleBuilderSchema } from './schemas';

/**
 * TODO: when a form element is removed, we don't currently remove any corresponding rules.
 */

export function RuleBuilder() {
  const { rules: defaultRules } = useDesigner();
  const form = useForm<RuleBuilderSchema>({
    resolver: zodResolver(ruleBuilderSchema),
    defaultValues: {
      rules: defaultRules,
    },
  });

  const { append, remove, fields: rules } = useFieldArray({ control: form.control, name: 'rules' });
  const { updateRules, showSavedIndicator } = useDesigner();

  return (
    <Flex className="flex-col gap-4">
      <Flex className="items-center flex flex-col gap-4 w-full flex-1">
        <FormProvider {...form}>
          <form
            id="rule-builder-form"
            onSubmit={form.handleSubmit((values) => updateRules(values.rules))}
            className="flex flex-col gap-4 w-full"
          >
            {rules.map((field, index) => (
              <Card variant="outline" className="w-full" key={field.id}>
                <CardBody className="flex flex-col gap-4">
                  <Rules index={index} />
                  <Actions index={index} />
                  <CardFooter px="0" py="0">
                    <Button colorScheme="red" variant="outline" ml="auto" onClick={() => remove(index)}>
                      Delete
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            ))}
          </form>
        </FormProvider>
      </Flex>

      <Box className="flex gap-2 justify-between">
        <Button
          leftIcon={<PlusIcon />}
          onClick={() =>
            append({
              id: id('rule'),
              definition: {
                operator: 'and',
                rules: [
                  {
                    type: 'multiple_choice',
                    operator: 'is',
                    value: '',
                    reference: { id: '' },
                  },
                ],
              },
              actions: [{ type: 'show', reference: { id: '' } }],
              ownershipType: 'template',
            })
          }
        >
          Add rule
        </Button>

        <Button colorScheme="blue" type="submit" form="rule-builder-form" isLoading={showSavedIndicator}>
          Save
        </Button>
      </Box>
    </Flex>
  );
}
