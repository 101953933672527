import { z } from 'zod';

const radioType = z.literal('radio');
const numberFieldType = z.literal('number');
const groupFieldType = z.literal('group');
const longTextFieldType = z.literal('long_text');
export const shortTextFieldType = z.literal('short_text');
const fileUploadType = z.literal('file_upload');
const measurementType = z.literal('measurement');
const multipleChoiceType = z.literal('multiple_choice');
const choiceFieldType = z.literal('choice');
const selectFieldType = z.literal('select');
const rowFieldType = z.literal('row');

export const shortTextFieldValidation = z.object({
  type: shortTextFieldType,
  minLength: z.coerce.number().optional(),
  maxLength: z.coerce.number().optional(),
});

export const fieldValidation = z.discriminatedUnion('type', [
  z.object({ type: groupFieldType }),
  shortTextFieldValidation,
  z.object({ type: longTextFieldType, minLength: z.number().optional(), maxLength: z.number().optional() }),
  z.object({ type: numberFieldType, min: z.number().optional(), max: z.number().optional() }),
  z.object({ type: selectFieldType }),
  z.object({ type: radioType }),
  z.object({
    type: fileUploadType,
    maxAttachments: z.number().optional(),
  }),
  z.object({ type: multipleChoiceType }),
  z.object({ type: measurementType, min: z.number().positive() }),
  z.object({ type: choiceFieldType }),
  z.object({ type: rowFieldType }),
]);

const commonFieldProperties = z.object({
  description: z.string().optional(),
  placeholder: z.string().optional(),
  allowDuplication: z.boolean().optional(),
  meta: z.object({ isDuplicate: z.boolean() }).partial(),
  design: z.object({
    layout: z.object({ columns: z.coerce.number() }).partial(),
    report: z
      .object({
        backgroundColor: z.string().optional(),
        textColor: z.string().optional(),
      })
      .optional(),
  }),
});

export const shortTextFieldProperties = commonFieldProperties.extend({ type: shortTextFieldType });

export const measurementFieldProperties = commonFieldProperties.extend({
  type: measurementType,
  unit: z.union([z.literal('ft'), z.literal('in'), z.literal('cm'), z.literal('btu')]),
});
export type MeasurementFieldProperties = z.infer<typeof measurementFieldProperties>;
const multipleChoiceFieldProperties = commonFieldProperties.extend({
  type: multipleChoiceType,
  options: z.array(
    z.object({
      id: z.string(),
      title: z.string(),
    }),
  ),
});
export type MultipleChoiceFieldProperties = z.infer<typeof multipleChoiceFieldProperties>;
const selectFieldProperties = commonFieldProperties.extend({
  type: selectFieldType,
  options: z.array(
    z.object({
      id: z.string(),
      title: z.string(),
    }),
  ),
});
export type SelectFieldProperties = z.infer<typeof selectFieldProperties>;

const radioFieldProperties = commonFieldProperties.extend({
  type: radioType,
  options: z.array(
    z.object({
      id: z.string(),
      title: z.string(),
    }),
  ),
});
export type RadioFieldProperties = z.infer<typeof radioFieldProperties>;

export const fieldProperties = z.discriminatedUnion('type', [
  shortTextFieldProperties,
  commonFieldProperties.extend({ type: longTextFieldType }),
  commonFieldProperties.extend({ type: numberFieldType }),
  commonFieldProperties.extend({ type: choiceFieldType }),
  commonFieldProperties.extend({ type: groupFieldType }),
  selectFieldProperties,
  commonFieldProperties.extend({ type: fileUploadType }),
  measurementFieldProperties,
  multipleChoiceFieldProperties,
  radioFieldProperties,
  commonFieldProperties.extend({ type: rowFieldType }),
]);
