import { useReplicache } from '@/components/providers/replicache.tsx';
import { CustomerAddressStore } from '@/data/customer-addresses.js';
import { CustomerStore } from '@/data/customers.js';
import { InspectionStore } from '@/data/inspections';
import { ProjectStore } from '@/data/projects.js';
import { trpc } from '@/lib/trpc';
import { useNetworkState } from '@uidotdev/usehooks';
import { groupBy, keyBy, map, uniqBy } from 'lodash-es';
import { useSubscribe } from 'replicache-react';
import { UserStore } from '../../../../data/users';

export function useProjects() {
  const { online } = useNetworkState();
  const repliache = useReplicache();
  const { data: remoteProjects } = trpc.projects.all.useQuery({}, { enabled: online });

  const localProjects = useSubscribe(
    repliache,
    async (tx) => {
      const projects = await ProjectStore.all(tx);
      const users = await UserStore.all(tx);
      const inspections = await Promise.all(
        projects.map((project) => InspectionStore.all(tx, { projectId: project.id })),
      );
      const customers = await Promise.all(projects.map((project) => CustomerStore.get(tx, { id: project.customerId })));
      const customerAddresses = await Promise.all(
        projects.map((project) =>
          CustomerAddressStore.get(tx, { customerId: project.customerId, id: project.customerAddressId }),
        ),
      );
      const customersById = keyBy(customers, 'id');
      const customerAddressesById = keyBy(customerAddresses, 'id');
      const inspectionsByProjectId = groupBy(inspections.flat(), 'projectId');
      const usersById = keyBy(users, 'id');

      return projects.map((project) => {
        const customer = customersById[project.customerId]!;
        const customerAddress = customerAddressesById[project.customerAddressId]!;
        const createdBy = usersById[project.createdById]!;

        return {
          ...project,
          customer,
          customerAddress,
          inspections: inspectionsByProjectId[project.id] ?? [],
          createdBy,
        };
      });
    },
    {
      default: [],
    },
  );

  const projects = online ? uniqBy([...(remoteProjects ?? []), ...localProjects], 'id') : localProjects;
  const enrichedProjects = projects.map((project) => {
    const inspectionStatusesForProject = map(project.inspections, 'status');
    const status = inspectionStatusesForProject.every((status) => status === 'completed')
      ? ('completed' as const)
      : inspectionStatusesForProject.some((status) => status === 'in_progress')
        ? ('in_progress' as const)
        : ('not_started' as const);
    return { ...project, status };
  });

  return enrichedProjects.toSorted((a, b) => a.id.localeCompare(b.id));
}
