import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption } from '@headlessui/react';
import { Box, Input } from '@chakra-ui/react';
import type { Customer, Database } from '@fieldbrick/data/core/models';
import React from 'react';
import { startCase } from 'lodash-es';
import { cn } from '@/lib/cn';
import { useFloating, autoUpdate, offset } from '@floating-ui/react';
import * as ScrollArea from '@radix-ui/react-scroll-area';

type Props = {
  value: Customer | null;
  onChange: (value: Customer | null) => void;
  customers: readonly Database.Customer[];
};

export function CustomerSearch({ value, onChange, customers = [] }: Props) {
  const [inputValue, setInputValue] = React.useState<string>('');
  const filteredCustomers = customers
    .filter(
      (customer) =>
        customer.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
        customer.lastName.toLowerCase().includes(inputValue.toLowerCase()),
    )
    .slice(0, 10);

  const { refs, floatingStyles } = useFloating({
    open: filteredCustomers.length > 0,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [offset(10)],
  });

  return (
    <Combobox value={value} onChange={onChange}>
      <ComboboxInput
        as={Input}
        ref={refs.setReference}
        placeholder="Start typing to search..."
        displayValue={(customer: Database.Customer) =>
          customer ? `${startCase([customer.firstName, customer.lastName].join(' '))} (${customer.phoneNumber})` : ''
        }
        onChange={(event) => setInputValue(event.target.value)}
      />

      <>
        <ComboboxOptions>
          {filteredCustomers.length > 0 ? (
            <Box
              ref={refs.setFloating}
              style={floatingStyles}
              bg="white"
              width="100%"
              borderRadius="md"
              border="1px solid"
              borderColor="gray.200"
              zIndex={100}
              p="1"
            >
              <ScrollArea.Root className="overflow-hidden" type="auto">
                <ScrollArea.Viewport className="h-full max-h-52 w-full">
                  {filteredCustomers.map((customer) => (
                    <ComboboxOption key={customer.id} value={customer}>
                      {({ focus }) => (
                        <Box px="3" py="2" className={cn({ 'bg-gray-100': focus }, 'capitalize')}>
                          {customer.firstName} {customer.lastName}
                        </Box>
                      )}
                    </ComboboxOption>
                  ))}
                </ScrollArea.Viewport>

                <ScrollArea.Scrollbar
                  orientation="vertical"
                  className="flex w-2 touch-none select-none rounded-md bg-zinc-200"
                >
                  <ScrollArea.Thumb className="relative flex-1 rounded-md bg-zinc-400" />
                </ScrollArea.Scrollbar>
              </ScrollArea.Root>
            </Box>
          ) : null}
        </ComboboxOptions>
      </>
    </Combobox>
  );
}
