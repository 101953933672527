import { FormControl, FormErrorMessage, FormHelperText, FormLabel, GridItem, Select } from '@chakra-ui/react';
import type { SelectFieldProperties } from '@fieldbrick/core/db/schemas/fields.js';
import { compact } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import type { InspectionField } from '../../lib/schema.js';

export const SelectField = ({ block }: InspectionField) => {
  const { control } = useFormContext<Record<string, { type: 'text'; value?: string }>>();
  const { design, options } = block.properties as SelectFieldProperties;
  const layout = design?.layout;
  const { description } = block;
  const fieldName = `${block.id}.value` as const;
  const choices = options;

  return (
    <GridItem colSpan={compact([12, layout?.columns])} key={block.id}>
      <Controller
        name={`${block.id}.type` as const}
        control={control}
        render={({ field }) => <input type="hidden" name={field.name} value="select" />}
      />

      <Controller
        control={control}
        name={fieldName}
        render={({ field, fieldState: { error } }) => {
          const hasError = !!error?.message;
          return (
            <FormControl className="flex flex-col" isInvalid={hasError} isRequired={true}>
              <FormLabel className="!font-semibold tracking-tight !text-lg">{block.title}</FormLabel>

              <Select onChange={field.onChange} value={String(field.value)} size="lg">
                <option value="">Select an option</option>
                {choices.map((child) => (
                  <option key={child.id} value={child.id} className="text-sm font-semibold">
                    {child.title}
                  </option>
                ))}
              </Select>

              {description ? (
                <FormHelperText className="text-sm font-semibold text-gray-600">{description}</FormHelperText>
              ) : null}
              {hasError ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
            </FormControl>
          );
        }}
      />
    </GridItem>
  );
};
