import { trpc } from '@/lib/trpc';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
} from '@chakra-ui/react';
import { type AcceptInvitationInput, acceptInvitationInput } from '@fieldbrick/core/domain/invitations/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

export function AcceptInvitationPage() {
  const { invitationId, orgId } = useParams();
  const [invitation] = trpc.invitations.byId.useSuspenseQuery({ id: invitationId!, organizationId: orgId! });
  const toast = useToast();
  const navigate = useNavigate();
  const { mutate: acceptInvitation, isPending } = trpc.invitations.accept.useMutation({
    onSuccess() {
      toast({ title: 'Invitation accepted', status: 'success', duration: 3000 });
      navigate('/login', { replace: true });
    },
    onError(error) {
      toast({ title: 'Could not accept invitation.', status: 'error', description: error.message });
    },
  });
  const form = useForm<AcceptInvitationInput>({
    resolver: zodResolver(acceptInvitationInput),
    defaultValues: {
      invitationId: invitationId!,
      organizationId: orgId!,
      firstName: '',
      lastName: '',
    },
  });

  if (!invitation) {
    return (
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Invitation not found
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          Your invitation may have expired or been used. Please contact the person who invited you to get a new
          invitation.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <Box className="flex flex-col gap-4 my-auto text-center">
      <Heading className="text-lg tracking-tight">You have been invited to join {invitation.organization.name}</Heading>

      <Card className="!border-gray-900 !rounded-none" variant="outline">
        <CardBody>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit((values) => acceptInvitation(values))}>
              <Box className="flex flex-col gap-4">
                <Box className="flex gap-4">
                  <Controller
                    control={form.control}
                    name="firstName"
                    render={({ field }) => (
                      <FormControl isRequired={true}>
                        <FormLabel htmlFor={field.name}>First name</FormLabel>
                        <Input
                          id={field.name}
                          {...field}
                          size="lg"
                          className="!rounded-none !border-gray-900 border-2"
                        />
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={form.control}
                    name="lastName"
                    render={({ field }) => (
                      <FormControl isRequired={true}>
                        <FormLabel htmlFor={field.name}>Last name</FormLabel>
                        <Input
                          id={field.name}
                          {...field}
                          size="lg"
                          className="!rounded-none !border-gray-900 border-2"
                        />
                      </FormControl>
                    )}
                  />
                </Box>
                <Button
                  type="submit"
                  borderRadius="none"
                  size="lg"
                  className="!bg-[#8a05ff] !text-white"
                  isLoading={isPending}
                >
                  Accept invitation
                </Button>
              </Box>
            </form>
          </FormProvider>
        </CardBody>
      </Card>
    </Box>
  );
}
