import type { Role } from '@fieldbrick/core/constants';
import { useAuth } from './providers/auth';

export function AuthorizeRole({ children, role }: { children: React.ReactNode; role: Role[] }) {
  const session = useAuth();

  if (!session) {
    return null;
  }
  if (role.includes(session.role)) {
    return children;
  }
  return null;
}
