import { PageLayout } from '@/components/page-layout';
import { trpc } from '@/lib/trpc';
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function LoginPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const { mutate: sendMagicLink, isPending } = trpc.auth.magicLink.send.useMutation({
    onSuccess({ authRequestId }) {
      navigate({
        pathname: '/login/verify',
        search: new URLSearchParams({ emailAddress, authRequestId }).toString(),
      });
      toast({ title: 'Magic link sent', status: 'success', duration: 5000, isClosable: true, position: 'top' });
      setEmailAddress('');
    },
    onError() {
      setEmailAddress('');
      toast({ title: 'Error sending magic link.', status: 'error' });
    },
  });

  const [emailAddress, setEmailAddress] = useState('');

  return (
    <PageLayout className="h-screen w-full mx-auto flex flex-col items-center justify-center">
      <PageLayout.Content>
        <Box className="flex flex-col items-center justify-center w-full flex-1 gap-8">
          <Heading className="text-lg tracking-tighter lowercase">Fieldbrick</Heading>

          <form
            className="w-full max-w-md"
            onSubmit={(event) => {
              event.preventDefault();
              sendMagicLink({ emailAddress });
            }}
          >
            <FormControl className="flex flex-col gap-4">
              <Flex direction="column">
                <FormLabel className="!text-lg !font-semibold tracking-tight">Email address</FormLabel>
                <Input
                  type="email"
                  onChange={(event) => setEmailAddress(event.target.value)}
                  value={emailAddress}
                  size="lg"
                  className="!border-slate-400"
                />
              </Flex>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={isPending}
                size="lg"
                isDisabled={!emailAddress.trim()}
              >
                Continue
              </Button>
            </FormControl>
          </form>
        </Box>
      </PageLayout.Content>
    </PageLayout>
  );
}
