import { Box, Card, CardBody, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useInspections } from '../hooks/use-inspections.js';
import { InspectionStatusButton } from './status-button.tsx';

type InspectionListProps = {
  projectId: string;
};

export function InspectionsList({ projectId }: InspectionListProps) {
  const inspections = useInspections({ projectId });
  return inspections.map((inspection) => {
    return (
      <Card className="col-span-12" key={inspection.id} variant="outline">
        <CardBody>
          <Box className="flex justify-between items-center">
            <Text fontWeight="bold" fontSize="x-large" className="!font-sans !tracking-tight">
              {inspection.form.name}
            </Text>

            <Box className="flex gap-2 items-center">
              {inspection.syncedAt ? (
                <Text>Last synced {DateTime.fromSQL(inspection.syncedAt).toRelative()}</Text>
              ) : null}
              <InspectionStatusButton inspection={inspection} />
            </Box>
          </Box>
        </CardBody>
      </Card>
    );
  });
}
