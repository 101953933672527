import { z } from 'zod';

export const acceptInvitationInput = z.object({
  organizationId: z.string(),
  invitationId: z.string(),
  firstName: z.string().trim().min(1, 'First name is required').toLowerCase(),
  lastName: z.string().trim().min(1, 'Last name is required').toLowerCase(),
});

export type AcceptInvitationInput = z.infer<typeof acceptInvitationInput>;
