import type { FormField } from '@fieldbrick/core/db/schema/forms';
import type { FormRule } from '@fieldbrick/core/db/schema/rules';
import { id } from '@fieldbrick/core/util/id.js';
import { type Draft, create } from 'mutative';
import { P, match } from 'ts-pattern';

/**
 * Given a list of fields and rules for a form template, we want to
 * replace all the hard-coded field IDs with new IDs while maintaining
 * all the parent-child relationships between the fields.
 */
export function resolveTemplateFields<T extends FormField[]>(fields: T) {
  const fieldResolutionMap = new Map<string, string>();
  const resolvedFields = fields
    .map((field) => {
      const fieldId = id('field');
      fieldResolutionMap.set(field.id, fieldId);

      return { ...field, id: fieldId };
    })
    .map((field) => {
      if (!field.parentId) {
        return field;
      }

      const newParentId = fieldResolutionMap.get(field.parentId);
      if (!newParentId) {
        throw new Error(`Parent ID ${field.parentId} not found`);
      }

      return { ...field, parentId: newParentId };
    });
  return { resolvedFields, fieldResolutionMap };
}

export function resolveTemplateRules<T extends FormRule[]>(rules: T, fieldResolutionMap: Map<string, string>) {
  const resolveId = (id: string) => {
    const newId = fieldResolutionMap.get(id);
    if (!newId) {
      throw new Error(`Field with ID ${id} not found`);
    }
    return newId;
  };

  function resolveConditionRules(rules: Draft<FormRule['definition']['rules']>) {
    for (const rule of rules) {
      match(rule)
        // .with({ operator: P.union('and', 'or') }, (rule) => {
        //   // This is a nested condition so we need to recursively resolve the rules
        //   resolveConditionRules(rule.rules);
        // })
        .with({ type: P.union('multiple_choice', 'radio') }, (rule) => {
          rule.reference.id = resolveId(rule.reference.id);
        })
        .exhaustive();
    }
  }

  return create(rules, (draftRules) => {
    for (const rule of draftRules) {
      for (const action of rule.actions) {
        match(action)
          .with({ type: 'show' }, ({ reference }) => {
            reference.id = resolveId(reference.id);
          })
          .exhaustive();
      }
      resolveConditionRules(rule.definition.rules);
    }
  });
}
