import { Box } from '@chakra-ui/react';
import type { InspectionField } from '../../lib/schema.js';
import { FormElement } from '../form-element.tsx';

export const RowField = ({ block }: InspectionField) => {
  return (
    <Box className="grid grid-cols-12 gap-8 col-span-12" key={block.id}>
      {block.children.map((child) => (
        <FormElement key={child.id} block={child} />
      ))}
    </Box>
  );
};
