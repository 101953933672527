import type { FormField } from '@fieldbrick/core/db/schema/forms';
import type { Inspection } from '@fieldbrick/core/db/schema/inspections';
import type { FormRule } from '@fieldbrick/core/db/schema/rules';
import React from 'react';

export const InspectionFormContext = React.createContext<{
  inspection: Inspection;
  formRules: FormRule[];
  formFields: FormField[];
  setFormFields: React.Dispatch<React.SetStateAction<FormField[]>>;
  setFormRules: React.Dispatch<React.SetStateAction<FormRule[]>>;
  formId: string;
  inspectionId: string;
} | null>(null);

export const useInspectionFormContext = () => {
  const context = React.use(InspectionFormContext);
  if (!context) {
    throw new Error('useInspectionFormContext must be used within an <InspectionFormContext.Provider>');
  }
  return context;
};
