import { useReplicache } from '@/components/providers/replicache';
import { CustomerAddressStore } from '@/data/customer-addresses';
import { CustomerStore } from '@/data/customers';
import { InspectionStore } from '@/data/inspections';
import { ProjectStore } from '@/data/projects';
import { trpc } from '@/lib/trpc';
import { useNetworkState } from '@uidotdev/usehooks';
import { useSubscribe } from 'replicache-react';

type Props = {
  projectId: string;
};

export function useProject({ projectId }: Props) {
  const { online } = useNetworkState();
  const replicache = useReplicache();
  const { data: remoteProject } = trpc.projects.show.useQuery({ projectId }, { enabled: online });
  const localProject = useSubscribe(replicache, async (tx) => {
    if (!projectId) {
      return;
    }
    const project = await ProjectStore.get(tx, { id: projectId });
    const inspections = await InspectionStore.all(tx, { projectId });
    const customer = await CustomerStore.get(tx, { id: project.customerId });
    const customerAddress = await CustomerAddressStore.get(tx, {
      customerId: project.customerId,
      id: project.customerAddressId,
    });
    return { ...project, customer, customerAddress, inspections };
  });

  const project = localProject ?? remoteProject;

  return project;
}
