import { trpc } from '@/lib/trpc';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { Controller, FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import type { z } from 'zod';

import { PageLayout } from '@/components/page-layout.tsx';
import { Customer } from '@fieldbrick/core/db/schema/customers.ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { CustomerAddresses } from './customer-addresses.tsx';
import { CustomerProjects } from './customer-projects.tsx';

const FormValues = Customer.pick({ firstName: true, lastName: true, phoneNumber: true, emailAddress: true, id: true });
type FormValues = z.infer<typeof FormValues>;

export function CustomerDetails() {
  const toast = useToast();
  const { customerId } = useParams();
  const [customer, { refetch }] = trpc.customers.byId.useSuspenseQuery({ id: customerId! });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const form = useForm({
    resolver: zodResolver(FormValues),
    defaultValues: {
      id: customer.id,
      firstName: customer.firstName,
      lastName: customer.lastName,
      phoneNumber: customer.phoneNumber,
      emailAddress: customer.emailAddress ?? '',
    },
  });

  const handleSubmit: SubmitHandler<FormValues> = async (values: FormValues) => {
    mutate(values);
  };

  const { mutate, isPending } = trpc.customers.update.useMutation({
    onSuccess() {
      refetch();
      onClose();
      toast({
        title: 'Customer updated.',
        description: "We've updated the customer account for you.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const lastEdited = DateTime.fromSQL(customer.updatedAt, { zone: 'utc' })
    .setZone('local')
    .toLocaleString(DateTime.DATETIME_MED);

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Title>Customer Profile</PageLayout.Title>
      </PageLayout.Header>

      <PageLayout.Content>
        <div className="flex flex-col gap-12">
          <FormProvider {...form}>
            <form id="edit-customer-contact-form" onSubmit={form.handleSubmit(handleSubmit)}>
              <Stack spacing={4} direction="column">
                <HStack gap={4}>
                  <Controller
                    control={form.control}
                    name="firstName"
                    render={({ field, fieldState: { error } }) => {
                      const hasError = !!error?.message;
                      return (
                        <FormControl isRequired={true} isInvalid={hasError}>
                          <FormLabel htmlFor={field.name} className="!font-medium tracking-tight !text-lg">
                            First name
                          </FormLabel>
                          <Input {...field} id={field.name} className="capitalize" size="lg" />
                          <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                        </FormControl>
                      );
                    }}
                  />
                  <Controller
                    control={form.control}
                    name="lastName"
                    render={({ field, fieldState: { error } }) => {
                      const hasError = !!error?.message;
                      return (
                        <FormControl isRequired={true} isInvalid={hasError}>
                          <FormLabel htmlFor={field.name} className="!font-medium tracking-tight !text-lg">
                            Last name
                          </FormLabel>
                          <Input {...field} id={field.name} className="capitalize" size="lg" />
                          <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                        </FormControl>
                      );
                    }}
                  />
                </HStack>
                <Controller
                  control={form.control}
                  name="phoneNumber"
                  render={({ field, fieldState: { error } }) => {
                    const hasError = !!error?.message;
                    return (
                      <FormControl isRequired={true} isInvalid={hasError}>
                        <FormLabel htmlFor={field.name} className="!font-medium tracking-tight !text-lg">
                          Phone number
                        </FormLabel>
                        <Input {...field} id={field.name} className="capitalize" size="lg" />
                        <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                      </FormControl>
                    );
                  }}
                />
                <Controller
                  control={form.control}
                  name="emailAddress"
                  render={({ field, fieldState: { error } }) => {
                    const hasError = !!error?.message;
                    return (
                      <FormControl isRequired={true} isInvalid={hasError}>
                        <FormLabel htmlFor={field.name} className="!font-medium tracking-tight !text-lg">
                          Email address
                        </FormLabel>
                        <Input {...field} id={field.name} size="lg" />
                        <Box>{hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                      </FormControl>
                    );
                  }}
                />

                <Box className="flex justify-between items-center gap-4">
                  <div className="text-sm font-semibold text-gray-600">Last Edited: {lastEdited}</div>
                  <Button colorScheme="gray" type="submit" isLoading={isPending} size="lg">
                    Update customer
                  </Button>
                </Box>
              </Stack>
            </form>
          </FormProvider>

          <Divider />
          <CustomerAddresses addresses={customer.addresses} />
          <Divider />
          <CustomerProjects projects={customer.projects} />
        </div>
      </PageLayout.Content>
    </PageLayout>
  );
}
