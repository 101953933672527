import { cn } from '@/lib/cn.js';
import { AdminLoginPage } from '@/pages/admin/auth/login.tsx';
import { AdminVerifyPage } from '@/pages/admin/auth/verify.tsx';
import { AdminOrganizationsPage } from '@/pages/admin/organizations/index.tsx';
import { AdminNewOrganizationPage } from '@/pages/admin/organizations/new.tsx';
import { AdminOrganizationPage } from '@/pages/admin/organizations/show.tsx';
import { AdminTemplateDesignerPage } from '@/pages/admin/organizations/templates/designer.tsx';
import { LoginPage } from '@/pages/auth/login';
import { LoginVerifyPage } from '@/pages/auth/verify.tsx';
import { CustomersPage } from '@/pages/customers/index/index.tsx';
import { CustomerDetails } from '@/pages/customers/show/index.tsx';
import { AcceptInvitationPage } from '@/pages/invitations/accept.tsx';
import { ProjectsPage } from '@/pages/projects/index';
import { InspectionPage } from '@/pages/projects/inspection';
import { InspectionSummaryPage } from '@/pages/projects/inspection/summary';
import { NewProject } from '@/pages/projects/new/components/new-project.tsx';
import { ProjectPage } from '@/pages/projects/show';
import { PublicInspectionsPage } from '@/pages/public/inspections';
import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { Avatar } from '@chakra-ui/react';
import { SESSION_COOKIE_NAME } from '@fieldbrick/core/constants.js';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { useNetworkState } from '@uidotdev/usehooks';
import Cookies from 'js-cookie';
import { ChevronDownIcon, MenuIcon } from 'lucide-react';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { dropAllDatabases } from 'replicache';
import { AdminLayout } from './admin/layout.tsx';
import { AdminContextProvider, AuthProvider, useAuth, useCurrentUser } from './providers/auth.tsx';
import { ReplicacheProvider, useReplicache } from './providers/replicache';
import { Sidebar, SidebarProvider, useSidebarState } from './sidebar.tsx';

function AdminRoutes() {
  return (
    <AdminContextProvider>
      <Helmet>
        <title>Fieldbrick | Admin</title>
      </Helmet>
      <AdminLayout>
        <Box className="md:max-w-6xl p-8 mx-auto">
          <Outlet />
        </Box>
      </AdminLayout>
    </AdminContextProvider>
  );
}

function AuthProviderWrapper() {
  return (
    <AuthProvider>
      <Outlet />
    </AuthProvider>
  );
}

export function ProtectedRoutes() {
  return (
    <AuthProvider>
      <ReplicacheProvider>
        <Layout>
          <Box className="md:max-w-6xl p-8 flex flex-col gap-4 w-full mx-auto h-screen">
            <Outlet />
          </Box>
        </Layout>
      </ReplicacheProvider>
    </AuthProvider>
  );
}

function UnprotectedRoutes() {
  return (
    <Box className="md:max-w-6xl p-8 md:px-0 flex flex-col gap-4 w-full mx-auto h-screen">
      <Outlet />
    </Box>
  );
}

function SidebarToggle() {
  const { setOpen } = useSidebarState();
  return (
    <IconButton
      icon={<MenuIcon />}
      aria-label="Menu"
      size="lg"
      variant="ghost"
      onClick={() => setOpen((open) => !open)}
    />
  );
}
function Layout({ children }: { children: React.ReactNode }) {
  const { online } = useNetworkState();
  const user = useCurrentUser();

  const replicache = useReplicache();
  const session = useAuth();
  const isImpersonating = !!session?.impersonatedBy;
  const navigate = useNavigate();
  const logout = async () => {
    await replicache.close();
    await dropAllDatabases();
    Cookies.remove(SESSION_COOKIE_NAME);
    navigate('/login', { replace: true });
  };

  return (
    <SidebarProvider>
      <Box display="flex" flexDirection="column" id="main" className="h-screen overflow-hidden">
        <Box as="header" className={cn('flex h-16 items-center border lg:px-12 px-6 py-4 justify-between')}>
          <Box className="flex items-center gap-4">
            <SidebarToggle />
            <h2 className="text-xl font-semibold leading-none tracking-tighter">
              <Link to={`/org/${session.organizationId}/projects`}>
                <span className="block">fieldbrick</span>
              </Link>
            </h2>
          </Box>

          <Box className="flex items-center gap-4">
            <Badge colorScheme={online ? 'blue' : 'red'}>
              <Text>{online ? 'Online' : 'Offline'}</Text>
            </Badge>

            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                className="capitalize"
                variant="ghost"
                leftIcon={<Avatar name={[user.firstName, user.lastName].join(' ')} size="xs" />}
              >
                {isImpersonating ? 'Impersonating ' : ''}
                {[user.firstName, user.lastName].join(' ')}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => logout()}>Log out</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Box>

        <Flex className="overflow-hidden h-full">
          <Sidebar />
          <ScrollArea.Root className="pt-2 pb-4 overflow-hidden w-full" type="auto">
            <ScrollArea.Viewport className="h-full w-full">
              <Suspense>{children}</Suspense>
            </ScrollArea.Viewport>

            <ScrollArea.Scrollbar
              orientation="vertical"
              className="flex w-2 touch-none select-none rounded-md bg-zinc-200"
            >
              <ScrollArea.Thumb className="relative flex-1 rounded-md bg-zinc-600" />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
        </Flex>
      </Box>
    </SidebarProvider>
  );
}

export function ApplicationRoutes() {
  return (
    <Routes>
      <Route element={<UnprotectedRoutes />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="login/verify" element={<LoginVerifyPage />} />
        <Route path="admin/login" element={<AdminLoginPage />} />
        <Route path="admin/login/verify" element={<AdminVerifyPage />} />
        <Route path="org/:orgId/invitations/:invitationId" element={<AcceptInvitationPage />} />
      </Route>

      <Route path="p">
        <Route path=":projectId" element={<PublicInspectionsPage />} />
      </Route>

      <Route element={<AuthProviderWrapper />}>
        <Route path="org/:orgId" element={<ProtectedRoutes />}>
          <Route index={true} element={<Navigate to="/dashboard/projects" />} />
          <Route path="projects" element={<ProjectsPage />} />
          <Route path="projects/new" element={<NewProject />} />
          <Route path="customers" element={<CustomersPage />} />
          <Route path="customers/:customerId" element={<CustomerDetails />} />
          <Route path="projects/:projectId" element={<ProjectPage />} />
          <Route path="projects/:projectId/inspections/:inspectionId" element={<InspectionPage />} />
          <Route path="projects/:projectId/inspections/:inspectionId/summary" element={<InspectionSummaryPage />} />
        </Route>
      </Route>

      <Route path="admin" element={<AdminRoutes />}>
        <Route path="organizations">
          <Route
            index={true}
            element={
              <Suspense
                fallback={
                  <Container centerContent={true} className="h-screen justify-center items-center">
                    <Spinner />
                  </Container>
                }
              >
                <AdminOrganizationsPage />
              </Suspense>
            }
          />
          <Route path="new" element={<AdminNewOrganizationPage />} />
          <Route path=":organizationId/templates/:templateId" element={<AdminTemplateDesignerPage />} />
          <Route path=":organizationId" element={<AdminOrganizationPage />} />
        </Route>
      </Route>

      <Route path="*" element={null} />
    </Routes>
  );
}
