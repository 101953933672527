import { CustomerAddress } from '@fieldbrick/core/db/schema/customers.js';
import { z } from 'zod';
import { Store } from './store.js';

export const CustomerAddressStore = new Store()
  .setScanner('all', (args: { customerId: string }) => ['customerAddresses', args.customerId])
  .setSchema(
    CustomerAddress.extend({
      createdAt: z.string().optional(),
      updatedAt: z.string().optional(),
    }),
  )
  .setPathResolver((args: { customerId: string; id: string }) => ['customerAddresses', args.customerId, args.id])
  .build();
