import { cn } from '@/lib/cn';
import { trpc } from '@/lib/trpc.ts';
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { ChevronDownIcon } from 'lucide-react';
import { Suspense } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAdmin } from '../providers/auth.tsx';

export function AdminLayout({ children }: { children: React.ReactNode }) {
  const admin = useAdmin();
  const navigate = useNavigate();
  const { mutate: logout } = trpc.admin.auth.logout.useMutation({
    onSuccess() {
      navigate('/admin/login', { replace: true });
    },
  });

  return (
    <Box display="flex" flexDirection="column" id="main" className="h-screen overflow-hidden">
      <Box as="header" className={cn('flex min-h-16 grow items-center border px-12 py-4 justify-between')}>
        <Box className="flex items-center gap-4">
          <h2 className="text-xl font-semibold leading-none tracking-tighter">
            <Link to="/admin/organizations">
              <span className="block">fieldbrick admin</span>
            </Link>
          </h2>
        </Box>

        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            className="capitalize flex items-center"
            variant="ghost"
          >
            {admin.fullName}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => logout()}>Log out</MenuItem>
          </MenuList>
        </Menu>
      </Box>

      <Flex className="overflow-hidden h-full">
        <ScrollArea.Root className="pt-2 pb-4 overflow-hidden w-full" type="auto">
          <ScrollArea.Viewport className="h-full w-full flex flex-col">
            <Suspense>{children}</Suspense>
          </ScrollArea.Viewport>

          <ScrollArea.Scrollbar
            orientation="vertical"
            className="flex w-2 touch-none select-none rounded-md bg-zinc-200"
          >
            <ScrollArea.Thumb className="relative flex-1 rounded-md bg-zinc-600" />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
      </Flex>
    </Box>
  );
}
