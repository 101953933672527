import { pgEnum } from 'drizzle-orm/pg-core';

export const fieldType = pgEnum('field_type', [
  'radio',
  'number',
  'group',
  'dropdown',
  'long_text',
  'short_text',
  'file_upload',
  'measurement',
  'multiple_choice',
  'radio_group',
  'select',
  'date',
  'row',
]);

export const visibilityType = pgEnum('visibility_type', ['visible', 'hidden']);
export const ownershipType = pgEnum('ownership_type', ['inspection', 'template']);
export const formStatus = pgEnum('form_status', ['draft', 'published', 'archived']);
export const triggerAction = pgEnum('trigger_action', ['show', 'hide']);
