import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input, Switch } from '@chakra-ui/react';
import { type DesignerFormFieldInput, designerFormFieldInput } from '@fieldbrick/core/domain/admin/templates/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { EyeOffIcon, GroupIcon } from 'lucide-react';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import type { ElementsType, FormElement, FormElementInstance } from '.';
import { useDesigner } from '../context';

const type: ElementsType = 'GroupField';

const extraAttributes = {
  type: 'group' as const,
  title: 'Group',
  description: '',
  properties: {
    type: 'group' as const,
    design: { layout: {} },
    meta: {},
  },
  validation: { type: 'group' as const },
  visibility: 'visible' as const,
  ownershipType: 'template',
  parentId: null,
  publicVisibility: 'visible',
} satisfies Omit<
  DesignerFormFieldInput,
  'id' | 'version' | 'order' | 'organizationId' | 'formId' | 'createdAt' | 'updatedAt'
>;

const propertiesSchema = designerFormFieldInput.omit({ order: true, organizationId: true, formId: true });

export const GroupFieldFormElement: FormElement = {
  type,
  construct: (id: string) => ({
    id,
    type,
    extraAttributes,
    children: [],
  }),
  designerBtnElement: {
    icon: GroupIcon,
    label: 'Group',
  },
  designerComponent: DesignerComponent,
  propertiesComponent: PropertiesComponent,
};

type CustomInstance = FormElementInstance & {
  extraAttributes: DesignerFormFieldInput;
};

function DesignerComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const element = elementInstance as CustomInstance;
  const { title, visibility } = element.extraAttributes;
  return (
    <div className="flex flex-col gap-1 w-full">
      {visibility === 'hidden' && (
        <Box className="ml-auto">
          <EyeOffIcon className="text-gray-400" />
        </Box>
      )}
      <FormLabel className="font-semibold text-gray-600 !tracking-tight" my={0}>
        {title}
      </FormLabel>
    </div>
  );
}

function PropertiesComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const element = elementInstance as CustomInstance;
  const { updateElement } = useDesigner();
  const form = useForm<DesignerFormFieldInput>({
    resolver: zodResolver(propertiesSchema),
    mode: 'onBlur',
    defaultValues: {
      ...element.extraAttributes,
      id: element.id,
    },
  });

  useEffect(() => {
    form.reset({ ...element.extraAttributes, id: element.id });
  }, [element, form]);

  function applyChanges(values: DesignerFormFieldInput) {
    updateElement(element.id, {
      ...element,
      extraAttributes: values,
    });
  }

  return (
    <FormProvider {...form}>
      <form onBlur={form.handleSubmit(applyChanges)} className="flex flex-col gap-4">
        <Flex direction="column" gap="4">
          <Controller
            control={form.control}
            name="title"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Title</FormLabel>
                  <Input {...field} />
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="visibility"
            render={({ field }) => {
              return (
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="visibility" mb="0">
                    Is visible?
                  </FormLabel>
                  <Switch
                    id="visibility"
                    isChecked={field.value === 'visible'}
                    onChange={(event) =>
                      form.setValue(field.name, event.target.checked ? 'visible' : 'hidden', {
                        shouldValidate: true,
                      })
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Flex>

        <Controller
          control={form.control}
          name="publicVisibility"
          render={({ field }) => {
            return (
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor={field.name} mb="0">
                  Show in customer report?
                </FormLabel>
                <Switch
                  id={field.name}
                  isChecked={field.value === 'visible'}
                  onChange={(event) =>
                    form.setValue(field.name, event.target.checked ? 'visible' : 'hidden', {
                      shouldValidate: true,
                    })
                  }
                />
              </FormControl>
            );
          }}
        />
      </form>
    </FormProvider>
  );
}
