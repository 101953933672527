import { Box } from '@chakra-ui/react';
import type { InspectionField } from '../../lib/schema.js';
import { FormElement } from '../form-element.tsx';

export const GroupField = ({ block }: InspectionField) => {
  return (
    <Box className="flex flex-col gap-4 col-span-12 rounded-md border p-4" id={[block.type, block.id].join('-')}>
      {/* <Box className="flex justify-start">
        <h3 className="font-semibold tracking-tighter text-2xl">{block.title}</h3>
      </Box> */}

      <Box className="grid grid-cols-12 gap-8 col-span-12" key={block.id}>
        {block.children.map((child) => (
          <FormElement key={child.id} block={child} />
        ))}
      </Box>
    </Box>
  );
};
