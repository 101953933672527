import { cn } from '@/lib/cn';
import { Field, Radio } from '@headlessui/react';

export function RadioCard({ value, children }: { value: string; children: React.ReactNode }) {
  return (
    <Field className="w-full">
      <Radio
        value={value}
        className={cn(
          'cursor-pointer focus:outline-none',
          'flex items-center justify-center rounded-md bg-white px-3 py-3 text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset',
        )}
      >
        {children}
      </Radio>
    </Field>
  );
}
