import { PageLayout } from '@/components/page-layout';
import { trpc } from '@/lib/trpc';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  PinInput,
  PinInputField,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function LoginVerifyPage() {
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { mutate: authenticatedWithCode, isPending } = trpc.auth.magicLink.authenticateWithCode.useMutation({
    onSuccess({ organizationId }) {
      toast({ title: 'Code verified', status: 'success', duration: 3000 });
      navigate(`/org/${organizationId}/projects`, { replace: true });
    },
    onError() {
      toast({ title: 'Could not verify code.', status: 'error' });
    },
  });

  const [code, setCode] = useState('');

  return (
    <PageLayout className="h-screen w-full mx-auto flex flex-col items-center justify-center">
      <PageLayout.Content>
        <Box className="flex flex-col items-center justify-center w-full flex-1 gap-8">
          <Heading className="text-lg tracking-tighter lowercase">Fieldbrick</Heading>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const authRequestId = searchParams.get('authRequestId')!;
              if (!(code && authRequestId)) {
                toast({
                  title: 'Missing code or authRequestId',
                  status: 'error',
                  duration: 2000,
                });
                return;
              }

              authenticatedWithCode({ code, authRequestId });
            }}
          >
            <FormControl className="flex flex-col gap-4">
              <Flex direction="column" gap={4}>
                <FormLabel className="!text-lg !font-semibold tracking-tight">Code</FormLabel>
                <HStack className="w-full" justifyContent="space-between">
                  <PinInput placeholder="" onComplete={(value) => setCode(value)} size="lg">
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
                <FormHelperText>
                  Enter the code you received in the email. The code will expire in 10 minutes.
                </FormHelperText>
              </Flex>
              <Button colorScheme="blue" type="submit" isLoading={isPending} disabled={!code.trim()}>
                Login
              </Button>
            </FormControl>
          </form>
        </Box>
      </PageLayout.Content>
    </PageLayout>
  );
}
