import { FormFieldStore, FormRuleStore } from '@/data/forms';
import { InspectionResponseStore } from '@/data/inspection-response';
import type { ExtractMutationFunctions } from '@fieldbrick/core/sync/framework';
import type { ReplicacheServerType } from '@fieldbrick/core/sync/replicache';
import { map } from 'lodash-es';

type Update = ExtractMutationFunctions<ReplicacheServerType>['updateForm'];

/**
 * Handles updating the inspection form locally. Fields could be added or removed
 * so we need to account for that and remove fields that are deleted as well as any
 * answers that referenced the deleted fields.
 *
 * There are also rules that we can delete but we don't have to because the rules
 * control which fields show up and if those fields are deleted, the rules will be run
 * and will be deleted from the backend on the next sync.
 */
export const updateForm: Update = async (tx, input) => {
  const currentFields = await FormFieldStore.all(tx, { formId: input.id });
  const currentFieldIds = new Set(map(currentFields, 'id'));
  const currentResponses = await InspectionResponseStore.all(tx, { inspectionId: input.inspectionId });

  const incomingFieldIds = new Set(map(input.fields, 'id'));
  const fieldIdsToDelete = currentFieldIds.difference(incomingFieldIds);
  const fieldsToDelete = currentFields.filter((field) => fieldIdsToDelete.has(field.id));
  const responsesToDelete = currentResponses.filter((response) => !incomingFieldIds.has(response.fieldId));

  /**
   * Delete responses locally and the fields.
   */
  await Promise.all(fieldsToDelete.map((field) => FormFieldStore.delete(tx, [field])));
  await Promise.all(responsesToDelete.map((response) => InspectionResponseStore.delete(tx, [response])));

  await Promise.all(input.fields.map((field) => FormFieldStore.put(tx, [{ formId: input.id, id: field.id }], field)));
  await Promise.all(input.rules.map((rule) => FormRuleStore.put(tx, [{ formId: input.id, id: rule.id }], rule)));
};
