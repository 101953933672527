import { useReplicache } from '@/components/providers/replicache';
import { FormStore } from '@/data/forms.js';
import { InspectionStore } from '@/data/inspections';
import type { Form } from '@fieldbrick/core/db/schema/forms.js';
import type { Inspection } from '@fieldbrick/core/db/schema/inspections';
// import { useNetworkState } from '@uidotdev/usehooks';
import { keyBy } from 'lodash-es';
import { useSubscribe } from 'replicache-react';

type Return = Array<Inspection & { form: Form }>;

export function useInspections({ projectId }: { projectId: string }): Return {
  // const { online } = useNetworkState();
  const replicache = useReplicache();
  const localInspections = useSubscribe(
    replicache,
    async (tx) => {
      const inspections = await InspectionStore.all(tx, { projectId });
      const forms = await Promise.all(
        inspections.map((inspection) => FormStore.all(tx, { inspectionId: inspection.id })),
      );
      const formsByInspectionId = keyBy(forms.flat(), 'inspectionId');
      const inspectionsWithForms = inspections.map((inspection) => {
        const form = formsByInspectionId[inspection.id];
        if (!form) {
          throw new Error(`Form with id ${inspection.id} not found`);
        }
        return { ...inspection, form };
      });
      return inspectionsWithForms;
    },
    { default: [] },
  );
  return localInspections;
}
