import { useAuth } from '@/components/providers/auth.tsx';
import { useReplicache } from '@/components/providers/replicache.tsx';
import { FormFieldStore, FormRuleStore, FormStore } from '@/data/forms.js';
import { InspectionResponseStore } from '@/data/inspection-response.js';
import { Box, Button, Progress } from '@chakra-ui/react';
import type { FormField } from '@fieldbrick/core/db/schema/forms.js';
import type { Inspection, InspectionResponse } from '@fieldbrick/core/db/schema/inspections.js';
import type { FormRule } from '@fieldbrick/core/db/schema/rules.js';
import { id } from '@fieldbrick/core/util/id.js';
import { zodResolver } from '@hookform/resolvers/zod';
import { diff } from 'deep-object-diff';
import merge from 'deepmerge';
import { pipe } from 'effect';
import { compact, isEmpty, isUndefined, keyBy, omitBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { create } from 'mutative';
import { type ReactNode, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { SubmitHandler, WatchObserver } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSubscribe } from 'replicache-react';
import { match } from 'ts-pattern';
import { useDebounceCallback } from 'usehooks-ts';
import { useDefaultValues } from '../hooks/use-default-values.tsx';
import { evaluateConditions } from '../lib/rules.js';
import { traverse } from '../lib/traversal.js';
import { buildValidationSchema } from '../lib/validation.js';
import { FormElement } from './form-element.tsx';
import { InspectionFormContext, useInspectionFormContext } from './inspection-form-context.js';

type Props = {
  inspection: Inspection;
  responses: Responses;
  rawResponses: InspectionResponse[];
};

type Responses = Record<string, InspectionResponse['response']>;

const InspectionFormWrapper = ({ inspection, responses, rawResponses }: Props) => {
  const replicache = useReplicache();
  const { organizationId } = useAuth();
  const { formFields, formRules, setFormFields, formId } = useInspectionFormContext();
  const resolver = zodResolver(buildValidationSchema(formFields));

  const { fieldsWithoutResponses } = useDefaultValues({ fields: formFields });
  const mergedFields = compact([fieldsWithoutResponses, responses]);
  const mergedDefaultValues = merge.all(mergedFields) as Responses;
  const form = useForm<Responses>({
    resolver,
    defaultValues: mergedDefaultValues,
    shouldUnregister: true,
  });

  const convertResponses = (formValues: Responses) => {
    const now = DateTime.utc().toSQL();
    const currentResponseByFieldId = keyBy(rawResponses, 'fieldId');

    return Object.entries(formValues).map(([fieldId, responseJson]) => {
      const response = currentResponseByFieldId[fieldId];
      const responseId = response?.id ?? id('response');
      return {
        organizationId,
        id: responseId,
        fieldId: fieldId,
        inspectionId: inspection.id,
        formId,
        type: responseJson.type,
        response: responseJson,
        createdAt: now,
        updatedAt: now,
        persistedAt: null,
      };
    });
  };

  const evaluateRules = async (values: Responses) => {
    const fieldUpdates = create(formFields, (draft) => {
      const fieldsById = keyBy(draft, 'id');
      for (const rule of formRules) {
        const conditionsEvaluatedToTrue = pipe(rule.definition, evaluateConditions(values));
        for (const action of rule.actions) {
          match(action)
            .with({ type: 'show' }, ({ reference }) => {
              const _field = fieldsById[reference.id];
              if (_field) {
                _field.visibility = conditionsEvaluatedToTrue ? 'visible' : 'hidden';
              }
            })
            .exhaustive();
        }
      }
    });

    if (!isEmpty(diff(formFields, fieldUpdates))) {
      setFormFields(structuredClone(fieldUpdates));
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    evaluateRules(mergedDefaultValues);
  }, []);

  const watcherSaver = useDebounceCallback<WatchObserver<Responses>>(async (values) => {
    const responses = convertResponses(omitBy(structuredClone(values), isUndefined) as Responses);
    await replicache.mutate.updateInspectionResponses({ inspectionId: inspection.id, responses, organizationId });
  }, 250);

  const ruleEvaluator: WatchObserver<Responses> = async (values, { name, type }) => {
    if (!name) {
      return;
    }
    const [fieldId] = name.split('.');
    if (!fieldId) {
      return;
    }

    const field = values[fieldId];
    if (!field) {
      return;
    }

    if (type === 'change' && ['multiple_choice', 'radio'].includes(field.type!)) {
      const responses = omitBy(structuredClone(values), isUndefined) as Responses;
      evaluateRules(responses);
    }
  };

  useEffect(() => {
    const { unsubscribe } = form.watch(watcherSaver);
    return unsubscribe;
  }, [form.watch, watcherSaver]);

  useEffect(() => {
    const { unsubscribe } = form.watch(ruleEvaluator);
    return unsubscribe;
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  }, [form.watch, ruleEvaluator]);

  const fieldTree = traverse(formFields);

  const navigate = useNavigate();

  const handleSubmit: SubmitHandler<Responses> = async (formValues) => {
    const responses = convertResponses(formValues);

    await replicache.mutate.updateInspectionResponses({ inspectionId: inspection.id, responses, organizationId });
    await replicache.mutate.updateInspection(
      merge(inspection, { status: 'completed', completedAt: DateTime.utc().toSQL() }),
    );

    navigate(`/org/${organizationId}/projects/${inspection.projectId}`);
  };

  const visibleFields = formFields
    .filter((field) => field.visibility === 'visible')
    .filter((field) => !['group', 'choice'].includes(field.type));

  const progress = visibleFields.reduce((acc, field) => {
    const response = responses[field.id]?.value;
    return acc + (isEmpty(response) ? 0 : 1);
  }, 0);

  return (
    <div className="flex flex-col gap-6">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4 pb-20" id="inspection-form">
          <div className="grid w-full grid-cols-12 gap-4">
            {fieldTree.map((field) => (
              <div key={field.id} className="col-span-12 gap-4 grid grid-cols-12">
                <FormElement key={field.id} block={field} />
              </div>
            ))}
          </div>
        </form>
      </FormProvider>

      <Box className="h-20 fixed bottom-0 left-0 right-0 z-10  border-t bg-white">
        <Box className="w-full flex items-center justify-between p-4 gap-8">
          <Box className="w-full">
            <Progress value={(progress / visibleFields.length) * 100} className="!rounded-md" />
          </Box>
          <Box ml="auto">
            <Button colorScheme="blue" type="submit" form="inspection-form">
              Complete inspection
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

type AnotherWrapperProps = {
  children: ReactNode;
  fields: FormField[];
  rules: FormRule[];
  formId: string;
  inspection: Inspection;
};

const AnotherWrapper = ({ children, fields, rules, formId, inspection }: AnotherWrapperProps) => {
  const [formFields, setFormFields] = useState<FormField[]>(fields);
  const [formRules, setFormRules] = useState<FormRule[]>(rules);
  const value = { formFields, setFormFields, formRules, setFormRules, formId, inspectionId: inspection.id, inspection };
  return <InspectionFormContext value={value}>{children}</InspectionFormContext>;
};

export const InspectionForm = ({ inspection }: Pick<Props, 'inspection'>) => {
  const replicache = useReplicache();

  const record = useSubscribe(replicache, async (tx) => {
    const allForms = await FormStore.all(tx, { inspectionId: inspection.id });
    const form = allForms.find((f) => f.inspectionId === inspection.id);
    if (!form) {
      return;
    }

    const fields = await FormFieldStore.all(tx, { formId: form.id });
    const rules = await FormRuleStore.all(tx, { formId: form.id });
    const responses = await InspectionResponseStore.all(tx, { inspectionId: inspection.id });

    return {
      form,
      fields: fields.toSorted((a, b) => a.order - b.order),
      rules,
      rawResponses: responses,
      responses: Object.fromEntries(responses.map((r) => [r.fieldId, r.response])),
    };
  });

  if (!record) {
    return null;
  }

  const { form, responses, fields, rules, rawResponses } = record;

  return (
    <AnotherWrapper fields={fields} rules={rules} formId={form.id} inspection={inspection}>
      <InspectionFormWrapper inspection={inspection} responses={responses} rawResponses={rawResponses} />
    </AnotherWrapper>
  );
};
