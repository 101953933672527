import { noop } from 'lodash-es';
import { createContext, use } from 'react';

export type NewProjectState = 'startProject' | 'addCustomer' | 'addCustomerAddress';

export const NewProjectContext = createContext<{
  state: NewProjectState;
  setState: (state: NewProjectState) => void;
}>({
  state: 'startProject',
  setState: noop,
});

export const useNewProjectContext = () => {
  const context = use(NewProjectContext);
  if (!context) {
    throw new Error('useNewProjectContext must be used within a NewProjectProvider');
  }
  return context;
};
