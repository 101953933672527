import { typeid } from 'typeid-js';
import { z } from 'zod';

const prefixes = z.union([
  z.literal('org'),
  z.literal('membership'),
  z.literal('user'),
  z.literal('proj'),
  z.literal('cust'),
  z.literal('addr'),
  z.literal('form'),
  z.literal('field'),
  z.literal('rule'),
  z.literal('inspect'),
  z.literal('response'),
  z.literal('response_snapshot'),
  z.literal('attach'),
  z.literal('prop'),
  z.literal('line_item'),
  z.literal('service'),
  z.literal('sync_group'),
  z.literal('sync_client'),
  z.literal('cvr'),
  z.literal('admin'),
  z.literal('session'),
  z.literal('auth_request'),
  z.literal('invitation'),
  z.literal('project_photo'),
  z.literal('branding'),
  z.literal('disclaimer'),
]);

export function id<Prefix extends z.infer<typeof prefixes>>(prefix: Prefix) {
  return typeid(prefix).toString();
}
