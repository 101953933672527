import { ReplicacheClient } from '@fieldbrick/core/sync/framework.js';
import type { ReplicacheServerType } from '@fieldbrick/core/sync/replicache.js';
import { merge } from 'lodash-es';
import { rawReturn } from 'mutative';
import { CustomerAddressStore } from '../customer-addresses.js';
import { CustomerStore } from '../customers.js';
import { FormFieldStore, FormRuleStore, FormStore } from '../forms.js';
import { InspectionResponseStore } from '../inspection-response.js';
import { InspectionStore } from '../inspections.js';
import { ProjectStore } from '../projects.js';
import { updateForm } from './forms/update.js';
import { updateInspectionResponses } from './inspections/responses/update.js';

export const mutators = new ReplicacheClient<ReplicacheServerType>()
  .mutation('createProject', async (tx, input) => {
    await ProjectStore.put(tx, [{ id: input.project.id }], input.project);
    for (const inspection of input.inspections) {
      const form = input.forms.find((form) => form.inspectionId === inspection.id);
      if (!form) {
        throw new Error(`Form with id ${input.project.id} not found`);
      }

      const fields = input.formFields.filter((f) => f.formId === form.id);
      const rules = input.formRules.filter((f) => f.formId === form.id);

      await Promise.all([
        fields.map((field) => FormFieldStore.put(tx, [{ formId: form.id, id: field.id }], field)),
        rules.map((rule) => FormRuleStore.put(tx, [{ formId: form.id, id: rule.id }], rule)),
      ]);
      await InspectionStore.put(tx, [{ projectId: input.project.id, id: inspection.id }], inspection);
      await FormStore.put(tx, [{ inspectionId: inspection.id, id: form.id }], form);
    }
  })
  .mutation('updateInspection', async (tx, input) => {
    await InspectionStore.update(tx, [{ projectId: input.projectId, id: input.id }], (draft) => {
      return rawReturn(merge({}, draft, input));
    });
  })
  .mutation('updateForm', updateForm)
  .mutation('updateInspectionResponses', updateInspectionResponses)
  .mutation('updateLocalInspectionResponses', async (tx, input) => {
    await Promise.all(
      input.responses.map((response) =>
        InspectionResponseStore.put(tx, [{ inspectionId: input.inspectionId, id: response.id }], response),
      ),
    );
  })
  .mutation('upsertCustomer', async (tx, input) => {
    await CustomerStore.put(tx, [{ id: input.id }], input);
  })
  .mutation('upsertCustomerAddress', async (tx, input) => {
    await CustomerAddressStore.put(tx, [input], input);
  })
  .build();

export type Mutators = typeof mutators;
