import { relations } from 'drizzle-orm';
import { boolean, foreignKey, index, integer, pgTable, primaryKey, varchar } from 'drizzle-orm/pg-core';
import { createSelectSchema } from 'drizzle-zod';
import type { Simplify } from 'type-fest';
import type { z } from 'zod';
import { timestamps } from '../../util/sql';
import { customerAddresses, customers } from './customers';
import { inspections } from './inspections';
import { organizations } from './organizations';
import { proposals } from './proposals';
import { users } from './users';

export const projects = pgTable(
  'projects',
  {
    id: varchar('id', { length: 256 }).notNull(),
    customerId: varchar('customer_id', { length: 256 }).notNull(),
    customerAddressId: varchar('customer_address_id', { length: 256 })
      .notNull()
      .references(() => customerAddresses.id),
    organizationId: varchar('organization_id', { length: 256 }).notNull(),
    createdById: varchar('created_by_id', { length: 256 })
      .references(() => users.id)
      .notNull(),
    ...timestamps(),
  },
  (t) => ({
    primaryKey: primaryKey({ columns: [t.organizationId, t.id] }),
    customerFk: foreignKey({
      columns: [t.organizationId, t.customerId],
      foreignColumns: [customers.organizationId, customers.id],
    }).onDelete('cascade'),
    customerFkIndex: index().on(t.organizationId, t.customerId),
    addressIndex: index().on(t.customerAddressId),
    createdByIdIndex: index().on(t.createdById),
  }),
);

export const projectPhotos = pgTable(
  'project_photos',
  {
    id: varchar('id', { length: 256 }).notNull(),
    url: varchar('url').notNull(),
    fileName: varchar('file_name').notNull(),
    size: integer('size').notNull(),
    contentType: varchar('content_type').notNull(),
    isCoverPhoto: boolean('is_cover_photo').notNull(),
    projectId: varchar('project_id', { length: 256 }).notNull(),
    organizationId: varchar('organization_id', { length: 256 })
      .notNull()
      .references(() => organizations.id),
    ...timestamps(),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.organizationId, t.id] }),
  }),
);

export const projectRelations = relations(projects, ({ one, many }) => ({
  customer: one(customers, {
    fields: [projects.organizationId, projects.customerId],
    references: [customers.organizationId, customers.id],
  }),
  organization: one(organizations, {
    fields: [projects.organizationId],
    references: [organizations.id],
  }),
  inspections: many(inspections),
  customerAddress: one(customerAddresses, {
    fields: [projects.customerAddressId],
    references: [customerAddresses.id],
  }),
  proposals: many(proposals),
  photos: many(projectPhotos),
  createdBy: one(users, {
    fields: [projects.createdById],
    references: [users.id],
  }),
}));

export const projectPhotoRelations = relations(projectPhotos, ({ one }) => ({
  project: one(projects, {
    fields: [projectPhotos.organizationId, projectPhotos.projectId],
    references: [projects.organizationId, projects.id],
  }),
}));

export const Project = createSelectSchema(projects);
export const ProjectPhoto = createSelectSchema(projectPhotos);

export type Project = Simplify<z.infer<typeof Project>>;
export type ProjectPhoto = Simplify<z.infer<typeof ProjectPhoto>>;
