import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import { type DesignerFormFieldInput, designerFormFieldInput } from '@fieldbrick/core/domain/admin/templates/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { EyeOffIcon, ImagesIcon } from 'lucide-react';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import type { z } from 'zod';
import type { ElementsType, FormElement, FormElementInstance } from '.';
import { useDesigner } from '../context';

const type: ElementsType = 'FileUploadField';

const extraAttributes = {
  type: 'file_upload' as const,
  title: 'Photo upload',
  description: '',
  properties: {
    type: 'file_upload' as const,
    design: { layout: {} },
    meta: {},
  },
  validation: { type: 'file_upload' as const },
  visibility: 'visible' as const,
  ownershipType: 'template',
  parentId: '',
  publicVisibility: 'visible',
} satisfies Omit<
  DesignerFormFieldInput,
  'id' | 'version' | 'order' | 'organizationId' | 'formId' | 'createdAt' | 'updatedAt'
>;

const propertiesSchema = designerFormFieldInput.omit({ order: true, organizationId: true, formId: true });
type Properties = z.infer<typeof propertiesSchema>;

export const FileUploadFieldFormElement: FormElement = {
  type,
  construct: (id: string) => ({
    id,
    type,
    extraAttributes,
    children: [],
  }),
  designerBtnElement: {
    icon: ImagesIcon,
    label: 'Photo upload',
  },
  designerComponent: DesignerComponent,
  propertiesComponent: PropertiesComponent,
};

type CustomInstance = FormElementInstance & {
  extraAttributes: Properties;
};

function DesignerComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const element = elementInstance as CustomInstance;
  const { title, visibility, description } = element.extraAttributes;
  return (
    <div className="flex flex-col gap-1 w-full">
      {visibility === 'hidden' && (
        <Box className="ml-auto">
          <EyeOffIcon className="text-gray-400" />
        </Box>
      )}
      <FormLabel className="font-semibold text-gray-600 !tracking-tight" my={0}>
        {title}
      </FormLabel>

      <Button
        variant="link"
        className="p-20 border border-dashed rounded-lg border-slate-400 flex-1 flex items-center justify-center !h-full !min-h-[100px] !no-underline w-full"
      >
        <Box className="flex flex-col items-center justify-center gap-3">
          <ImagesIcon className="text-slate-600" />
          <span className="text-sm font-semibold text-gray-600">Add photos</span>
        </Box>
      </Button>

      {description && <p className="text-muted-foreground text-[0.8rem]">{description}</p>}
    </div>
  );
}

function PropertiesComponent({ elementInstance }: { elementInstance: FormElementInstance }) {
  const element = elementInstance as CustomInstance;
  const { updateElement, elements } = useDesigner();
  const form = useForm<Properties>({
    resolver: zodResolver(propertiesSchema),
    mode: 'onBlur',
    defaultValues: {
      ...element.extraAttributes,
      id: element.id,
    },
  });

  useEffect(() => {
    form.reset({ ...element.extraAttributes, id: element.id });
  }, [element, form]);

  function applyChanges(values: Properties) {
    updateElement(element.id, {
      ...element,
      extraAttributes: values,
    });
  }

  return (
    <FormProvider {...form}>
      <form onBlur={form.handleSubmit(applyChanges)} className="flex flex-col gap-4">
        <Flex direction="column" gap="4">
          <Controller
            control={form.control}
            name="parentId"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Parent</FormLabel>
                  <Select {...field} value={field.value ?? ''}>
                    <option value="">Select an option</option>
                    {elements
                      .filter((el) => el.id !== element.id)
                      .filter((el) => el.extraAttributes.type === 'group')
                      .map((element) => (
                        <option key={element.id} value={element.id}>
                          {element.extraAttributes.title}
                        </option>
                      ))}
                  </Select>
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="title"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Title</FormLabel>
                  <Textarea {...field} value={field.value ?? ''} />
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="description"
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Description</FormLabel>
                  <Textarea {...field} value={field.value ?? ''} />
                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="visibility"
            render={({ field }) => {
              return (
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="visibility" mb="0">
                    Is visible?
                  </FormLabel>
                  <Switch
                    id="visibility"
                    isChecked={field.value === 'visible'}
                    onChange={(event) =>
                      form.setValue(field.name, event.target.checked ? 'visible' : 'hidden', {
                        shouldValidate: true,
                      })
                    }
                  />
                </FormControl>
              );
            }}
          />

          <Controller
            control={form.control}
            name="publicVisibility"
            render={({ field }) => {
              return (
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor={field.name} mb="0">
                    Show in customer report?
                  </FormLabel>
                  <Switch
                    id={field.name}
                    isChecked={field.value === 'visible'}
                    onChange={(event) =>
                      form.setValue(field.name, event.target.checked ? 'visible' : 'hidden', {
                        shouldValidate: true,
                      })
                    }
                  />
                </FormControl>
              );
            }}
          />
        </Flex>
      </form>
    </FormProvider>
  );
}
