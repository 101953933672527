import { RadioCard } from '@/components/radio-card.tsx';
import { Box, FormControl, FormErrorMessage, FormHelperText, FormLabel, GridItem, VStack } from '@chakra-ui/react';
import type { RadioFieldProperties } from '@fieldbrick/core/db/schemas/fields.js';
import { RadioGroup } from '@headlessui/react';
import { capitalize, compact } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import type { InspectionField } from '../../lib/schema.js';

export const RadioField = ({ block }: InspectionField) => {
  const { control } = useFormContext<Record<string, { type: 'text'; value?: string }>>();
  const { design, options } = block.properties as RadioFieldProperties;
  const layout = design?.layout;
  const { description } = block;
  const fieldName = `${block.id}.value` as const;
  const choices = options;

  return (
    <GridItem colSpan={compact([12, layout?.columns])} key={block.id}>
      <Controller
        name={`${block.id}.type` as const}
        control={control}
        render={({ field: { value: _, ...field } }) => <input type="hidden" {...field} value="radio" />}
      />

      <Controller
        name={fieldName}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const hasError = !!error?.message;
          const sortedChoices = choices.toSorted((a, b) => a.id.localeCompare(b.id));
          return (
            <FormControl className="flex flex-col gap-2" isInvalid={hasError} isRequired={true}>
              <FormLabel htmlFor={fieldName} className="!font-semibold tracking-tight !text-lg">
                {capitalize(block.title)}
              </FormLabel>

              <VStack alignItems="normal" spacing={4}>
                <RadioGroup value={field.value ?? ''} onChange={field.onChange}>
                  <Box className="gap-4 grid grid-cols-12">
                    {sortedChoices.map((choice) => (
                      <Box className="flex-1 flex flex-col gap-3 col-span-6 sm:col-span-4" key={choice.id}>
                        <RadioCard value={choice.title}>
                          <span className="font-semibold block">{choice.title}</span>
                        </RadioCard>
                        {description ? (
                          <FormHelperText className="text-sm font-semibold text-gray-600">
                            {capitalize(description)}
                          </FormHelperText>
                        ) : null}
                      </Box>
                    ))}
                  </Box>
                </RadioGroup>

                {hasError ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
              </VStack>
            </FormControl>
          );
        }}
      />
    </GridItem>
  );
};
