import { PageLayout } from '@/components/page-layout';
import { useReplicache } from '@/components/providers/replicache.tsx';
import { InspectionStore } from '@/data/inspections.js';
import { useParams } from 'react-router-dom';
import { useSubscribe } from 'replicache-react';
import { InspectionForm } from './components/inspection-form.tsx';

export function InspectionPage() {
  const replicache = useReplicache();
  const { projectId, inspectionId } = useParams();
  const inspection = useSubscribe(replicache, async (tx) => {
    if (!(projectId && inspectionId)) {
      return null;
    }
    return InspectionStore.get(tx, { projectId, id: inspectionId });
  });

  if (!inspection) {
    return null;
  }

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Title>Inspection</PageLayout.Title>
      </PageLayout.Header>

      <PageLayout.Content>
        <InspectionForm inspection={inspection} />
      </PageLayout.Content>
    </PageLayout>
  );
}
