import { PageLayout } from '@/components/page-layout';
import { trpc } from '@/lib/trpc';
import { traverse } from '@/pages/projects/inspection/lib/traversal';
import { renderResponse } from '@/pages/projects/inspection/summary';
import {
  Avatar,
  Box,
  Card,
  CardBody,
  CardHeader,
  Container,
  Divider,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { keyBy, startCase, template } from 'lodash-es';
import { DateTime } from 'luxon';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

export function PublicInspectionsPage() {
  const { projectId } = useParams();
  const [project] = trpc.projects.report.view.useSuspenseQuery({ projectId });
  const { customer, customerAddress, organization, inspections, createdAt, photos } = project;

  const projectCoverPhoto = photos.find((p) => p.isCoverPhoto)!;
  const disclaimer = organization.disclaimers[0];
  const disclaimerTemplate = disclaimer ? template(disclaimer.text) : () => '';
  return (
    <>
      <Helmet>
        <title>Fieldbrick | Inspection Report</title>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>

      <Container maxW="container.lg" py="6" px="6">
        <PageLayout>
          <PageLayout.Header className="bg-black pt-4 pb-4 pl-6 pr-6 rounded-md">
            <Box className="flex flex-col gap-4 w-full ">
              <Box className="flex items-start w-full h-24 relative justify-between">
                {organization.branding?.logoUrl ? (
                  <Box className="flex-1 w-full">
                    <Image
                      boxSize="100px"
                      src={organization.branding.logoUrl}
                      alt={organization.name}
                      className="object-scale-down flex-1"
                    />
                  </Box>
                ) : null}

                <Box className="text-right w-full flex-1 text-white">
                  <Text fontWeight="bold" className="text-md">
                    {organization.officePhoneNumber}
                  </Text>
                  <Text fontWeight="bold" className="text-md">
                    {organization.officeEmailAddress}
                  </Text>
                  <Text fontWeight="bold" className="text-md capitalize">
                    {organization.primaryStreet} {organization.secondaryStreet}
                  </Text>
                  <Text fontWeight="bold" className="text-md capitalize">
                    {customerAddress.city}, <span className="uppercase">{customerAddress.state}</span>{' '}
                    {customerAddress.zipCode}
                  </Text>
                </Box>
              </Box>
            </Box>
          </PageLayout.Header>

          <PageLayout.Content>
            <Box className="w-full flex justify-center items-center rounded-md">
              <Image src={projectCoverPhoto.url} alt={organization.name} className="rounded-md" />
            </Box>
            <div className="flex justify-center -mt-36">
              <Card size="lg" align="center " className="w-5/6">
                <CardHeader className="bg-black text-white rounded-t-md w-full flex justify-center">
                  <Heading size="lg">Inspection Report</Heading>
                </CardHeader>
                <CardBody>
                  <Text fontWeight="bold" fontSize="x-large" className="text-center capitalize">
                    {customerAddress.primaryStreet} {customerAddress.secondaryStreet} {customerAddress.city},{' '}
                    <span className="uppercase">{customerAddress.state}</span> {customerAddress.zipCode}
                  </Text>
                  <Text fontWeight="bold" fontSize="x-large">
                    Date of Inspection: {''}
                    {DateTime.fromSQL(createdAt).toLocaleString(DateTime.DATE_FULL)}
                  </Text>
                  <Text fontWeight="bold" fontSize="x-large" className="text-center">
                    Prepared for: {startCase([customer.firstName, customer.lastName].join(' '))}
                  </Text>
                </CardBody>
              </Card>
            </div>
            <Box className="pt-20">
              <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow="hidden"
                variant="outline"
                className="p-4 border-none shadow-none"
              >
                {project.createdBy.profileImageUrl ? (
                  <Image
                    objectFit="cover"
                    maxW={{ base: '100%', sm: '200px' }}
                    src={project.createdBy.profileImageUrl}
                    alt={project.createdBy.firstName}
                    className="rounded-md"
                  />
                ) : (
                  <Avatar name={project.createdBy.firstName} size="lg" />
                )}

                <Stack>
                  <CardBody>
                    <Text fontSize="md" fontWeight="bold">
                      Inspected by:
                    </Text>

                    <Text fontWeight="bold" fontSize="lg" className="pt-2 capitalize">
                      {project.createdBy.firstName} {project.createdBy.lastName}
                    </Text>
                  </CardBody>
                </Stack>
              </Card>
            </Box>
            <Divider />

            {disclaimer ? (
              <div className="flex justify-center pt-16 pb-16">
                <Card size="lg" align="center">
                  <CardHeader className="bg-black text-white rounded-t-md w-full flex justify-center">
                    <Heading size="md">Legal Disclaimer</Heading>
                  </CardHeader>
                  <CardBody>
                    <Text
                      className="text-left"
                      fontWeight="semibold"
                      dangerouslySetInnerHTML={{
                        __html: disclaimerTemplate({
                          organizationName: organization.name,
                          customerName: startCase([customer.firstName, customer.lastName].join(' ')),
                        }),
                      }}
                    />
                  </CardBody>
                </Card>
              </div>
            ) : null}

            <Box className="flex flex-col gap-4">
              {inspections.map((inspection) => {
                const form = inspection.forms.at(0)!;
                const fieldsById = keyBy(form.fields, 'id');
                const responsesByFieldId = keyBy(inspection.responses, 'fieldId');
                const fieldTree = traverse(form.fields);
                return (
                  <Box key={inspection.id} className="flex flex-col gap-4">
                    <h3 className="text-4xl font-semibold tracking-tighter">{form.name}</h3>
                    {fieldTree.map((field) => renderResponse(field, 0, responsesByFieldId, fieldsById))}
                  </Box>
                );
              })}
            </Box>
          </PageLayout.Content>
        </PageLayout>
      </Container>
    </>
  );
}
