import { relations } from 'drizzle-orm';
import { pgTable, timestamp, varchar } from 'drizzle-orm/pg-core';
import { createSelectSchema } from 'drizzle-zod';
import type { z } from 'zod';
import { id } from '../../util/id';
import { timestamps } from '../../util/sql';
import { orgMemberships } from './organizations';

export const users = pgTable('users', {
  id: varchar('id')
    .primaryKey()
    .$defaultFn(() => id('user')),
  firstName: varchar('first_name').notNull(),
  lastName: varchar('last_name').notNull(),
  emailAddress: varchar('email_address').notNull().unique(),
  deactivatedAt: timestamp('deactivated_at', { precision: 0, withTimezone: true, mode: 'string' }),
  profileImageUrl: varchar('profile_image_url'),
  ...timestamps(),
});

export const admins = pgTable('admins', {
  id: varchar('id')
    .primaryKey()
    .$defaultFn(() => id('admin')),
  fullName: varchar('full_name').notNull(),
  emailAddress: varchar('email_address').notNull().unique(),
  ...timestamps(),
});

export const userRelations = relations(users, ({ many }) => ({
  memberships: many(orgMemberships),
}));

export const User = createSelectSchema(users);
export const Admin = createSelectSchema(admins);
export type User = z.infer<typeof User>;
export type Admin = z.infer<typeof Admin>;
