import { Box, Link as ChakraLink, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import type { CustomerAddress } from '@fieldbrick/core/db/schema/customers';
import type { Project } from '@fieldbrick/core/db/schema/projects';
import { DateTime } from 'luxon';
import { Link as ReactRouterLink } from 'react-router-dom';

export function CustomerProjects({ projects }: { projects: (Project & { customerAddress: CustomerAddress })[] }) {
  return (
    <Box className="flex flex-col gap-4">
      <Box>
        <div className="font-semibold leading-none tracking-tight">
          <h2 className="text-2xl font-medium tracking-tighter">Projects</h2>
        </div>
      </Box>

      <Box>
        <TableContainer className="w-full">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Service Address</Th>
                <Th>Started On</Th>
              </Tr>
            </Thead>
            <Tbody>
              {projects.map((project) => (
                <Tr key={project.id}>
                  <Td>
                    <ChakraLink
                      as={ReactRouterLink}
                      to={`/org/${project.organizationId}/projects/${project.id}`}
                      className="capitalize text-lg tracking-tight underline-offset-4"
                    >
                      {project.customerAddress.primaryStreet}
                    </ChakraLink>
                  </Td>

                  <Td className="font-normal capitalize">
                    {DateTime.fromISO(project.createdAt, { zone: 'utc' }).setZone('local').toLocaleString()}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
