import { Customer, CustomerAddress } from '@fieldbrick/core/db/schema/customers';
import { z } from 'zod';

export const newProjectSchema = z.object({
  customer: Customer.extend({ createdAt: z.string().optional(), updatedAt: z.string().optional() }),
  customerAddress: CustomerAddress.extend({ createdAt: z.string().optional(), updatedAt: z.string().optional() }),
  templates: z.object({ templateId: z.string() }).array().min(1, 'Please select at least one template'),
  coverPhoto: z.object(
    {
      id: z.string(),
      encoded: z.string(),
      contentType: z.string(),
      fileName: z.string(),
      size: z.number(),
    },
    { invalid_type_error: 'Please select a cover photo' },
  ),
});

export type NewProjectFormValues = z.infer<typeof newProjectSchema>;
