import { PageLayout } from '@/components/page-layout';
import { trpc } from '@/lib/trpc';
import { Button, Card, CardBody, Container, Flex, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function AdminLoginPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const { mutate: sendMagicLink, isPending } = trpc.admin.auth.magicLink.send.useMutation({
    onSuccess({ authRequestId }) {
      navigate({
        pathname: '/admin/login/verify',
        search: new URLSearchParams({ emailAddress, authRequestId }).toString(),
      });
      toast({ title: 'Magic link sent', status: 'success', duration: 5000, isClosable: true, position: 'top' });
      setEmailAddress('');
    },
    onError() {
      setEmailAddress('');
      toast({
        title: 'Error sending magic link. You are probably not an admin.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    },
  });

  const [emailAddress, setEmailAddress] = useState('');

  return (
    <PageLayout className="h-screen flex flex-col items-center justify-center">
      <PageLayout.Content>
        <Container maxW="md" py="6">
          <Card className="w-full shadow-md" variant="outline">
            <CardBody>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  sendMagicLink({ emailAddress });
                }}
              >
                <FormControl className="flex flex-col gap-4">
                  <Flex direction="column">
                    <FormLabel>Email address</FormLabel>
                    <Input
                      type="email"
                      placeholder="email@example.com"
                      onChange={(event) => setEmailAddress(event.target.value)}
                      value={emailAddress}
                    />
                  </Flex>
                  <Button colorScheme="blue" type="submit" isLoading={isPending}>
                    Continue
                  </Button>
                </FormControl>
              </form>
            </CardBody>
          </Card>
        </Container>
      </PageLayout.Content>
    </PageLayout>
  );
}
