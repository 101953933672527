import { trpc } from '@/lib/trpc';

type Props = {
  projectId: string | undefined;
  inspectionId: string | undefined;
};

export function useSummary({ projectId: _, inspectionId }: Props) {
  const [remoteSummary] = trpc.inspections.summary.view.useSuspenseQuery({ inspectionId });

  return remoteSummary;
}
