import type { WriteTransaction } from 'replicache';
import type { ZodSchema, z } from 'zod';

type Mutation<Name extends string = string, Input = any> = {
  name: Name;
  input: Input;
};

export class ReplicacheServer<Mutations> {
  mutations = new Map<
    string,
    {
      input: ZodSchema;
      fn: (input: any) => Promise<void>;
    }
  >();

  mutation<Name extends string, Shape extends ZodSchema, Args = z.infer<Shape>>(
    name: Name,
    shape: Shape,
    fn: (input: z.infer<Shape>) => Promise<any>,
  ): ReplicacheServer<Mutations & { [key in Name]: Mutation<Name, Args> }> {
    this.mutations.set(name as string, {
      fn: async (args) => {
        const parsed = args;
        return fn(parsed);
      },
      input: shape,
    });
    return this;
  }

  expose<Name extends string, Shape extends ZodSchema, Args = z.infer<Shape>>(
    name: Name,
    fn: ((input: z.infer<ZodSchema>) => Promise<any>) & {
      schema: Shape;
    },
  ): ReplicacheServer<Mutations & { [key in Name]: Mutation<Name, Args> }> {
    this.mutations.set(name as string, {
      fn,
      input: fn.schema,
    });
    return this;
  }

  public execute(name: string, args: unknown) {
    const mut = this.mutations.get(name as string);
    if (!mut) {
      throw new Error(`Mutation "${name}" not found`);
    }
    return mut.fn(args);
  }
}

export type ExtractMutations<S extends ReplicacheServer<any>> = S extends ReplicacheServer<infer M> ? M : never;
export type ExtractMutationFunctions<
  S extends ReplicacheServer<any>,
  Mutations extends ExtractMutations<S> = ExtractMutations<S>,
> = {
  [K in keyof Mutations]: (tx: WriteTransaction, input: Mutations[K]['input']) => Promise<void>;
};

export class ReplicacheClient<
  S extends ReplicacheServer<any>,
  Mutations extends Record<string, Mutation> = ExtractMutations<S>,
  UsedMutations extends Record<string, Mutation> = ExtractMutations<S>,
> {
  mutations = new Map<
    keyof Mutations,
    (tx: WriteTransaction, input: Mutations[keyof Mutations]['input']) => Promise<void>
  >();

  mutation<Name extends keyof UsedMutations>(
    name: Name,
    fn: (tx: WriteTransaction, input: UsedMutations[Name]['input']) => Promise<void>,
  ) {
    this.mutations.set(name as string, fn);
    // If the mutation is used, we want to omit it from the list of available mutations
    return this as unknown as ReplicacheClient<S, Mutations, Omit<UsedMutations, Name>>;
  }

  build(): {
    [key in keyof Mutations]: (ctx: WriteTransaction, args: Mutations[key]['input']) => Promise<void>;
  } {
    return Object.fromEntries(this.mutations.entries()) as any;
  }
}
