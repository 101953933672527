import type { FormField } from '@fieldbrick/core/db/schema/forms';
import type { InspectionResponse } from '@fieldbrick/core/db/schema/inspections';

type Props = {
  fields: FormField[];
};

export function useDefaultValues({ fields }: Props) {
  const filteredFields = fields.filter((field) => field.type !== 'group');
  const fieldsWithoutResponses = filteredFields.reduce(
    (acc, field) => {
      const parsed = field;
      acc[field.id] = { type: 'text', value: '' };

      if (parsed.type === 'multiple_choice' || parsed.type === 'select') {
        acc[field.id] = { type: parsed.type, value: [] };
      }

      if (parsed.type === 'file_upload') {
        acc[field.id] = { type: 'file_upload', value: [] };
      }

      if (parsed.type === 'radio') {
        acc[field.id] = { type: 'radio', value: '' };
      }

      return acc;
    },
    {} as Record<string, InspectionResponse['response']>,
  );

  return { fieldsWithoutResponses };
}
