import { z } from 'zod';
import { Roles } from '../../constants';

export const authSessionSchema = z.object({
  sessionId: z.string(),
  userId: z.string(),
  organizationId: z.string(),
  role: z.enum([Roles.OWNER, Roles.TECH, Roles.OFFICE]),
});
export type AuthSession = z.infer<typeof authSessionSchema>;
