import { trpc } from '@/lib/trpc';
import {
  Box,
  Button,
  Card,
  CardBody,
  Link as ChakraLink,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

type Props = {
  organizationId: string;
};

export function TemplateList({ organizationId }: Props) {
  const [templates] = trpc.admin.organizations.templates.all.useSuspenseQuery({ organizationId });
  const navigate = useNavigate();
  const { mutate: createTemplate, isPending } = trpc.admin.organizations.templates.create.useMutation({
    onSuccess({ id }) {
      onToggle();
      navigate(`/admin/organizations/${organizationId}/templates/${id}`);
    },
  });

  const { isOpen, onToggle } = useDisclosure();
  const [templateName, setTemplateName] = useState('');

  return (
    <Box className="flex flex-col gap-4">
      <Box className="ml-auto">
        <Button onClick={onToggle}>New template</Button>
      </Box>

      {templates.map((template) => (
        <Card key={template.id} variant="outline">
          <CardBody>
            <Link to={`/admin/organizations/${organizationId}/templates/${template.id}`}>
              <ChakraLink>{template.name}</ChakraLink>
            </Link>
          </CardBody>
        </Card>
      ))}

      <Modal isOpen={isOpen} onClose={onToggle} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h3>Create new template</h3>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <FormControl>
              <FormLabel>Template name</FormLabel>
              <Input value={templateName} onChange={(event) => setTemplateName(event.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              isLoading={isPending}
              onClick={() => createTemplate({ name: templateName, organizationId })}
              isDisabled={!templateName.trim()}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
