import type { FormField } from '@fieldbrick/core/db/schema/forms';
import type { InspectionResponse } from '@fieldbrick/core/db/schema/inspections';
import type { FormRule } from '@fieldbrick/core/db/schema/rules';
import { zodResolver } from '@hookform/resolvers/zod';
import { diff } from 'deep-object-diff';
import { pipe } from 'effect';
import { isEmpty, isUndefined, keyBy, omitBy } from 'lodash-es';
import { create } from 'mutative';
import { useEffect, useState } from 'react';
import { type WatchObserver, useForm } from 'react-hook-form';
import { match } from 'ts-pattern';
import { evaluateConditions } from '../lib/rules';
import { traverse } from '../lib/traversal';
import { buildValidationSchema } from '../lib/validation';
import { useDefaultValues } from './use-default-values';

type Props = {
  formFields: FormField[];
  formRules: FormRule[];
};

type Responses = Record<string, InspectionResponse['response']>;

/**
 * Hook to handle the logic for rendering inspection fields and rules.
 */
export function useInspection({ formFields: initialFormFields, formRules: initialFormRules }: Props) {
  const { fieldsWithoutResponses } = useDefaultValues({ fields: initialFormFields });
  const resolver = zodResolver(buildValidationSchema(initialFormFields));

  const form = useForm({
    resolver,
    defaultValues: fieldsWithoutResponses,
    shouldUnregister: true,
  });

  const [formFields, setFormFields] = useState<FormField[]>(initialFormFields);
  const [formRules] = useState<FormRule[]>(initialFormRules);
  const fieldTree = traverse(formFields);
  console.log({ fieldTree, formRules });

  const evaluateRules = (values: Responses) => {
    const fieldUpdates = create(formFields, (draft) => {
      const fieldsById = keyBy(draft, 'id');
      for (const rule of formRules) {
        const conditionsEvaluatedToTrue = pipe(rule.definition, evaluateConditions(values));
        for (const action of rule.actions) {
          match(action)
            .with({ type: 'show' }, ({ reference }) => {
              const _field = fieldsById[reference.id];
              if (_field) {
                _field.visibility = conditionsEvaluatedToTrue ? 'visible' : 'hidden';
              }
            })
            .exhaustive();
        }
      }
    });

    if (!isEmpty(diff(formFields, fieldUpdates))) {
      setFormFields(structuredClone(fieldUpdates));
    }
  };

  const ruleEvaluator: WatchObserver<Responses> = (values, { name, type }) => {
    console.log({ name, type, values });
    if (!name) {
      return;
    }
    const [fieldId] = name.split('.');
    if (!fieldId) {
      return;
    }

    const field = values[fieldId];
    if (!field) {
      return;
    }

    if (type === 'change' && ['multiple_choice', 'radio'].includes(field.type!)) {
      const responses = omitBy(structuredClone(values), isUndefined) as Responses;
      evaluateRules(responses);
    }
  };

  useEffect(() => {
    const { unsubscribe } = form.watch(ruleEvaluator);
    return unsubscribe;
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  }, [form.watch, ruleEvaluator]);

  return { form, fieldTree };
}
