import { Box } from '@chakra-ui/react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { useWindowSize } from '@uidotdev/usehooks';
import classNames from 'classnames';
import { SquareGanttChartIcon, UserIcon } from 'lucide-react';
import { Fragment, createContext, useCallback, useState } from 'react';
import { use } from 'react';
import { NavLink } from 'react-router-dom';
import { cn } from '../lib/cn';
import { useAuth } from './providers/auth';

const SidebarContext = createContext<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  open: false,
  setOpen: () => {},
});

export const SidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const windowSize = useWindowSize();
  const isDesktop = windowSize.width ? windowSize.width >= 1024 : true;

  const [showDesktopSidebar, setShowDesktopSidebar] = useState(true);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);

  const open = isDesktop ? showDesktopSidebar : showMobileSidebar;
  const setOpen = () => {
    if (isDesktop) {
      setShowDesktopSidebar((open) => !open);
    } else {
      setShowMobileSidebar((open) => !open);
    }
  };
  return <SidebarContext.Provider value={{ open, setOpen }}>{children}</SidebarContext.Provider>;
};

export const useSidebarState = () => {
  const context = use(SidebarContext);
  if (!context) {
    throw new Error('useSidebarState must be used within a <SidebarProvider>');
  }
  return context;
};

function SidebarItem({ children, url }: { children: React.ReactNode; url: string }) {
  return (
    <NavLink to={url}>
      {({ isActive }) => (
        <div
          className={cn(
            'duration-50 flex items-center justify-between gap-4 rounded-lg px-3 py-2 leading-normal text-white',
            {
              'bg-slate-200 text-black': isActive,
              'hover:text-slate text-slate-900': !isActive,
            },
          )}
        >
          <div className="flex items-center gap-2">{children}</div>
        </div>
      )}
    </NavLink>
  );
}

function SidebarItems() {
  const session = useAuth();

  return (
    <div className="flex flex-col gap-2">
      <SidebarItem url={`/org/${session.organizationId}/projects`}>
        <Box className="flex gap-2 items-center">
          <SquareGanttChartIcon />
          <span className="text-lg font-semibold">Projects</span>
        </Box>
      </SidebarItem>
      <SidebarItem url={`/org/${session.organizationId}/customers`}>
        <Box className="flex gap-2 items-center">
          <UserIcon />
          <span className="text-lg font-semibold">Customers</span>
        </Box>
      </SidebarItem>
    </div>
  );
}

export const Sidebar = () => {
  const windowSize = useWindowSize();
  const isDesktop = windowSize.width ? windowSize.width >= 1024 : true;
  const { open, setOpen } = useSidebarState();
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  if (isDesktop) {
    return (
      <Transition show={open} as={Fragment}>
        <TransitionChild
          as={Fragment}
          enter="transition-[margin] duration-200 ease-in-out"
          enterFrom="-ml-[18rem]"
          enterTo="ml-0"
          leave="transition-[margin] duration-200 ease-in-out"
          leaveFrom="ml-0"
          leaveTo="-ml-[18rem]"
        >
          <div
            className={classNames('h-full w-[18rem] shrink-0 overflow-auto border-r border-r-zinc-300 p-4 bg-zinc-50')}
            data-testid="desktop-sidebar"
          >
            <SidebarItems />
          </div>
        </TransitionChild>
      </Transition>
    );
  }

  return (
    <Transition appear={true} show={open} as={Fragment}>
      <Dialog as="div" onClose={handleClose} className="relative z-50" data-testid="mobile-sidebar">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/60 lg:hidden" aria-hidden="true" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out transform duration-200"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out transform duration-200"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <DialogPanel
                className={cn(
                  'fixed h-screen min-w-[18rem] max-w-[80%] transform overflow-y-auto border-r border-r-zinc-300 p-4 transition-all bg-zinc-50',
                )}
              >
                <SidebarItems />
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
