import { relations } from 'drizzle-orm';
import { foreignKey, index, integer, jsonb, pgTable, primaryKey, text, varchar } from 'drizzle-orm/pg-core';
import { createSelectSchema } from 'drizzle-zod';
import type { Simplify } from 'type-fest';
import { z } from 'zod';
import { timestamps } from '../../util/sql';
import { fieldProperties, fieldValidation } from '../schemas/fields';
import { fieldType, formStatus, ownershipType, visibilityType } from './enums';
import { inspectionResponseAttachments, inspectionResponses, inspections } from './inspections';
import { organizations } from './organizations';
import { formRules } from './rules';

export const forms = pgTable(
  'forms',
  {
    id: varchar('id', { length: 256 }).notNull(),
    name: varchar('name').notNull(),
    /**
     * A type allows us to distinguish between inspection templates
     * and inspection forms. Inspection templates are used to create
     * inspection forms. Inspection forms are used to collect data.
     */
    ownershipType: ownershipType('ownership_type').notNull(),
    status: formStatus('status').notNull().default('draft'),
    version: integer('version').notNull().default(1),
    organizationId: varchar('organization_id', { length: 256 })
      .notNull()
      .references(() => organizations.id),
    inspectionId: varchar('inspection_id', { length: 256 }),
    ...timestamps(),
  },
  (t) => ({
    primary: primaryKey({ columns: [t.organizationId, t.id] }),
    inspectionIdIndex: index().on(t.organizationId, t.inspectionId),
    inspectionFk: foreignKey({
      columns: [t.organizationId, t.inspectionId],
      foreignColumns: [inspections.organizationId, inspections.id],
    }).onDelete('cascade'),
  }),
);

export const formFields = pgTable(
  'fields',
  {
    id: varchar('id', { length: 256 }).notNull(),
    type: fieldType('type').notNull(),
    ownershipType: ownershipType('ownership_type').notNull(),
    version: integer('version').notNull().default(1),
    title: varchar('title', { length: 255 }).notNull(),
    description: text('description').default(''),
    visibility: visibilityType('visibility').notNull().default('visible'),
    publicVisibility: visibilityType('public_visibility').notNull().default('hidden'),
    validation: jsonb('validation').notNull().default({}),
    properties: jsonb('properties').notNull().default({}),
    order: integer('order').notNull().default(0),
    formId: varchar('form_id', { length: 256 }).notNull(),
    organizationId: varchar('organization_id', { length: 256 }).notNull(),
    parentId: varchar('parent_id', { length: 256 }),
    ...timestamps(),
  },
  (t) => ({
    pk: primaryKey({ columns: [t.organizationId, t.id] }),
    parentIdIndex: index().on(t.organizationId, t.parentId),
    formIndex: index().on(t.organizationId, t.formId),
    formIdFk: foreignKey({
      columns: [t.organizationId, t.formId],
      foreignColumns: [forms.organizationId, forms.id],
    }).onDelete('cascade'),
    parentIdFk: foreignKey({
      columns: [t.organizationId, t.parentId],
      foreignColumns: [t.organizationId, t.id],
    }),
    ownershipTypeIndex: index().on(t.ownershipType),
  }),
);

export const formRelations = relations(forms, ({ many, one }) => ({
  fields: many(formFields),
  rules: many(formRules),
  inspection: one(inspections, {
    fields: [forms.inspectionId],
    references: [inspections.id],
  }),
  attachments: many(inspectionResponseAttachments),
}));

export const fieldRelations = relations(formFields, ({ one }) => ({
  parent: one(formFields, {
    fields: [formFields.parentId],
    references: [formFields.id],
  }),
  form: one(forms, {
    fields: [formFields.organizationId, formFields.formId],
    references: [forms.organizationId, forms.id],
  }),
  response: one(inspectionResponses, {
    fields: [formFields.id],
    references: [inspectionResponses.fieldId],
  }),
}));

export const Form = createSelectSchema(forms);
export const FormField = createSelectSchema(formFields, {
  properties: () => fieldProperties,
  validation: () => fieldValidation,
  parentId: () =>
    z
      .string()
      .nullable()
      .transform((value) => value || null),
});

export type Form = Simplify<z.infer<typeof Form>>;
export type FormField = Simplify<z.infer<typeof FormField>>;
