import { PageLayout } from '@/components/page-layout';
import { trpc } from '@/lib/trpc';
import { Link as ChakraLink, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import { Link as ReactRouterLink } from 'react-router-dom';

export function CustomersPage() {
  const [customers] = trpc.customers.all.useSuspenseQuery();
  return (
    <PageLayout>
      <PageLayout.Header>
        <Helmet>
          <title>Fieldbrick | Customers</title>
        </Helmet>
        <PageLayout.Title>Customers</PageLayout.Title>
      </PageLayout.Header>

      <PageLayout.Content>
        <TableContainer className="w-full border-2 border-slate-100 rounded-sm">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Customer</Th>
                <Th>Email Address</Th>
                <Th isNumeric={true}>Phone Number</Th>
              </Tr>
            </Thead>
            <Tbody>
              {customers.map((customer) => (
                <Tr key={customer.id}>
                  <Td>
                    <ChakraLink
                      as={ReactRouterLink}
                      to={customer.id}
                      relative="path"
                      className="capitalize font-semibold"
                    >
                      {customer.firstName} {customer.lastName}
                    </ChakraLink>
                  </Td>
                  <Td>{customer.emailAddress}</Td>
                  <Td isNumeric={true}>{customer.phoneNumber}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </PageLayout.Content>
    </PageLayout>
  );
}
