import { useAuth } from '@/components/providers/auth.tsx';
import { useReplicache } from '@/components/providers/replicache.tsx';
import { toBase64 } from '@/lib/files.ts';
import {
  AspectRatio,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  IconButton,
  Image,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import type { Customer, CustomerAddress } from '@fieldbrick/core/db/schema/customers.js';
import { id } from '@fieldbrick/core/util/id.js';
import { countBy, keyBy, map } from 'lodash-es';
import { CirclePlusIcon, ImagesIcon, MinusIcon, PlusIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import { useRef } from 'react';
import { type SubmitHandler, useFieldArray, useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { type Template, buildInspectionsFromTemplates } from '../domain/builder.js';
import type { NewProjectFormValues } from '../schema.js';
import { CustomerSearch } from './customer-search.tsx';
import { useNewProjectContext } from './new-project-context.tsx';

type Props = {
  templates: Template[];
  customers: Customer[];
  addresses: CustomerAddress[];
  customerId: string | null;
};

export function NewProjectForm({ addresses, templates, customers, customerId }: Props) {
  const { control, handleSubmit, setValue } = useFormContext<NewProjectFormValues>();
  const { setState } = useNewProjectContext();
  const addressesById = keyBy(addresses, 'id');
  const navigate = useNavigate();
  const templatesById = keyBy(templates, 'id');
  const replicache = useReplicache();
  const ref = useRef<HTMLInputElement>(null);

  const { organizationId, userId } = useAuth();
  const onSubmit: SubmitHandler<NewProjectFormValues> = async (values) => {
    const projectId = id('proj');
    const templates = map(values.templates, 'templateId').map((templateId) => templatesById[templateId]!);
    const inspectionData = buildInspectionsFromTemplates(projectId, templates, organizationId);
    const forms = inspectionData.map((_) => _.form);
    const formFields = forms.flatMap((_) => _.fields);
    const formRules = forms.flatMap((_) => _.rules);
    const inspections = inspectionData.map((_) => _.inspection);
    const now = DateTime.utc().toSQL();
    const { customer, customerAddress } = values;
    const project = {
      id: projectId,
      customerId: customer.id,
      customerAddressId: customerAddress.id,
      organizationId,
      createdAt: now,
      updatedAt: now,
      createdById: userId,
      persistedAt: null,
    };

    await replicache.mutate.createProject({
      project,
      inspections,
      forms,
      formFields,
      formRules,
      coverPhoto: values.coverPhoto,
    });

    navigate(`/org/${organizationId}/projects/${projectId}`);
  };

  const { append, remove, fields: selectedTemplates } = useFieldArray({ control, name: 'templates' });
  const selectedTemplateCounts = countBy(selectedTemplates, 'templateId');

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="4"
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      id="new-project-form"
    >
      <Controller
        control={control}
        name="customer"
        render={({ field, fieldState: { error } }) => {
          const hasError = !!error?.message;
          return (
            <FormControl className="flex flex-col gap-2" id={field.name} isInvalid={hasError}>
              <FormLabel htmlFor="customer" className="!font-semibold !tracking-tight !text-lg">
                Customer
              </FormLabel>
              <CustomerSearch onChange={field.onChange} value={field.value} customers={customers} />
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  variant="ghost"
                  leftIcon={<CirclePlusIcon className="size-4 text-current" />}
                  onClick={() => setState('addCustomer')}
                >
                  Add customer
                </Button>
              </Box>
            </FormControl>
          );
        }}
      />

      {customerId ? (
        <Controller
          control={control}
          name="customerAddress"
          render={({ field, fieldState: { error } }) => {
            const hasError = !!error?.message;
            return (
              <FormControl className="flex flex-col gap-2" id={field.name} isInvalid={hasError}>
                <FormLabel className="!font-semibold !tracking-tight !text-lg">Service address</FormLabel>
                <RadioGroup
                  value={field.value?.id ?? ''}
                  onChange={(value) => {
                    field.onChange(value ? addressesById[value] : null);
                  }}
                >
                  <Stack spacing={4} direction="column">
                    {addresses.map((address) => (
                      <Radio key={address.id} value={address.id}>
                        <Text size="4" fontWeight="semibold" className="capitalize">
                          {address.primaryStreet} {address.secondaryStreet}
                        </Text>
                        <Text className="capitalize">
                          {address.city}, <span className="uppercase">{address.state}</span> {address.zipCode}
                        </Text>
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>{error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}</Box>
                  <Button
                    variant="ghost"
                    leftIcon={<CirclePlusIcon className="size-4 text-current" />}
                    onClick={() => setState('addCustomerAddress')}
                  >
                    Add address
                  </Button>
                </Box>
              </FormControl>
            );
          }}
        />
      ) : null}

      <Controller
        control={control}
        name="coverPhoto"
        render={({ field, fieldState: { error } }) => {
          const hasError = !!error?.message;
          return (
            <FormControl className="flex flex-col" id={field.name} isInvalid={hasError}>
              <FormLabel htmlFor="customer">
                <span className="!font-semibold !tracking-tight !text-lg block">Cover photo</span>
                <span className="block  text-gray-500 font-normal">
                  This photo will be used as the cover photo for the inspection report.
                </span>
              </FormLabel>

              {field.value ? (
                <Box className="relative">
                  <Box className="absolute top-0 right-2">
                    <Button
                      className="z-10 top-2 right-0 shadow-lg"
                      onClick={() => setValue('coverPhoto', null as any, { shouldValidate: true })}
                      size="sm"
                      colorScheme="red"
                    >
                      Delete
                    </Button>
                  </Box>

                  <AspectRatio ratio={16 / 9} className="w-full">
                    <Image className="cursor-pointer object-cover rounded-md" src={field.value.encoded} />
                  </AspectRatio>
                </Box>
              ) : (
                <Button
                  variant="link"
                  onClick={() => ref.current?.click()}
                  className="p-20 border border-dashed rounded-lg border-slate-400 flex-1 flex items-center justify-center cursor-pointer !h-full !min-h-[185px] !no-underline w-full"
                >
                  <Box className="flex flex-col items-center justify-center gap-3">
                    <ImagesIcon className="text-slate-600" />
                    <span className="text-sm font-semibold text-gray-600">Add photo</span>
                  </Box>
                </Button>
              )}

              <input
                type="file"
                capture="environment"
                accept="image/*"
                multiple={false}
                className="hidden"
                ref={ref}
                onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                  const files = Array.from(event.target.files ?? []);
                  const results = await Promise.all(
                    files.map(async (file) => {
                      const contents = await toBase64(file);
                      return {
                        id: id('attach'),
                        encoded: contents?.toString() ?? '',
                        fileName: file.name,
                        contentType: file.type,
                        size: file.size,
                      };
                    }),
                  );
                  const photo = results[0];
                  if (!photo) {
                    return;
                  }
                  setValue('coverPhoto', photo, { shouldValidate: true });
                }}
              />
              {hasError ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
            </FormControl>
          );
        }}
      />

      <Controller
        control={control}
        name="templates"
        render={({ field, fieldState: { error } }) => {
          const hasError = !!error?.message;
          return (
            <FormControl className="flex flex-col" id={field.name} isInvalid={hasError}>
              <FormLabel htmlFor="customer" className="text-lg">
                <span className="block">Inspection template</span>
                <span className="block text-sm text-gray-500 font-normal">
                  Add a template to start an inspection with. Each template will correspond to a separate inspection
                  form.
                </span>
              </FormLabel>

              <Box>
                <SimpleGrid columns={1} gap="8">
                  <Grid templateColumns="repeat(2, 1fr)" gap="4">
                    {templates.map((template, index) => (
                      <Card key={template.id} variant="outline">
                        <CardBody className="flex flex-col gap-4">
                          <Flex className="items-center gap-4">
                            <Avatar
                              name={template.name}
                              bg="gray.300"
                              className="!rounded-md !text-black !font-semibold !tracking-tighter !text-sm"
                            />
                            <Text className="font-semibold tracking-tight !text-lg">{template.name}</Text>
                          </Flex>
                          <Flex className="justify-end">
                            <ButtonGroup isAttached={true} variant="outline">
                              <IconButton aria-label="remove" icon={<MinusIcon />} onClick={() => remove(index)} />
                              <Button>{selectedTemplateCounts[template.id] ?? 0}</Button>
                              <IconButton
                                aria-label="add"
                                icon={<PlusIcon />}
                                onClick={() => append({ templateId: template.id })}
                              />
                            </ButtonGroup>
                          </Flex>
                        </CardBody>
                      </Card>
                    ))}
                  </Grid>
                </SimpleGrid>
              </Box>
              {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
            </FormControl>
          );
        }}
      />
    </Box>
  );
}
