import { trpc } from '@/lib/trpc';
import { Box, Card, CardBody, Flex, IconButton, useToast } from '@chakra-ui/react';
import { XCircleIcon } from 'lucide-react';

export function InviteList({ organizationId }: { organizationId: string }) {
  const [invitations, { refetch }] = trpc.admin.organizations.invitations.all.useSuspenseQuery(
    { organizationId },
    {
      refetchOnMount: 'always',
      refetchOnWindowFocus: 'always',
    },
  );

  const toast = useToast();
  const deleteInvitation = trpc.admin.organizations.invitations.delete.useMutation({
    async onSuccess() {
      await refetch();
      toast({ title: 'Invitation deleted', status: 'success' });
    },
  });

  return (
    <Box>
      {invitations.map((invitation) => (
        <Card key={invitation.id} variant="outline">
          <CardBody>
            <Flex alignItems="center" justify="space-between">
              <p>{invitation.invitationEmail}</p>
              <IconButton
                icon={<XCircleIcon />}
                aria-label="Remove"
                variant="ghost"
                isLoading={deleteInvitation.isPending}
                onClick={() => deleteInvitation.mutate({ id: invitation.id, organizationId })}
              />
            </Flex>
          </CardBody>
        </Card>
      ))}
    </Box>
  );
}
