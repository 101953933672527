import { Box } from '@chakra-ui/react';
import type { CustomerAddress } from '@fieldbrick/core/db/schema/customers.ts';
import { AddNewAddress } from './add-new-address.tsx';
import { UpdateCustomerAddress } from './update-customer-address.tsx';

export function CustomerAddresses({ addresses }: { addresses: CustomerAddress[] }) {
  return (
    <Box className="flex flex-col gap-4">
      <Box className="flex justify-between items-center">
        <h2 className="text-2xl font-medium tracking-tighter">Addresses</h2>
        <AddNewAddress />
      </Box>

      <Box className="flex flex-col gap-4 divide-y">
        {addresses.map((address) => (
          <UpdateCustomerAddress key={address.id} address={address} />
        ))}
      </Box>
    </Box>
  );
}
