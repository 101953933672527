import { toBase64 } from '@/lib/files.js';
import {
  AspectRatio,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  GridItem,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import { id } from '@fieldbrick/core/util/id.js';
import { capitalize, compact } from 'lodash-es';
import { EllipsisIcon, ImagesIcon } from 'lucide-react';
import { useRef, useState } from 'react';
import { Controller, type UseFieldArrayReturn, useFieldArray, useFormContext } from 'react-hook-form';
import type { InspectionField } from '../../lib/schema.js';

function ImageUpload({ append }: Pick<UseFieldArrayReturn, 'append'>) {
  const ref = useRef<HTMLInputElement>(null);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files ?? []);
    const results = await Promise.all(
      files.map(async (file) => {
        const contents = await toBase64(file);
        return {
          id: id('attach'),
          encoded: contents,
          fileName: file.name,
          contentType: file.type,
          size: file.size,
        };
      }),
    );

    append(results);
  };

  return (
    <>
      <Button
        variant="link"
        onClick={() => ref.current?.click()}
        className="p-20 border border-dashed rounded-lg border-slate-400 flex-1 flex items-center justify-center cursor-pointer !h-full !min-h-[185px] !no-underline w-full"
      >
        <Box className="flex flex-col items-center justify-center gap-3">
          <ImagesIcon className="text-slate-600" />
          <span className="text-sm font-semibold text-gray-600">Add photos</span>
        </Box>
      </Button>

      <input
        type="file"
        capture="environment"
        accept="image/*"
        multiple={true}
        className="hidden"
        ref={ref}
        onChange={handleChange}
      />
    </>
  );
}

export function FileUploadField({ block }: InspectionField) {
  const { control } =
    useFormContext<Record<string, { type: 'file_upload'; value?: ({ encoded: string } | { url: string })[] }>>();
  const { design } = block.properties;
  const layout = design?.layout;
  const { description } = block;
  const fieldName = `${block.id}.value` as const;

  const { fields: attachments, append, remove } = useFieldArray({ control, name: fieldName });
  const [previewAttachment, setPreviewAttachment] = useState<{
    contents: string;
    fileName: string;
    id: string;
  } | null>(null);

  return (
    <GridItem colSpan={compact([12, layout?.columns])} key={block.id}>
      <Controller
        name={`${block.id}.type` as const}
        control={control}
        render={({ field: { value: _, ...field } }) => <input type="hidden" {...field} value="file_upload" />}
      />

      <Controller
        name={fieldName}
        control={control}
        render={({ fieldState: { error } }) => {
          const hasError = !!error?.message;
          return (
            <FormControl isInvalid={hasError} isRequired={true} className="flex flex-col gap-4">
              <FormLabel htmlFor={fieldName} className="!font-semibold tracking-tight !text-lg" mb={0}>
                {capitalize(block.title)}
              </FormLabel>

              <Box className="grid grid-cols-12 gap-4 w-full">
                {attachments.map((attachment, index) => {
                  return (
                    <Box key={attachment.id} className="flex flex-col gap-2 col-span-12 sm:col-span-6">
                      <Box className="relative">
                        <Box className="absolute top-0 right-2">
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              className="z-10 top-2 right-0"
                              icon={<EllipsisIcon />}
                              isRound={true}
                            >
                              Actions
                            </MenuButton>
                            <MenuList>
                              <MenuItem className="text-sm font-semibold text-gray-600" onClick={() => remove(index)}>
                                Delete
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Box>
                        <AspectRatio ratio={4 / 3} className="w-full">
                          <Image
                            className="cursor-pointer object-cover rounded-md"
                            src={'encoded' in attachment ? attachment.encoded : attachment.url}
                          />
                        </AspectRatio>
                      </Box>
                    </Box>
                  );
                })}

                <Box className="col-span-12 sm:col-span-6 w-full">
                  <ImageUpload append={append} />
                </Box>
              </Box>

              {description ? (
                <FormHelperText className="text-sm font-semibold text-gray-600">
                  {capitalize(description)}
                </FormHelperText>
              ) : undefined}
              {hasError ? <FormErrorMessage>{error.message}</FormErrorMessage> : undefined}
            </FormControl>
          );
        }}
      />

      {previewAttachment ? (
        <Modal isOpen={!!previewAttachment} onClose={() => setPreviewAttachment(null)} size="full">
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Box className="relative w-full">
                <AspectRatio ratio={4 / 3}>
                  <Image
                    // objectFit="cover"
                    src={previewAttachment?.url ?? previewAttachment?.encoded}
                    alt={previewAttachment?.fileName}
                    className="object-contain"
                  />
                </AspectRatio>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button onClick={() => setPreviewAttachment(null)}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </GridItem>
  );
}
