import { FileUploadFieldFormElement } from './file-upload-field';
import { GroupFieldFormElement } from './group-field';
import { MeasurementFieldFormElement } from './measurement-field';
import { MultipleChoiceFieldFormElement } from './multiple-choice-field';
import { RadioFieldFormElement } from './radio-field';
import { RowFieldFormElement } from './row-field';
import { TextFieldFormElement } from './text-field';

export type ElementsType =
  | 'ShortTextField'
  | 'GroupField'
  | 'MultipleChoiceField'
  | 'FileUploadField'
  | 'MeasurementField'
  | 'RadioField'
  | 'RowField';

export type FormElementInstance = {
  id: string;
  type: ElementsType;
  extraAttributes: Record<string, any>;
  children: FormElementInstance[];
};

export const FormElements: Record<ElementsType, FormElement> = {
  ShortTextField: TextFieldFormElement,
  GroupField: GroupFieldFormElement,
  MultipleChoiceField: MultipleChoiceFieldFormElement,
  FileUploadField: FileUploadFieldFormElement,
  MeasurementField: MeasurementFieldFormElement,
  RadioField: RadioFieldFormElement,
  RowField: RowFieldFormElement,
};

export type FormElement = {
  type: ElementsType;
  construct: (id: string) => FormElementInstance;
  designerBtnElement: {
    icon: React.ElementType;
    label: string;
  };
  designerComponent: React.FC<{
    elementInstance: FormElementInstance;
  }>;
  propertiesComponent: React.FC<{
    elementInstance: FormElementInstance;
  }>;
};
