import type { InspectionResponse } from '@fieldbrick/core/db/schema/inspections';
import type { FormRule } from '@fieldbrick/core/db/schema/rules';
import { P, match } from 'ts-pattern';

const evaluateConditionOrRule =
  (data: Record<string, InspectionResponse['response'] | undefined>) =>
  (conditionOrRule: FormRule['definition']['rules'][number]): boolean =>
    match(conditionOrRule)
      .with({ type: P.union('multiple_choice') }, (rule) =>
        match(rule)
          .with({ operator: 'is' }, ({ reference, value }) => {
            const dataValue = (data[reference.id]?.value ?? []) as string[];
            return dataValue?.includes(value);
          })
          .exhaustive(),
      )
      .with({ type: P.union('radio') }, (rule) =>
        match(rule)
          .with({ operator: 'is' }, ({ reference, value }) => {
            const dataValue = data[reference.id]?.value;
            console.log({ dataValue, value });
            return dataValue === value;
          })
          .exhaustive(),
      )
      .exhaustive();
/**
 * Given a list of rules, evaluate them and return a boolean indicating whether
 * the actions should be executed.
 */
export function evaluateConditions(data: Record<string, InspectionResponse['response'] | undefined>) {
  return (definition: FormRule['definition']): boolean => {
    return match(definition)
      .with({ operator: 'and' }, ({ rules }) => rules.every(evaluateConditionOrRule(data)))
      .with({ operator: 'or' }, ({ rules }) => rules.some(evaluateConditionOrRule(data)))
      .exhaustive();
  };
}
