import { relations } from 'drizzle-orm';
import { foreignKey, index, pgTable, primaryKey, unique, varchar } from 'drizzle-orm/pg-core';
import { createSelectSchema } from 'drizzle-zod';

import type { Simplify } from 'type-fest';
import type { z } from 'zod';
import { timestamps } from '../../util/sql';
import { organizations } from './organizations';
import { projects } from './projects';

export const customers = pgTable(
  'customers',
  {
    id: varchar('id', { length: 256 }).notNull(),
    firstName: varchar('first_name').notNull(),
    lastName: varchar('last_name').notNull(),
    emailAddress: varchar('email_address'),
    phoneNumber: varchar('phone_number').notNull(),
    organizationId: varchar('organization_id', { length: 256 })
      .notNull()
      .references(() => organizations.id),
    ...timestamps(),
  },
  (t) => ({
    primaryKey: primaryKey({ columns: [t.organizationId, t.id] }),
    uniqueEmail: unique().on(t.organizationId, t.emailAddress),
    uniquePhoneNumber: unique().on(t.organizationId, t.phoneNumber),
  }),
);

export const customerAddresses = pgTable(
  'customer_addresses',
  {
    id: varchar('id', { length: 256 }).primaryKey(),
    primaryStreet: varchar('primary_street').notNull(),
    secondaryStreet: varchar('secondary_street'),
    city: varchar('city').notNull(),
    state: varchar('state').notNull(),
    zipCode: varchar('zip_code').notNull(),
    customerId: varchar('customer_id', { length: 256 }).notNull(),
    organizationId: varchar('organization_id', { length: 256 }).notNull(),
    ...timestamps(),
  },
  (t) => ({
    customerFk: foreignKey({
      columns: [t.organizationId, t.customerId],
      foreignColumns: [customers.organizationId, customers.id],
    }).onDelete('cascade'),
    customerFkIndex: index().on(t.organizationId, t.customerId),
    uniqueAddress: unique().on(t.organizationId, t.customerId, t.primaryStreet, t.secondaryStreet, t.zipCode),
  }),
);

export const customerRelations = relations(customers, ({ many }) => ({
  projects: many(projects),
  addresses: many(customerAddresses),
}));

export const customerAddressRelations = relations(customerAddresses, ({ one }) => ({
  customer: one(customers, {
    fields: [customerAddresses.customerId, customerAddresses.organizationId],
    references: [customers.id, customers.organizationId],
  }),
}));
const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
export const Customer = createSelectSchema(customers, {
  firstName: (schema) => schema.firstName.trim().toLowerCase().max(100),
  lastName: (schema) => schema.lastName.trim().toLowerCase().max(100),
  emailAddress: (schema) => schema.emailAddress.trim().email().toLowerCase().max(100),
  phoneNumber: (schema) =>
    schema.phoneNumber.trim().regex(phoneRegex, 'Please enter a valid phone number in the format XXX-XXX-XXXX'),
});
export const CustomerAddress = createSelectSchema(customerAddresses, {
  primaryStreet: (schema) => schema.primaryStreet.trim().toLowerCase(),
  secondaryStreet: (schema) => schema.secondaryStreet.trim().toLowerCase().default(''),
  city: (schema) => schema.city.trim().toLowerCase(),
  state: (schema) => schema.state.trim().toLowerCase(),
  zipCode: (schema) => schema.zipCode.trim().toLowerCase(),
});

export type Customer = Simplify<z.infer<typeof Customer>>;
export type CustomerAddress = Simplify<z.infer<typeof CustomerAddress>>;
