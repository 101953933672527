import type { z } from 'zod';
import { CustomerAddress } from '../../db/schema/customers';

export const upsertCustomerAddressInput = CustomerAddress.pick({
  id: true,
  primaryStreet: true,
  secondaryStreet: true,
  city: true,
  state: true,
  zipCode: true,
  customerId: true,
});

export type UpsertCustomerAddressInput = z.infer<typeof upsertCustomerAddressInput>;
