import { cn } from '@/lib/cn.ts';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  VStack,
  useCheckbox,
  useCheckboxGroup,
} from '@chakra-ui/react';
import type { MultipleChoiceFieldProperties } from '@fieldbrick/core/db/schemas/fields.ts';
import { capitalize, compact } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import type { InspectionField } from '../../lib/schema.js';

function CustomCheckbox(props) {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } = useCheckbox(props);

  return (
    <label
      className={cn(
        'cursor-pointer focus:outline-none col-span-12 md:col-span-6',
        'flex items-center justify-center gap-4 rounded-md bg-white p-3 text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset',
      )}
      {...htmlProps}
    >
      <input {...getInputProps()} hidden={true} />
      <Flex
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        borderColor="green.500"
        w={5}
        h={5}
        className="!rounded-full"
        {...getCheckboxProps()}
      >
        {state.isChecked && <Box w={2} h={2} bg="green.500" className="!rounded-full !size-2" />}
      </Flex>
      <span className="font-semibold block" {...getLabelProps()}>
        {props.value}
      </span>
    </label>
  );
}

function CheckboxGroup(props: {
  options: MultipleChoiceFieldProperties['options'];
  value: string[];
  onChange: (value: string[]) => void;
}) {
  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue: [],
    value: props.value,
    onChange: props.onChange,
  });

  return (
    <Box className="grid grid-cols-12 gap-4 items-center flex-wrap">
      {props.options.map((option) => (
        <CustomCheckbox key={option.id} {...getCheckboxProps({ value: option.title })} />
      ))}
    </Box>
  );
}

export const MultipleChoiceField = ({ block }: InspectionField) => {
  const { control } = useFormContext<Record<string, { type: 'text'; value?: string[] }>>();
  const { design, options } = block.properties as MultipleChoiceFieldProperties;
  const layout = design?.layout;

  const fieldName = `${block.id}.value` as const;
  const choices = options;

  return (
    <GridItem colSpan={compact([12, layout?.columns])} key={block.id}>
      <Controller
        name={`${block.id}.type` as const}
        control={control}
        render={({ field: { value: _, ...field } }) => <input type="hidden" {...field} value="multiple_choice" />}
      />

      <Controller
        name={fieldName}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const hasError = !!error?.message;
          const sortedChoices = choices.toSorted((a, b) => a.id.localeCompare(b.id));
          return (
            <FormControl className="flex flex-col gap-2" isInvalid={hasError}>
              <FormLabel htmlFor={fieldName} className="!font-semibold tracking-tight !text-lg">
                {capitalize(block.title)}
              </FormLabel>

              <VStack alignItems="normal" spacing={4}>
                <CheckboxGroup options={sortedChoices} value={field.value ?? []} onChange={field.onChange} />

                {hasError ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
              </VStack>
            </FormControl>
          );
        }}
      />
    </GridItem>
  );
};
