import { Fragment } from 'react';
import { match } from 'ts-pattern';
import type { InspectionField } from '../lib/schema.js';
import { FileUploadField } from './fields/file-upload.tsx';
import { GroupField } from './fields/group.tsx';
import { MeasurementField } from './fields/measurement.tsx';
import { MultipleChoiceField } from './fields/multiple-choice.tsx';
import { RadioField } from './fields/radio-field.tsx';
import { RowField } from './fields/row-field.tsx';
import { SelectField } from './fields/select-field.tsx';
import { TextAreaField } from './fields/text-area.tsx';
import { TextField } from './fields/text-field.tsx';

export const FormElement = (props: InspectionField) => {
  const { id } = props.block;
  if (props.block.visibility === 'hidden') {
    return null;
  }

  return (
    <Fragment key={id}>
      {match(props.block)
        .with({ type: 'group' }, () => <GroupField key={id} {...props} />)
        .with({ type: 'short_text' }, () => <TextField key={id} {...props} />)
        .with({ type: 'long_text' }, () => <TextAreaField key={id} {...props} />)
        .with({ type: 'multiple_choice' }, () => <MultipleChoiceField key={id} {...props} />)
        .with({ type: 'radio' }, () => <RadioField key={id} {...props} />)
        .with({ type: 'measurement' }, () => <MeasurementField key={id} {...props} />)
        .with({ type: 'select' }, () => <SelectField key={id} {...props} />)
        .with({ type: 'file_upload' }, () => <FileUploadField key={id} {...props} />)
        .with({ type: 'row' }, () => <RowField key={id} {...props} />)
        .otherwise(() => null)}
    </Fragment>
  );
};
